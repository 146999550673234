import styled from "styled-components";

import { Cell, Row } from "@ax/components/TableList/TableItem/style";
import { ActionMenu } from "@ax/components";

const CheckCell = styled(Cell)`
  padding-right: 0;
  padding-left: ${(p) => p.theme.spacing.m};
  label {
    margin-bottom: ${(p) => p.theme.spacing.s};
    padding-left: ${(p) => p.theme.spacing.s};
  }
`;

const NameCell = styled(Cell)`
  flex-grow: 1;
`;

const TypeCell = styled(Cell)`
  flex: 0 0 215px;
`;

const UpdateCell = styled(Cell)`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  flex: 0 0 170px;
  align-items: center;
`;

const StateCell = styled(Cell)`
  flex: 0 0 85px;
  align-items: center;
`;

const LangCell = styled(Cell)`
  flex: 0 0 115px;
  align-items: center;
`;

const ActionsCell = styled(Cell)`
  flex: 0 0 64px;
`;

const ActionsWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.1s;
  text-align: right;
`;

const StyledActionMenu = styled(ActionMenu)`
  opacity: 0;
  width: 32px;
  display: flex;
  margin-left: auto;
`;

const FormRow = styled(Row)`
  cursor: pointer;
  &:hover {
    ${StyledActionMenu} {
      opacity: 1;
    }
  }
`;

const FlagsWrapper = styled.div`
  svg {
    margin-right: 2px;
    margin-bottom: -3px;
    display: inline;
    vertical-align: baseline;
  }
`;

export {
  CheckCell,
  NameCell,
  TypeCell,
  UpdateCell,
  StateCell,
  LangCell,
  ActionsCell,
  ActionsWrapper,
  StyledActionMenu,
  FormRow,
  FlagsWrapper,
};
