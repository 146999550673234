import * as React from "react";

import { SiteContext } from "../../../contexts";
import "./styles.css";

interface FormWrapperProps {
	selectedEditorID: number | null;
	editorID?: number;
	component: string;
	isSelectionAllowed: boolean;
	children: JSX.Element;
}

const FormWrapper = (props: FormWrapperProps) => {
	const { selectEditorID } = React.useContext(SiteContext);

	const {
		selectedEditorID,
		editorID,
		component,
		isSelectionAllowed,
		children,
	} = props;

	const handleClick = (e: React.SyntheticEvent) => {
		if (
			(!editorID && editorID !== 0) ||
			typeof window === "undefined" ||
			!isSelectionAllowed
		) {
			return;
		}
		selectEditorID && selectEditorID(props, undefined, e);
	};

	const isSelected = !!selectedEditorID && selectedEditorID === editorID;

	return (
		<span
			className={isSelected ? "fw-selected" : ""}
			data-text={component}
			onClick={handleClick}
		>
			<span
				className={
					isSelected ? "fw-selected-nameplate" : "fw-not-selected-nameplate"
				}
			>
				{component}
			</span>
			{children}
		</span>
	);
};

export { FormWrapper };
