import React from "react";
import { getSchemaFormCategories } from "@ax/helpers";

import MultiCheckSelectGroup, { IMultiCheckSelectGroupProps } from "../MultiCheckSelectGroup";

const FormCategorySelect = (props: IFormCategorySelectProps): JSX.Element => {
  const { categories } = props;

  const schemaCategories = getSchemaFormCategories();

  const elements: IElement[] = categories
    ? categories.reduce((acc: IElement[], curr: string) => {
        const schemaCategory = schemaCategories.find((category) => category.value === curr);
        if (schemaCategory) {
          acc.push({ key: schemaCategory.value, source: schemaCategory.value, placeholder: schemaCategory.label });
        }
        return acc;
      }, [])
    : schemaCategories.map((cat) => {
        return { key: cat.value, source: cat.value, placeholder: cat.label };
      });

  const formCategorySelectProps: IMultiCheckSelectGroupProps = {
    ...props,
    elements,
    contentType: "formCategory",
  };

  return <MultiCheckSelectGroup {...formCategorySelectProps} />;
};

interface IElement {
  key: string;
  placeholder: string;
  source: string;
}

interface IFormCategorySelectProps {
  value: Record<string, unknown[]> | Record<string, never> | undefined | null;
  onChange: (value: Record<string, unknown[]> | Record<string, never>) => void;
  categories?: string[];
}

export default FormCategorySelect;
