import React from "react";

import {
  CategoryFilter,
  CheckField,
  CheckGroupFilter,
  NameFilter,
  StatusFilter,
  TableCounter,
  Tooltip,
} from "@ax/components";
import { IFilterValue, IQueryValue } from "@ax/types";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const {
    totalItems,
    selectAllItems,
    isScrolling,
    sortItems,
    filterItems,
    sortedListStatus,
    filterValues,
    checkState,
    setHoverCheck,
    featuredCategory,
    siteID,
  } = props;

  const statusFilters: IFilterValue[] = [
    {
      name: "all",
      value: "all",
      title: "All states",
    },
    {
      name: "inactive",
      value: "inactive",
      title: "Inactive",
      icon: "offline",
    },
    {
      name: "active",
      value: "active",
      title: "Active",
      icon: "active",
    },
  ];

  const langfilters: IFilterValue[] = [
    {
      name: "all",
      value: "all",
      title: "All forms",
    },
    {
      name: "only",
      value: "only",
      title: "Translated content",
    },
    {
      name: "no",
      value: "no",
      title: "Not translated content",
    },
  ];

  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <Tooltip content="Select All Users" bottom>
          <CheckField
            key="selectAll"
            name="selectAll"
            value="selectAll"
            onChange={selectAllItems}
            checked={checkState.isAllSelected || checkState.hoverCheck}
            disabled={false}
            error={false}
            setHoverCheck={setHoverCheck}
          />
        </Tooltip>
      </S.CheckHeader>
      <S.NameWrapper>
        <NameFilter sortItems={sortItems} sortedState={sortedListStatus} title="Form name" />
      </S.NameWrapper>
      {featuredCategory && (
        <S.TypeHeader>
          <CategoryFilter
            filterItems={filterItems}
            value={filterValues.type}
            formCategory={featuredCategory}
            siteID={siteID}
            pointer="type"
          />
        </S.TypeHeader>
      )}
      <S.UpdateHeader>
        <StatusFilter sortItems={sortItems} sortedState={sortedListStatus} />
      </S.UpdateHeader>
      <S.StateHeader>
        <CheckGroupFilter
          filterItems={filterItems}
          value={filterValues.state}
          pointer="state"
          label="State"
          description="Live Filter"
          initialState={["all"]}
          selectAllOption="all"
          filters={statusFilters}
          menuPosition="left"
        />
      </S.StateHeader>
      <S.LangHeader>
        <CheckGroupFilter
          filterItems={filterItems}
          value={filterValues.translated}
          pointer="translated"
          label="Lang"
          description="Filter by Translations"
          initialState={["all"]}
          selectAllOption="all"
          filters={langfilters}
          menuPosition="right"
        />
      </S.LangHeader>
      <S.ActionsHeader>
        <TableCounter totalItems={totalItems} />
      </S.ActionsHeader>
    </S.TableHeader>
  );
};

interface IProps {
  totalItems: number;
  isScrolling: boolean;
  featuredCategory?: { value: string; label: string };
  selectAllItems: () => void;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  sortedListStatus: { isAscending: boolean; sortedByTitle: boolean; sortedByDate: boolean };
  filterValues: Record<string, IQueryValue[]>;
  checkState: Record<string, boolean>;
  siteID: number | null;
  setHoverCheck: (state: boolean) => void;
}

export default TableHeader;
