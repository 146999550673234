import React, { useState, useEffect, useRef } from "react";
import ResizeHandle from "./ResizeHandle";

import * as S from "./style";

const ResizePanel = (props: IResizePanelProps): JSX.Element => {
  const { leftPanel, rightPanel, fixed = true, full = false } = props;

  const [rwidth, setRwidth] = useState(0);

  const rightPanelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rightPanelRef.current) {
      setRwidth(rightPanelRef.current.offsetWidth);
    }
  }, [rightPanelRef]);

  const resize = (value: number) => {
    if (value > 344) {
      setRwidth(value);
    }
  };

  return (
    <>
      <S.LeftPanel data-testid="left-panel">
        {fixed ? (
          <S.FixedPanel width={rwidth} full={full} data-testid="fixed-panel">
            {leftPanel}
          </S.FixedPanel>
        ) : (
          leftPanel
        )}
      </S.LeftPanel>
      <ResizeHandle onMouseMove={resize} />
      <S.RightPanel width={rwidth} ref={rightPanelRef} data-testid="right-panel">
        {rightPanel}
      </S.RightPanel>
    </>
  );
};

export interface IResizePanelProps {
  leftPanel: JSX.Element | JSX.Element[];
  rightPanel: JSX.Element | JSX.Element[];
  fixed?: boolean;
  full?: boolean;
}

export default ResizePanel;
