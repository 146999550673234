import { AxiosResponse } from "axios";
import { template } from "./config";
import { sendRequest, IServiceConfig } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_STRUCTURED_DATA: {
    ...template,
    endpoint: ["/site/", "/structured_data_contents/", "/checkgroup"],
    method: "GET",
  },
  GET_LIVE_STATUS: {
    ...template,
    endpoint: "/live-status",
    method: "GET",
  },
  GET_FORM_CATEGORIES: {
    ...template,
    endpoint: ["/site/", "/form/categories/", "/checkgroup"],
    method: "GET",
  },
};

const getCheckGroupItems = async (
  siteId: number | string | null,
  source: string,
  allLanguages?: boolean
): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, infix, suffix],
  } = SERVICES.GET_STRUCTURED_DATA;

  const allLanguagesQuery = allLanguages ? "&allLanguages=true" : "";

  SERVICES.GET_STRUCTURED_DATA.dynamicUrl = `${host}${prefix}${siteId}${infix}${source}${suffix}?groupingCategories=on&order=title-ASC${allLanguagesQuery}`;

  return sendRequest(SERVICES.GET_STRUCTURED_DATA);
};

const getFormCategories = async (siteID: number | "global", categoriID: string): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, infix, suffix],
  } = SERVICES.GET_FORM_CATEGORIES;

  SERVICES.GET_FORM_CATEGORIES.dynamicUrl = `${host}${prefix}${siteID}${infix}${categoriID}${suffix}`;

  return sendRequest(SERVICES.GET_FORM_CATEGORIES);
};

const getLiveStatusItems = async (): Promise<AxiosResponse> => sendRequest(SERVICES.GET_LIVE_STATUS);

export default { getCheckGroupItems, getLiveStatusItems, getFormCategories };
