import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import Tree, {
  mutateTree,
  RenderItemParams,
  TreeItem,
  TreeData,
  ItemId,
  TreeSourcePosition,
  TreeDestinationPosition,
  moveItemOnTree,
} from "@atlaskit/tree";

import { menuActions } from "@ax/containers/Navigation";
import { appActions } from "@ax/containers/App";
import { Loading, ErrorToast, IconAction } from "@ax/components";
import { RouteLeavingGuard } from "@ax/guards";
import { IMenuItem, IRootState } from "@ax/types";

import { formatItem, formatMenu, normalizeMenu } from "./helpers";
import Header from "./Header";
import Item from "./Item";
import * as S from "./style";

const ItemList = (props: IItemList): JSX.Element => {
  const { menu, reorderMenu } = props;
  const [tree, setTree] = useState<TreeData>({ rootId: "root", items: {} });
  const [itemDragging, setItemDragging] = useState<ItemId | null>(null);

  useEffect(() => {
    setTree(normalizeMenu(menu, tree));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);

  const getIcon = (item: TreeItem, onExpand: (itemId: ItemId) => void, onCollapse: (itemId: ItemId) => void) => {
    const handleCollapse = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onCollapse(item.id);
    };

    const handleExpand = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onExpand(item.id);
    };

    if (item.children && item.children.length > 0) {
      return item.isExpanded ? (
        <IconAction icon="UpArrow" size="m" onClick={handleCollapse} />
      ) : (
        <IconAction icon="DownArrow" size="m" onClick={handleExpand} />
      );
    } else {
      return <></>;
    }
  };

  const renderItem = ({ item, onExpand, onCollapse, provided, depth }: RenderItemParams) => {
    const isDragging = !!itemDragging && itemDragging !== item.id;
    return (
      <S.Draggable ref={provided.innerRef} {...provided.draggableProps}>
        <Item
          item={formatItem(item, tree)}
          icon={getIcon(item, onExpand, onCollapse)}
          dragHandleProps={provided.dragHandleProps}
          depth={depth}
          isDragging={isDragging}
        />
      </S.Draggable>
    );
  };

  const onExpand = (itemId: ItemId) => {
    const newTree = mutateTree(tree, itemId, { isExpanded: true });
    setTree(newTree);
    const newMenu = formatMenu(newTree);
    reorderMenu(newMenu);
  };

  const onCollapse = (itemId: ItemId) => {
    const newTree = mutateTree(tree, itemId, { isExpanded: false });
    setTree(newTree);
    const newMenu = formatMenu(newTree);
    reorderMenu(newMenu);
  };

  const onDragStart = (item: ItemId) => setItemDragging(item);

  const onDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
    if (!destination || (source.parentId === destination.parentId && source.index === destination.index)) {
      setItemDragging(null);
      return;
    }

    const newTree = moveItemOnTree(tree, source, destination);
    const { parentId } = destination;
    newTree.items[parentId].isExpanded = true;
    setTree(newTree);
    const newMenu = formatMenu(newTree);
    reorderMenu(newMenu);
    setItemDragging(null);
  };

  return (
    <S.Droppable>
      <Tree
        tree={tree}
        renderItem={renderItem}
        onExpand={onExpand}
        onCollapse={onCollapse}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        isDragEnabled
        isNestingEnabled
      />
    </S.Droppable>
  );
};

const Table = (props: ITable): JSX.Element => {
  const { editorMenu, isLoading, setHistoryPush, isDirty, reorderMenu } = props;

  const action = (path: string) => setHistoryPush(path);
  const text = (
    <>
      Some changes <strong>are not saved</strong>.
    </>
  );
  const allowedRoutes = ["/sites/navigations/menus"];

  if (isLoading) {
    return (
      <S.LoadingWrapper>
        <Loading />
      </S.LoadingWrapper>
    );
  }

  return (
    <S.TableWrapper>
      <RouteLeavingGuard when={isDirty} action={action} text={text} allowedRoutes={allowedRoutes} />
      <ErrorToast />
      <S.Table>
        <Header />
        {editorMenu && <ItemList menu={editorMenu} reorderMenu={reorderMenu} />}
      </S.Table>
    </S.TableWrapper>
  );
};

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  reorderMenu: menuActions.reorderMenu,
};

const mapStateToProps = (state: IRootState) => ({
  isLoading: state.app.isLoading,
  editorMenu: state.menu.editorMenu.elements,
});

interface ITableStateProps {
  isLoading: boolean;
  editorMenu: IMenuItem[] | undefined;
  isDirty: boolean;
}

interface ITableDispatchProps {
  setHistoryPush(path: string): any;
  reorderMenu(elements: IMenuItem[]): void;
}

interface IItemList {
  menu: IMenuItem[];
  reorderMenu(elements: IMenuItem[]): void;
}

type ITable = ITableStateProps & ITableDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Table);
