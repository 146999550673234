import React from "react";

import { FieldsBehavior } from "@ax/components";
import { slugify } from "@ax/helpers";
import { IFormState } from "..";

const Form = (props: IFormProps): JSX.Element => {
  const { edit, form, setForm } = props;

  const setNameValue = (value: string) => {
    if (!edit) {
      setForm({ ...form, name: value, code: slugify(value) });
    } else {
      setForm({ ...form, name: value });
    }
  };

  const setCodeValue = (value: string) => setForm({ ...form, code: value });

  return (
    <>
      <FieldsBehavior
        title="Name"
        name="name"
        fieldType="TextField"
        value={form.name}
        onChange={setNameValue}
        autoComplete="category-name"
        mandatory={true}
      />
      <FieldsBehavior
        title="Code"
        name="code"
        fieldType="TextField"
        value={form.code}
        onChange={setCodeValue}
        autoComplete="category-code"
        mandatory={true}
      />
    </>
  );
};

interface IFormProps {
  edit: boolean | undefined;
  form: IFormState;
  setForm: (values: IFormState) => void;
}

export default Form;
