import React, { useState } from "react";
import { connect } from "react-redux";
import { config } from "components";

const themes = config.schemas.config.themes;

import { IGriddoTheme, IImage, INavItem, IRootState, ISettingsForm } from "@ax/types";
import { RouteLeavingGuard } from "@ax/guards";
import { useModal, useShouldBeSaved } from "@ax/hooks";
import { appActions } from "@ax/containers/App";
import { FieldsBehavior, FieldGroup, MainWrapper, ErrorToast, Nav, Notification, Modal } from "@ax/components";
import { sitesActions } from "@ax/containers/Sites";
import { getNavigationModules } from "@ax/helpers";

import NavigationModules from "./NavigationModules";
import { timezones } from "./constants";
import * as S from "./style";

const Globals = (props: IProps): JSX.Element => {
  const { isSaving, currentSiteInfo, saveSettings, setHistoryPush, navItems, currentNavItem } = props;

  const [isNavigationNotificationOpen, setIsNavigationNotificationOpen] = useState(false);
  const [tempTheme, setTempTheme] = useState<string | undefined>();
  const { isOpen, toggleModal } = useModal();

  const title = "General settings";

  const [isNavigationModulesChanged, setIsNavigationModulesChanged] = useState(!!currentSiteInfo.navigationModules);
  const [form, setForm] = useState(currentSiteInfo);

  const { name, timezone, theme, favicon, smallAvatar, bigAvatar, thumbnail } = form;

  const { isDirty, setIsDirty } = useShouldBeSaved(form);

  const navigationModules: Record<string, any[]> = getNavigationModules();
  const hasMultipleNavigationModules = Object.keys(navigationModules).some(
    (key: string) => navigationModules[key].length > 1
  );

  const setValue = (newValue: Record<string, string | null>) =>
    setForm((state: ISettingsForm) => ({ ...state, ...newValue }));

  const setNameValue = (value: string) => setValue({ name: value });
  const setTimezoneValue = (value: string) => setValue({ timezone: value });
  const setThemeValue = (value: string) => {
    const theme = (themes as IGriddoTheme[]).find((th) => th.value === value);
    if (theme && theme.elements) {
      setTempTheme(value);
      toggleModal();
    } else {
      setValue({ theme: value });
    }
  };
  const setFaviconValue = (value: IImage) => setValue({ favicon: value.url ? value.url : null });
  const setSmallAvatarValue = (value: IImage) => setValue({ smallAvatar: value.url ? value.url : null });
  const setBigAvatarValue = (value: IImage) => setValue({ bigAvatar: value.url ? value.url : null });
  const setThumbnailValue = (value: IImage) => setValue({ thumbnail: value.url ? value.url : null });
  const setNavigationModulesValue = (value: any) => setValue({ navigationModules: value });

  const handleChangeTheme = () => {
    tempTheme && setValue({ theme: tempTheme });
    toggleModal();
  };

  const saveForm = async () => {
    const isSaved = await saveSettings(form);
    const isNavigationModulesChanging =
      JSON.stringify(form?.navigationModules) !== JSON.stringify(currentSiteInfo?.navigationModules);
    setIsNavigationNotificationOpen(isNavigationModulesChanging);
    if (isSaved) setIsDirty(false);
  };

  const rightButtonProps = {
    label: isSaving ? "Saving" : isDirty ? "Save" : "Saved",
    disabled: isSaving || !isDirty,
    action: saveForm,
  };

  const setRoute = (path: string) => setHistoryPush(path);
  const modalText = (
    <>
      Some settings <strong>are not saved</strong>.{" "}
    </>
  );

  const handleMenuClick = (path: string) => {
    setHistoryPush(path);
  };

  const closeNavigationNotification = () => setIsNavigationNotificationOpen(false);
  const goToNavigationModules = () => setHistoryPush("/sites/navigations/modules");

  const mainDeleteAction = { title: "Understood", onClick: handleChangeTheme };
  const secondaryDeleteAction = { title: "Cancel", onClick: toggleModal };

  return (
    <>
      <RouteLeavingGuard when={isDirty} action={setRoute} text={modalText} />
      <MainWrapper backLink={false} title={title} rightButton={rightButtonProps}>
        <S.Wrapper>
          <Nav current={currentNavItem} items={navItems} onClick={handleMenuClick} />
          <S.ContentWrapper>
            <ErrorToast />
            {isNavigationNotificationOpen && (
              <Notification
                type="warning"
                text="The design of the navigation modules has changed. Please, check the previously created."
                btnText="Go to check"
                onClick={goToNavigationModules}
                resetError={closeNavigationNotification}
              />
            )}
            <S.FormWrapper>
              <FieldsBehavior
                title="Site Name"
                name="name"
                fieldType="TextField"
                value={name || ""}
                onChange={setNameValue}
              />
              <FieldsBehavior
                title="Timezone"
                name="timezone"
                fieldType="Select"
                value={timezone}
                options={timezones}
                onChange={setTimezoneValue}
                mandatory={true}
              />
              <FieldsBehavior
                title="Site theme"
                name="theme"
                fieldType="Select"
                value={theme}
                options={themes}
                onChange={setThemeValue}
                mandatory={true}
              />
              <FieldsBehavior
                title="Site favicon"
                name="favicon"
                value={favicon || null}
                fieldType="ImageField"
                onChange={setFaviconValue}
                helptext="Upload a 32x32px image"
              />
              {hasMultipleNavigationModules && (
                <NavigationModules
                  navigationModules={navigationModules}
                  form={form}
                  setNavigationModulesValue={setNavigationModulesValue}
                  isNavigationModulesChanged={isNavigationModulesChanged}
                  setIsNavigationModulesChanged={setIsNavigationModulesChanged}
                  theme={theme}
                />
              )}
              <FieldGroup title="Content for CMS">
                <FieldsBehavior
                  title="Small avatar"
                  name="smallAvatar"
                  value={smallAvatar || null}
                  fieldType="ImageField"
                  onChange={setSmallAvatarValue}
                  helptext="Upload a 72x64px image to show in menu"
                />
                <FieldsBehavior
                  title="Big avatar"
                  name="bigAvatar"
                  value={bigAvatar || null}
                  fieldType="ImageField"
                  onChange={setBigAvatarValue}
                  helptext="Upload a 256x64px image to show in open menu"
                />
                <FieldsBehavior
                  title="Site screen thumbnail"
                  name="thumbnail"
                  value={thumbnail || null}
                  fieldType="ImageField"
                  onChange={setThumbnailValue}
                  helptext="Image for site screen"
                />
              </FieldGroup>
            </S.FormWrapper>
          </S.ContentWrapper>
        </S.Wrapper>
        <Modal
          isOpen={isOpen}
          hide={toggleModal}
          title="Theme Change Warning"
          secondaryAction={secondaryDeleteAction}
          mainAction={mainDeleteAction}
          size="S"
        >
          <S.ModalContent>
            <p>
              The selected theme has some restrictions. By switching to this theme,{" "}
              <strong>you may lose access to some modules or templates.</strong>
            </p>
          </S.ModalContent>
        </Modal>
      </MainWrapper>
    </>
  );
};

interface IProps {
  isSaving: boolean;
  currentSiteInfo: any;
  navItems: INavItem[];
  currentNavItem: INavItem;
  saveSettings(form: ISettingsForm): Promise<boolean>;
  setHistoryPush(path: string, isEditor?: boolean): void;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  saveSettings: sitesActions.saveSettings,
};

const mapStateToProps = (state: IRootState) => ({
  isSaving: state.app.isSaving,
  currentSiteInfo: state.sites.currentSiteInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(Globals);
