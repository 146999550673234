import React, { memo } from "react";
import { IconAction, SideModal, Tooltip } from "@ax/components";

const AddItemButton = (props: IProps) => {
  const { handleClick, whiteList, categories, theme, isOpen, contentType, toggleModal } = props;
  const addAction = whiteList.length <= 1 ? () => handleClick(whiteList[0]) : toggleModal;

  const tooltip = contentType === "fields" ? "Add field" : contentType === "module" ? "Add module" : "Add component";

  return (
    <>
      <Tooltip content={tooltip} hideOnClick>
        <IconAction icon="add" onClick={addAction} />
      </Tooltip>
      <SideModal
        optionsType={contentType}
        whiteList={whiteList}
        categories={categories}
        toggleModal={toggleModal}
        isOpen={isOpen}
        handleClick={handleClick}
        theme={theme}
        showSearch
      />
    </>
  );
};

interface IProps {
  handleClick: any;
  whiteList: any[];
  categories?: any;
  theme: string;
  toggleModal: () => void;
  isOpen: boolean;
  contentType: string;
}

export default memo(AddItemButton);
