import styled from "styled-components";

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

const SelectWrapper = styled.div`
  & .react-select__control,
  .react-select__menu {
    min-width: 100%;
  }
`;

export { ModalContent, SelectWrapper };
