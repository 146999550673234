import { FormCategory, FormContent, IBreadcrumbItem, IModule, ISchema } from "@ax/types";
import {
  SET_BREADCRUMB,
  SET_COPY_MODULE,
  SET_CURRENT_FORM_ID,
  SET_FORM_CONTENT,
  SET_FORMS,
  SET_SCHEMA,
  SET_SELECTED_CONTENT,
  SET_SELECTED_EDITOR_ID,
  SET_SELECTED_PARENT,
  SET_TAB,
  SET_TEMPLATE,
  SET_SUMMARY,
  SET_CURRENT_FORM_CATEGORIES,
  SET_IS_NEW_TRANSLATION,
  SET_IS_IA_TRANSLATED,
} from "./constants";

export interface IFormsState {
  forms: FormContent[];
  formContent: FormContent | null;
  schema: ISchema | Record<string, never>;
  template: string;
  tab: string;
  currentFormID: number | null;
  currentFormStatus: string | null;
  selectedContent: Record<string, unknown>;
  selectedEditorID: number;
  selectedParent: Record<string, unknown> | null;
  breadcrumb: IBreadcrumbItem[];
  moduleCopy: { date: Date; elements: IModule[] } | null;
  summary: { total: number; active: number; inactive: number };
  currentFormCategories: FormCategory[];
  isNewTranslation: boolean;
  isIATranslated: boolean;
}

export const initialState = {
  forms: [],
  formContent: null,
  schema: {},
  template: "BasicForm",
  tab: "content",
  currentFormID: null,
  currentFormStatus: null,
  selectedContent: {},
  selectedEditorID: 0,
  selectedParent: null,
  breadcrumb: [],
  moduleCopy: null,
  summary: { total: 0, active: 0, inactive: 0 },
  currentFormCategories: [],
  isNewTranslation: false,
  isIATranslated: false,
};

export function reducer(state = initialState, action: any): IFormsState {
  switch (action.type) {
    case SET_FORMS:
    case SET_FORM_CONTENT:
    case SET_SCHEMA:
    case SET_TEMPLATE:
    case SET_TAB:
    case SET_CURRENT_FORM_ID:
    case SET_SELECTED_CONTENT:
    case SET_SELECTED_EDITOR_ID:
    case SET_SELECTED_PARENT:
    case SET_BREADCRUMB:
    case SET_COPY_MODULE:
    case SET_SUMMARY:
    case SET_CURRENT_FORM_CATEGORIES:
    case SET_IS_NEW_TRANSLATION:
    case SET_IS_IA_TRANSLATED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as formsInitialState, reducer as formsReducer };
