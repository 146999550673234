import { config } from "components";

const schemas = config.schemas.contentTypes;

const getStructuredDataFromPage = (site: any) => site.filter((data: any) => data.fromPage);

const getCurrentPageStructuredData = (template: string, structuredDataFromPage: any) =>
  structuredDataFromPage.find(
    (data: any) => !!data.schema.templates.find((relatedTemplate: string) => relatedTemplate === template)
  );

const getStructuredDataTitle = (name: string): string => schemas.structuredData[name]?.title;

const isStructuredDataFromPage = (name: string): boolean => schemas.structuredData[name]?.fromPage;

const isGlobalStructuredData = (name: string): boolean => !schemas.structuredData[name]?.local;

const getGlobalPageTypes = (): Record<string, string>[] => {
  const { structuredData } = schemas;
  const keys = Object.keys(structuredData);
  const globalPageTypes = keys.reduce((arr: Record<string, string>[], type: string) => {
    const title = structuredData[type].title;
    const isGlobalPage = structuredData[type].fromPage && !structuredData[type].local;
    return isGlobalPage ? [...arr, { type, title }] : arr;
  }, []);
  return globalPageTypes;
};

export {
  getStructuredDataFromPage,
  getCurrentPageStructuredData,
  getStructuredDataTitle,
  isGlobalStructuredData,
  isStructuredDataFromPage,
  getGlobalPageTypes,
};
