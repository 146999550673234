import React from "react";
import { FloatingPanel, Icon } from "@ax/components";
import { useModal } from "@ax/hooks";
import { ISchemaField, ISite } from "@ax/types";
import AIConfigPanel from "./AIConfigPanel";

import * as S from "./style";

const AIReferenceField = (props: IProps): JSX.Element => {
  const { value, onChange, disabled, lang, site, field } = props;

  const { isOpen, toggleModal } = useModal();

  const handleChange = (newValue: IState) => {
    onChange(newValue);
    toggleModal();
  };

  const IconStatus = (props: { type: "success" | "warning" | "close" }) => {
    return (
      <S.IconWrapper color={props.type}>
        <Icon name={props.type} size="16" />
      </S.IconWrapper>
    );
  };

  return (
    <S.Wrapper>
      <S.Label>AI Distributor</S.Label>
      <S.Content>
        <S.Text>
          Configure the <strong>AI distributor</strong> to tailor content suggestions based on user data. Refine and
          ensure a relevant user experience. Only <strong>published</strong> &#40; <Icon name="active" size="16" />{" "}
          &#41; data is displayed. &lsquo;Pending to publish&rsquo; &#40; <Icon name="uploadPending" size="16" /> &#41;
          data won&apos;t appear until the publishing process is complete.
        </S.Text>
        {value && (
          <S.StatusWrapper>
            <S.Check>
              <IconStatus type={value.templates.length > 0 ? "success" : "close"} />
              <div>Selected Content Type</div>
            </S.Check>
            <S.Check>
              <IconStatus type={value.sites.length > 0 ? "success" : "close"} />
              <div>Selected Sites</div>
            </S.Check>
            <S.Check>
              <IconStatus type={value.prompt.trim().length > 0 ? "success" : "warning"} />
              <div>Custom AI Prompt</div>
            </S.Check>
            <S.Check>
              <IconStatus type={value.limit > 0 ? "success" : "close"} />
              <div>Number of Results</div>
            </S.Check>
          </S.StatusWrapper>
        )}
        <S.ButtonWrapper>
          <S.StyledButton type="button" onClick={toggleModal} icon="Ia" disabled={disabled}>
            {value ? "Edit settings AI distributor" : "Configure AI distributor"}
          </S.StyledButton>
        </S.ButtonWrapper>
      </S.Content>
      <FloatingPanel title="Configure AI Distributor" toggleModal={toggleModal} isOpen={isOpen}>
        {isOpen && (
          <AIConfigPanel
            value={value}
            onChange={handleChange}
            lang={lang}
            site={site?.id}
            whiteList={field.whiteList}
          />
        )}
      </FloatingPanel>
    </S.Wrapper>
  );
};

export interface IState {
  prompt: string;
  templates: string[];
  sites: number[];
  language: number;
  limit: number;
}

interface IProps {
  value?: IState | null;
  onChange: (value: IState) => void;
  disabled?: boolean;
  lang: number;
  site: ISite | null;
  field: ISchemaField;
}

export default AIReferenceField;
