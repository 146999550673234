import React, { useEffect, useState } from "react";
import { Button, FieldsBehavior, Icon } from "@ax/components";
import { sites, structuredData } from "@ax/api";
import { isReqOk } from "@ax/helpers";
import { IState } from "..";

import * as S from "./style";

const AIConfigPanel = (props: IAIConfigPanelProps): JSX.Element => {
  const { value, onChange, lang, site, whiteList } = props;

  const initialState: IState = {
    prompt: "",
    templates: [],
    sites: site ? [site] : [],
    language: lang,
    limit: 0,
  };

  const formValues = value ? value : initialState;

  const [form, setForm] = useState(formValues);
  const [data, setData] = useState({ sites: [], templates: [] });

  useEffect(() => {
    const getItems = async () => {
      try {
        const items = { sites: [], templates: [] };
        const resultSites = await sites.getSelectSites();
        if (resultSites && isReqOk(resultSites.status)) {
          const sites = resultSites.data.map((opt: { value: number; label: string }) => {
            return { value: opt.value, name: opt.value, title: opt.label };
          });
          items.sites = sites;
        }
        const resultData = await structuredData.getAISearchData();
        if (resultData && isReqOk(resultData.status)) {
          const templates = resultData.data
            .filter((opt: { id: string; name: string }) => (whiteList ? whiteList.includes(opt.id) : true))
            .map((opt: { id: string; name: string }) => {
              return { value: opt.id, name: opt.id, title: opt.name };
            });
          items.templates = templates;
        }
        setData(items);
      } catch (e) {
        console.log(e);
      }
    };

    let isMounted = true;
    if (isMounted) {
      getItems();
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApply = () => onChange(form);
  const handleClear = () => setForm({ ...initialState, sites: [] });

  const handleSourceSelect = (templates: string[]) => setForm((state) => ({ ...state, templates }));

  const handleSiteSelect = (sites: number[]) => setForm((state) => ({ ...state, sites }));

  const handleLimit = (limit: number) => setForm((state) => ({ ...state, limit }));

  const handlePrompt = (prompt: string) => setForm((state) => ({ ...state, prompt }));

  const sitesPlaceholder = form.sites.length
    ? `Selected ${form.sites.length} Site${form.sites.length > 1 ? "s" : ""}`
    : "Select Sites";

  const templatesPlaceholder = form.templates.length
    ? `Selected ${form.templates.length} Content Type${form.templates.length > 1 ? "s" : ""}`
    : "Select Content Type";

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Note>
          <S.Title>
            <S.IconWrapper>
              <Icon name="Ia" size="16" />
            </S.IconWrapper>
            <div>AI distributor</div>
          </S.Title>
          <S.Text>
            Configure the <strong>AI distributor</strong> to tailor content suggestions based on user data. Use these
            settings to refine the type of content displayed and ensure a relevant user experience.
          </S.Text>
        </S.Note>
        <FieldsBehavior
          title="Data Source"
          fieldType="MultiCheckSelect"
          options={data.templates}
          value={form.templates}
          onChange={handleSourceSelect}
          placeholder={templatesPlaceholder}
          helptext="Select one or multiple content types to include in the search."
          mandatory={true}
          floating
        />
        <FieldsBehavior
          title="Site Scope"
          fieldType="MultiCheckSelect"
          options={data.sites}
          value={form.sites}
          onChange={handleSiteSelect}
          placeholder={sitesPlaceholder}
          helptext="Specify the site for the search. By default, the search is limited to the current site."
          mandatory={true}
          floating
        />
        <FieldsBehavior
          fieldType="TextArea"
          title="Custom AI Prompt"
          value={form.prompt}
          onChange={handlePrompt}
          placeholder="Example: Prioritising online programmes"
          helptext="Specify the type of content you want to display. The AI will use this as guidance, but results may vary."
        />
        <FieldsBehavior
          fieldType="NumberField"
          title="Number of results"
          value={form.limit}
          onChange={handleLimit}
          helptext="Set the number of results to display. Keep it concise to improve user experience."
          mandatory={true}
        />
      </S.FormWrapper>
      <S.ActionsWrapper>
        <Button type="button" buttonStyle="line" onClick={handleClear}>
          Clear Data
        </Button>
        <Button type="button" onClick={handleApply}>
          Save Data
        </Button>
      </S.ActionsWrapper>
    </S.Wrapper>
  );
};

interface IAIConfigPanelProps {
  value?: IState | null;
  lang: number;
  site?: number;
  whiteList?: string[];
  onChange: (value: IState) => void;
}

export default AIConfigPanel;
