import React, { useState } from "react";
import { InputActionMeta } from "react-select";

import * as S from "./style";

const Select = (props: ISelectProps): JSX.Element => {
  const {
    name,
    value,
    options,
    error,
    defaultValue,
    placeholder,
    isMulti,
    disabled,
    type,
    mandatory,
    onChange,
    alignRight,
    maxWidth,
  } = props;
  const className = error ? `react-select-error ${type}` : type;
  const emptyOption = { value: "", label: placeholder || "Empty" };

  const [hasEmptyOption, setHasEmptyOption] = useState(!mandatory);
  const [inputText, setInputText] = useState<string>("");

  const optionValues: IOptionProps[] = hasEmptyOption ? [emptyOption, ...options] : options;

  const handleChange = (selectedValue: IOptionProps) => onChange(selectedValue.value);

  const handleInputChange = (inputText: string, meta: InputActionMeta) => {
    if (meta.action !== "input-blur" && meta.action !== "menu-close") {
      const inputValue = inputText.replace(/\W/g, "");
      setInputText(inputText);
      setHasEmptyOption(!mandatory && inputValue.length === 0);
    }
  };

  const getObjectValue = (value: string | undefined, options: IOptionProps[]) =>
    options.find((option) => option.value === value);

  const searchable = type === "inline" || type === "mini" ? false : true;

  return (
    <div data-testid="select-component">
      <S.StyledSelect
        name={name}
        value={getObjectValue(value, optionValues) || ""}
        options={optionValues}
        isMulti={isMulti}
        isDisabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={className}
        classNamePrefix="react-select"
        error={error}
        onChange={handleChange}
        isSearchable={searchable}
        hasEmptyOption={hasEmptyOption}
        onInputChange={handleInputChange}
        alignRight={alignRight}
        aria-label={name}
        maxWidth={maxWidth}
        inputValue={inputText}
      />
    </div>
  );
};

export interface ISelectProps {
  name: string;
  value: string | undefined;
  options: IOptionProps[];
  error?: boolean;
  disabled?: boolean;
  defaultValue?: IOptionProps;
  placeholder?: string;
  isMulti?: boolean;
  type?: string;
  mandatory?: boolean;
  alignRight?: boolean;
  onChange: (value: string) => void;
  maxWidth?: number;
}

interface IOptionProps {
  value: string;
  label: string;
}

export default Select;
