import { INavItem, IRootState, ISocialState } from "@ax/types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { RouteLeavingGuard } from "@ax/guards";
import { useIsDirty } from "@ax/hooks";

import { appActions } from "@ax/containers/App";
import { FieldsBehavior, MainWrapper, ErrorToast, Nav } from "@ax/components";
import { socialActions } from "@ax/containers/Settings/Social";

import * as S from "./style";

const Social = (props: IProps): JSX.Element => {
  const { isSaving, navItems, currentNavItem, socialInfo, saveSocial, getSocial, setHistoryPush } = props;

  const title = "Social";

  const socialFields: { name: string; title: string }[] = [
    { name: "instagram", title: "Instagram URL" },
    { name: "linkedIn", title: "LinkedIn URL" },
    { name: "facebook", title: "Facebook URL" },
    { name: "twitter", title: "X/Twitter URL" },
    { name: "youtube", title: "YouTube URL" },
    { name: "flickr", title: "Flickr URL" },
    { name: "tiktok", title: "Tiktok URL" },
    { name: "snapchat", title: "Snapchat URL" },
    { name: "newsletter", title: "Newsletter URL" },
    { name: "podcast", title: "Podcast URL" },
  ];

  const [socialForm, setSocialForm] = useState<Record<string, string>>({});
  const changeSocialForm = (field: string, value: string) => setSocialForm((state) => ({ ...state, [field]: value }));

  const { isDirty, resetDirty, setIsDirty } = useIsDirty(socialForm);

  useEffect(() => {
    getSocial();
    resetDirty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSocialForm({ ...socialInfo });
    setIsDirty(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialInfo]);

  const saveForm = async () => {
    const isSaved = await saveSocial(socialForm);
    if (isSaved) setIsDirty(false);
  };

  const rightButtonProps = {
    label: isSaving ? "Saving" : isDirty ? "Save" : "Saved",
    disabled: isSaving || !isDirty,
    action: saveForm,
  };

  const setRoute = (path: string) => setHistoryPush(path);
  const modalText = (
    <>
      Some settings <strong>are not saved</strong>.{" "}
    </>
  );

  const handleMenuClick = (path: string) => {
    setHistoryPush(path);
  };

  return (
    <>
      <RouteLeavingGuard when={isDirty} action={setRoute} text={modalText} />
      <MainWrapper backLink={false} title={title} rightButton={rightButtonProps}>
        <S.Wrapper>
          <Nav current={currentNavItem} items={navItems} onClick={handleMenuClick} />
          <S.ContentWrapper>
            <ErrorToast />
            <S.FormWrapper>
              {socialFields.map((field, idx) => (
                <FieldsBehavior
                  key={idx}
                  title={field.title}
                  name={field.name}
                  fieldType="TextField"
                  value={socialForm[field.name] || ""}
                  onChange={(value: string) => changeSocialForm(field.name, value)}
                />
              ))}
            </S.FormWrapper>
          </S.ContentWrapper>
        </S.Wrapper>
      </MainWrapper>
    </>
  );
};

interface IProps {
  isSaving: boolean;
  socialInfo: ISocialState;
  navItems: INavItem[];
  currentNavItem: INavItem;
  saveSocial: (form: Record<string, string>) => Promise<boolean>;
  getSocial(): void;
  setHistoryPush(path: string, isEditor?: boolean): void;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  saveSocial: socialActions.saveSocial,
  getSocial: socialActions.getSocial,
};

const mapStateToProps = (state: IRootState) => ({
  isSaving: state.app.isSaving,
  socialInfo: state.social,
});

export default connect(mapStateToProps, mapDispatchToProps)(Social);
