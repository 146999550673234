import type { Fields } from "..";

import * as React from "react";

import { usePage } from "./usePage";
import { getOptions } from "./utils";
import { getLang, getSiteID } from "../utils";

/**
 * Returns an array of the ContentType data from the ReferenceField. This hooks only works in the context of the Griddo editor.
 * @see [Documentación](https://www.notion.so/griddoio/useReferenceFieldData-9eca3bc618464c5c9bee67f3a9607138)
 *
 * @param data The property of the ReferenceField.
 *
 * @example
 * const dataItems = useReferenceFieldData<NewsContentType>(data)
 */
function useReferenceFieldData<ContentType = unknown>(
	/** The ReferenceField from the module schema */
	data?: Fields.Reference<ContentType>
): Fields.QueriedData<ContentType> | undefined | null {
	const [response, setResponse] = React.useState(null);
	const {
		order,
		quantity,
		sources,
		mode,
		fixed,
		allLanguages,
		preferenceLanguage,
		fullRelations = false,
		site,
		lang,
	} = data || {};

	const { apiUrl } = usePage();

	React.useEffect(() => {
		const langID = getLang();
		const isAX = !!langID;
		const siteID = site || getSiteID() || "global";
		const url = `${apiUrl}/site/${siteID}/distributor`;

		const body =
			mode === "auto"
				? {
						mode,
						order,
						quantity,
						sources,
						fullRelations,
						allLanguages,
						preferenceLanguage,
				  }
				: { mode, fixed, fullRelations };
		const fetchOptions = getOptions(body, lang);

		isAX &&
			fetch(url, fetchOptions)
				.then((res) => res.json())
				.then((data) => setResponse(data))
				.catch((error) => {
					console.error("Error:", error);
				});
	}, [
		allLanguages,
		apiUrl,
		fixed,
		fullRelations,
		lang,
		mode,
		order,
		preferenceLanguage,
		quantity,
		site,
		sources,
		lang,
	]);

	return response;
}

export { useReferenceFieldData };
