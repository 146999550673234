import React from "react";
import { connect } from "react-redux";
import { ResizePanel } from "@ax/components";
import { IBreadcrumbItem, IModule, INotification, IRootState, ISchema } from "@ax/types";
import { formsActions } from "@ax/containers/Forms";
import PageBrowser from "../PageBrowser";
import FormConfigPanel from "./FormConfigPanel";

const Editor = (props: IProps) => {
  const {
    isLoading,
    isGlobal,
    theme,
    browserRef,
    schema,
    selectedTab,
    breadcrumb,
    selectedParent,
    setNotification,
    setSelectedTab,
    setSelectedContent,
    addModule,
    deleteModule,
    duplicateModule,
    moveModule,
    copyModule,
    pasteModule,
  } = props;

  const actions = {
    setNotificationAction: setNotification,
    addModuleAction: addModule,
    deleteModuleAction: deleteModule,
    duplicateModuleAction: duplicateModule,
    moveModuleAction: moveModule,
    copyModuleAction: copyModule,
    pasteModuleAction: pasteModule,
  };

  return (
    <ResizePanel
      leftPanel={<PageBrowser isReadOnly={false} theme={theme} browserRef={browserRef} />}
      rightPanel={
        <FormConfigPanel
          schema={schema}
          actions={actions}
          isLoading={isLoading}
          isGlobal={isGlobal}
          theme={theme}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          setSelectedContent={setSelectedContent}
          breadcrumb={breadcrumb}
          selectedParent={selectedParent}
        />
      }
      full={true}
    />
  );
};

interface IEditorStateProps {
  isLoading: boolean;
  selectedTab: string;
  schema: ISchema | Record<string, never>;
  breadcrumb: IBreadcrumbItem[];
  selectedParent: Record<string, unknown> | null;
}

interface IPageBrowserDispatchProps {
  setNotification: (notification: INotification) => void;
  setSelectedTab(tab: string): void;
  setSelectedContent(editorID: number): void;
  addModule: (moduleType: string, key: string) => void;
  deleteModule: (editorID: number[], key?: string) => void;
  duplicateModule: (editorID: number[], key?: string) => number;
  moveModule: (elementID: number[], content: any, newIndex: number, key: string) => void;
  copyModule: (editorID: number[]) => boolean | number;
  pasteModule: (editorID: number, key: string, modulesToPaste: IModule[]) => Promise<{ error?: INotification }>;
  isGlobal: boolean;
  theme: string;
  browserRef: any;
}

type IProps = IEditorStateProps & IPageBrowserDispatchProps;

const mapStateToProps = (state: IRootState): IEditorStateProps => ({
  isLoading: state.app.isLoading,
  selectedTab: state.forms.tab,
  schema: state.forms.schema,
  breadcrumb: state.forms.breadcrumb,
  selectedParent: state.forms.selectedParent,
});

const mapDispatchToProps = {
  setSelectedTab: formsActions.setSelectedTab,
  setSelectedContent: formsActions.setSelectedContent,
  addModule: formsActions.addModule,
  deleteModule: formsActions.deleteModule,
  duplicateModule: formsActions.duplicateModule,
  moveModule: formsActions.moveModule,
  copyModule: formsActions.copyModule,
  pasteModule: formsActions.pasteModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
