import styled from "styled-components";

export const Wrapper = styled.section`
  background: ${(p) => p.theme.color.uiBackground01};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingWrapper = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
`;
