import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { useHandleClickOutside } from "@ax/hooks";
import { getDisplayName, filterByCategory } from "@ax/helpers";
import SideModalOption from "@ax/components/SideModal/SideModalOption";
import { MenuItem, SearchField, IconAction } from "@ax/components";
import { ModuleCategoryInfo } from "@ax/types";

import * as S from "./style";

const SideModal = (props: ISideModalProps): JSX.Element | null => {
  const {
    whiteList,
    optionsType,
    isOpen,
    toggleModal,
    componentOptions,
    categories,
    handleClick,
    current,
    showSearch,
    theme,
  } = props;

  const componentList: any = [];
  const hasCategories = categories && categories.length > 1;
  const hasFieldsStyle = optionsType === "fields";

  const optionList = componentOptions ? componentList : whiteList;

  const filteredValues = {
    options: optionList,
    category: "all",
  };

  const [options, setOptions] = useState(filteredValues);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (componentOptions) {
      for (const key in componentOptions) {
        const currentComponent = current && current.component;
        const availableComponent = componentOptions[key].component;
        if (availableComponent !== currentComponent) {
          componentList.push(componentOptions[key]);
        }
      }
      const filteredOptions = {
        options: componentList,
        category: "all",
      };
      setOptions(filteredOptions);
    } else {
      setOptions(filteredValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, whiteList]);

  const handleToggleModal = () => {
    setSearchQuery("");
    toggleModal();
  };

  const node = useRef<any>(null);

  const handleClickOutside = (e: any) => {
    if (node.current.contains(e.target)) {
      return;
    }

    handleToggleModal();
  };

  useHandleClickOutside(isOpen, handleClickOutside);

  const filterOptionsByCategory = (category: string) => {
    const options = category !== "all" ? filterByCategory(optionList, category) : optionList;

    const filteredValues = {
      options,
      category,
    };

    setOptions(filteredValues);
  };

  const getCategoryItem = (category: ModuleCategoryInfo, i: number) => {
    const { value, label } = category;
    const filterOptions = () => filterOptionsByCategory(value);
    const isSelected = value === options.category;
    const selectedClass = isSelected ? "selected" : "";

    return (
      <MenuItem key={`${value}${i}`} className={selectedClass} onClick={filterOptions}>
        <S.NavLink data-testid="side-modal-nav-link">
          <S.Link data-testid="side-modal-link" active={isSelected}>
            {label}
          </S.Link>
        </S.NavLink>
      </MenuItem>
    );
  };

  const featuredFilters =
    hasCategories &&
    categories &&
    categories
      .filter((cat: any) => cat.featured)
      .map((category: ModuleCategoryInfo, i: number) => {
        return getCategoryItem(category, i);
      });

  const filters =
    hasCategories &&
    categories &&
    categories
      .filter((cat: any) => !cat.featured)
      .map((category: ModuleCategoryInfo, i: number) => {
        return getCategoryItem(category, i);
      });

  const filteredOptions =
    options.options &&
    options.options.map((option: any, i: number) => {
      const displayName = getDisplayName(option.component ? option.component : option);
      if (searchQuery.length > 0) {
        const name = displayName.toLowerCase();
        const search = searchQuery.toLocaleLowerCase();
        if (!name.includes(search)) return null;
      }
      return (
        <SideModalOption
          option={option}
          handleClick={handleClick}
          toggleModal={handleToggleModal}
          key={`${option}${i}`}
          theme={theme}
          smallMargin={hasFieldsStyle}
        >
          {displayName}
        </SideModalOption>
      );
    });

  return isOpen
    ? createPortal(
        <S.Wrapper ref={node} optionsType={optionsType} hasFieldsStyle={hasFieldsStyle} data-testid="side-modal">
          <S.Header hasFieldsStyle={hasFieldsStyle}>
            {!hasFieldsStyle && <S.Title data-testid="side-modal-title">{optionsType}</S.Title>}
            {showSearch && optionsType !== "components" && (
              <S.SearchWrapper>
                <SearchField
                  onChange={setSearchQuery}
                  closeOnInactive={!hasFieldsStyle}
                  small={!filters}
                  placeholder={hasFieldsStyle ? "Search field" : ""}
                />
              </S.SearchWrapper>
            )}
            {!showSearch && (
              <S.ButtonWrapper data-testid="side-modal-close-button">
                <IconAction icon="close" onClick={handleToggleModal} />
              </S.ButtonWrapper>
            )}
          </S.Header>
          <S.ColumnsWrapper>
            {(filters || featuredFilters) && (
              <S.Content>
                {featuredFilters && <S.FeaturedWrapper>{featuredFilters}</S.FeaturedWrapper>}
                {filters && <div>{filters}</div>}
              </S.Content>
            )}
            <S.Content>{filteredOptions}</S.Content>
          </S.ColumnsWrapper>
        </S.Wrapper>,
        document.body
      )
    : null;
};

export interface ISideModalProps {
  isOpen: boolean;
  whiteList?: string[];
  categories?: ModuleCategoryInfo[];
  optionsType: string;
  toggleModal: () => void;
  handleClick?: (moduleType: string) => void;
  componentOptions?: any;
  current?: any;
  showSearch?: boolean;
  theme: string;
}

export default SideModal;
