import React, { useEffect, useRef, useState } from "react";

import { useHandleClickOutside } from "@ax/hooks";

import * as S from "./style";

const FloatingMenu = (props: IFloatingProps): JSX.Element => {
  const {
    children,
    isInAppBar,
    Button,
    position = "right",
    closeOnSelect = true,
    isCheckGroup,
    reactiveToHover,
    offset,
  } = props;
  const wrapper = useRef<any>(null);
  const button = useRef<any>(null);
  const menuOptions = useRef<any>(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen && menuOptions?.current) {
      const bounding = menuOptions.current.children[0].getBoundingClientRect();
      const boundingChild = menuOptions.current.children[0] && menuOptions.current.children[0].getBoundingClientRect();
      if (bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
        menuOptions.current.scrollIntoView({ block: "end", behavior: "smooth" });
      }
      if (boundingChild && boundingChild.right > window.innerWidth) {
        menuOptions.current.style.right = "130px";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleClickOutside = (e: any) => {
    if (wrapper.current.contains(e.target)) {
      return;
    }

    setOpen(false);
  };

  const closeWhenChecked = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLInputElement;
    const isChecked = target && target.checked;
    isChecked && setOpen(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const buttonWasClicked = button.current.contains(e.target);
    const optionWasSelected = isOpen && menuOptions.current.contains(e.target);

    if (buttonWasClicked) {
      setOpen(!isOpen);
    }

    if (optionWasSelected) {
      closeOnSelect ? (isCheckGroup ? closeWhenChecked(e) : setOpen(!isOpen)) : setOpen(true);
    }
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) =>
    reactiveToHover && !isOpen ? handleClick(e) : null;

  const handleMouseLeave = () => reactiveToHover && isOpen && setOpen(false);

  useHandleClickOutside(isOpen, handleClickOutside);

  return (
    <S.Wrapper
      ref={wrapper}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid="floating-menu"
    >
      <S.ButtonWrapper ref={button} data-testid="floating-menu-button">
        <Button />
      </S.ButtonWrapper>

      {isOpen && (
        <S.MenuWrapper
          isInAppBar={isInAppBar}
          ref={menuOptions}
          position={position}
          offset={offset}
          data-testid="floating-menu-wrapper"
        >
          <S.Menu>{children}</S.Menu>
        </S.MenuWrapper>
      )}
    </S.Wrapper>
  );
};

export interface IFloatingProps {
  children: JSX.Element | (JSX.Element | boolean | JSX.Element[])[] | boolean;
  Button: any;
  isInAppBar?: boolean;
  position?: string;
  closeOnSelect?: boolean;
  isCheckGroup?: boolean;
  reactiveToHover?: boolean;
  offset?: number;
}

export default FloatingMenu;
