import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";

import { IGetSitePagesParams, IRootState, IMenuItem } from "@ax/types";

import { sitesActions } from "@ax/containers/Sites";
import { menuActions } from "@ax/containers/Navigation";
import { useShouldBeSaved } from "./helpers";
import { setIsSavedData } from "@ax/forms";

import Nav from "./Nav";
import Table from "./Table";
import * as S from "./style";

const List = (props: IMenuList): JSX.Element => {
  const { getMenus, lang, getSitePages, currentSiteID, editorMenu, savedMenu, savedMenus } = props;

  if (!currentSiteID) {
    throw new Error(`ERROR: User reached Menu List with null site info`);
  }

  const initialState = savedMenus && savedMenus.length ? savedMenus[0].name : "";
  const [currentType, setCurrentType] = useState(initialState);

  const { isDirty } = useShouldBeSaved(editorMenu, savedMenu, currentType);
  const memoizedGetMenus = useCallback(() => getMenus(), [getMenus]);
  const memoizedGetSitePages = useCallback(
    async (params: IGetSitePagesParams) => {
      await getSitePages(params);
    },
    [getSitePages]
  );

  useEffect(() => {
    const params = {
      siteID: currentSiteID,
      deleted: false,
      page: 1,
      itemsPerPage: 50,
    };
    memoizedGetSitePages(params);
    memoizedGetMenus();
    setIsSavedData(true);
  }, [memoizedGetMenus, memoizedGetSitePages, lang.id, currentSiteID]);

  return (
    <S.ListWrapper>
      <Nav isDirty={isDirty} currentType={currentType} setCurrentType={setCurrentType} />
      <S.TableWrapper>
        <Table isDirty={isDirty} />
      </S.TableWrapper>
    </S.ListWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
  lang: state.app.lang,
  editorMenu: state.menu.editorMenu.elements,
  savedMenu: state.menu.savedMenu.elements,
  savedMenus: state.menu.savedMenus,
});

const mapDispatchToProps = {
  getMenus: menuActions.getMenus,
  getSitePages: sitesActions.getSitePages,
};

interface IStateProps {
  currentSiteID: number | null;
  lang: { locale: string; id: number | null };
  editorMenu: IMenuItem[] | undefined;
  savedMenu: IMenuItem[] | undefined;
  savedMenus: IMenuItem[] | null;
}

interface IDispatchProps {
  getMenus(): void;
  getSitePages(params: IGetSitePagesParams): Promise<void>;
}

type IMenuList = IStateProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(List);
