import styled from "styled-components";

const Item = styled.li<{ smallMargin: boolean }>`
  cursor: pointer;
  padding: ${(p) => p.theme.spacing.xs};
  padding-bottom: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => (p.smallMargin ? p.theme.spacing.xs : p.theme.spacing.s)};
  box-shadow: ${(p) => p.theme.shadow.shadowS};
  border-radius: ${(p) => p.theme.radii.s};
  ${(p) => p.theme.textStyle.uiS};
  background-color: ${(p) => p.theme.color.interactiveBackground};
  &:hover {
    background: ${(p) => p.theme.color.overlayHoverPrimary};
  }
  &:focus {
    background-color: ${(p) => p.theme.color.overlayFocusPrimary};
  }
`;

const Thumbnail = styled.img`
  cursor: pointer;
  padding-bottom: ${(p) => p.theme.spacing.s};
`;

const TagWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.xs};
`;

export { Item, Thumbnail, TagWrapper };
