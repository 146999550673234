import styled, { keyframes } from "styled-components";

const Wrapper = styled.section<{ fixedAppBar: boolean | undefined }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${(p) => (p.fixedAppBar ? p.theme.spacing.xl : "0")};
`;

const Main = styled.article<{ fullWidth: boolean | undefined }>`
  position: relative;
  overflow: ${(p) => (p.fullWidth ? "visible" : "auto")};
  background: ${(p) => p.theme.color.uiBackground01};
  margin-top: -1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const LoginAnimation = keyframes`
  from  {
    width: 100%;
  }
  to {
    width: 72px;
  }
}`;

const OpacityAnimation = keyframes`
  from  {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}`;

const BackgroundAnimation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${(p) => p.theme.color.uiMainMenuBackground};
  z-index: 10;
  animation:
    ${LoginAnimation} 1.2s ease-in,
    ${OpacityAnimation} 1s ease-in 1.2s;
  animation-fill-mode: forwards;
`;

export { Wrapper, Main, BackgroundAnimation };
