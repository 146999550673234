import React, { useEffect, useState } from "react";

import { checkgroups } from "@ax/api";
import { isReqOk } from "@ax/helpers";
import { ISite, ICheck } from "@ax/types";

import * as S from "./style";

const AsyncCheckGroup = (props: IAsyncCheckGroup): JSX.Element => {
  const {
    onChange,
    value,
    site,
    source,
    disabled,
    error,
    handleValidation,
    fullHeight,
    allLanguages = false,
    contentType = "data",
  } = props;

  const [options, setOptions] = useState<ICheckValue[]>([]);
  const safeValue = value && Array.isArray(value) ? value : [];

  const isCheckValue = (object: any): object is ICheckValue => "value" in object;

  const getAllIds = (results: ICheckValue[]) => {
    return results.reduce((acc: number[], current) => {
      acc.push(current.value);
      const children = getAllIds(current.children || []);
      if (children.length) {
        acc = [...acc, ...children];
      }
      return acc;
    }, []);
  };

  useEffect((): any => {
    let isSubscribed = true;

    const getItems = async (): Promise<ICheckValue[]> => {
      try {
        let result = null;
        const siteID = site ? site.id : "global";
        result =
          contentType === "formCategory"
            ? await checkgroups.getFormCategories(siteID, source)
            : await checkgroups.getCheckGroupItems(siteID, source, allLanguages);
        if (result && isReqOk(result.status)) {
          return result.data;
        }
      } catch (e) {
        console.log(e);
      }
      return [];
    };

    getItems()
      .then((result) => {
        if (isSubscribed) {
          setOptions(result);
          if (safeValue.length > 0 && isCheckValue(safeValue[0])) {
            // clean array of old values and set translations
            const resultIDs = result && getAllIds(result);
            const fixedState = safeValue.reduce((acc: ICheckValue[], current: ICheckValue) => {
              if (resultIDs.includes(current.value)) {
                return [...acc, current];
              } else {
                const trad = result.find(
                  (value: ICheckValue) =>
                    value.dataLanguages && value.dataLanguages.find((data: any) => data.id === current.value)
                );
                if (trad) {
                  return [...acc, trad];
                }
                return acc;
              }
            }, []);
            onChange(fixedState);
          }
        }
      })
      .catch((apiError) => console.log(apiError));

    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, source, allLanguages]);

  const filterOptions = (options: ICheckValue[], ids: number[]) => {
    return options.reduce((acc: ICheckValue[], current) => {
      if (ids.includes(current.value)) {
        acc.push(current);
      }
      const children = filterOptions(current.children || [], ids);
      if (children.length) {
        acc = [...acc, ...children];
      }
      return acc;
    }, []);
  };

  const handleChange = (newValue: ICheck) => {
    const arrayIds = safeValue.map((e: any) => (typeof e === "number" ? e : e.value));

    let newArray: number[] = [];
    const isInArray = arrayIds.find((e: any) => e === newValue.value);

    if (isInArray && !newValue.isChecked) {
      newArray = arrayIds.filter((e: any) => e !== newValue.value);
    }

    if (!isInArray && newValue.isChecked) {
      newArray = [...arrayIds, newValue.value];
    }

    const newArrayObject = filterOptions(options, newArray);

    onChange(newArrayObject);
    error && handleValidation && handleValidation(newArrayObject);
  };

  const isChecked = (id: number): boolean =>
    !!safeValue.find((e: ICheckValue | number) => (typeof e === "number" ? e === id : e.value === id));

  const renderChecks = (options: ICheckValue[]): JSX.Element[] => {
    return options.map((item) => {
      return (
        <React.Fragment key={`${item.name}${item.value}`}>
          {item.type === "group" ? (
            <S.GroupTitle>{item.title}</S.GroupTitle>
          ) : (
            <S.StyledCheckField
              onChange={handleChange}
              checked={isChecked(item.value)}
              value={item.value}
              title={item.title}
              name={item.name}
              disabled={item.disabled || disabled}
              error={item.error}
            />
          )}
          {item.children && <S.GroupWrapper>{renderChecks(item.children)}</S.GroupWrapper>}
        </React.Fragment>
      );
    });
  };

  return <S.FieldGroup full={fullHeight}>{renderChecks(options)}</S.FieldGroup>;
};

interface ICheckValue {
  value: number;
  name: string;
  title: string;
  dataLanguages?: { id: number; language: number }[];
  type: "category" | "group";
  children?: ICheckValue[];
  selectable?: boolean;
  disabled?: boolean;
  error?: boolean;
}

export interface IAsyncCheckGroup {
  value: any[] | null;
  site?: ISite | null;
  source: string;
  onChange: (value: ICheckValue[]) => void;
  disabled?: boolean;
  error?: boolean;
  handleValidation?: (value: string | any[]) => void;
  validators?: Record<string, unknown>;
  fullHeight?: boolean;
  allLanguages?: boolean;
  contentType: "data" | "formCategory";
}

export default AsyncCheckGroup;
