import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  padding-bottom: ${(p) => `calc(${p.theme.spacing.l} + ${p.theme.spacing.s})`};
`;

const FormWrapper = styled.div`
  position: relative;
  padding: ${(p) => p.theme.spacing.m};
  height: 100%;
  overflow: auto;
`;

const Note = styled.div`
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  margin-top: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.m};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
`;

const Title = styled.div`
  ${(p) => p.theme.textStyle.fieldLabel};
  display: flex;
  margin-bottom: ${(p) => p.theme.spacing.xs};
  align-items: center;
`;

const IconWrapper = styled.div`
  width: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.s};
  margin-right: ${(p) => p.theme.spacing.xxs};
`;

const ActionsWrapper = styled.div`
  display: flex;
  padding: ${(p) => `${p.theme.spacing.s} ${p.theme.spacing.m} ${p.theme.spacing.m} ${p.theme.spacing.m}`};
  border-top: 1px solid ${(p) => p.theme.color.uiLine};
  align-items: center;
  justify-content: flex-end;
  gap: ${(p) => p.theme.spacing.s};
  button {
    flex-shrink: 0;
  }
`;

export { Wrapper, FormWrapper, Note, Title, Text, IconWrapper, ActionsWrapper };
