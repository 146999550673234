export interface RecursiveSearchProps {
	obj?: Record<string, string>;
	searchKey?: string;
	resultsBuffer?: Array<string>;
	unique?: boolean;
}

/**
 * recursiveSearch
 * Devuelve una lista con los valores de una prop dentro de un objeto
 * La búsqueda es recursiva en todo el objeto
 * @param config Un objeto con los parámetros de la función
 * @param config.obj El objeto donde buscar
 * @param config.searchKey El nombre de la propiedad a buscar
 * @param config.results El array de resultados, se van añadiendo recursivamente los resultados.
 * @param config.unique Si es true elimina los resultados repetidos
 * @example
 * Obtiene una lista con los valores de la prop "a"
 * recursiveSearch({ obj: {a: 1, b: {a: "foo"}}, "a"})
 * -> [1, "foo"]
 *
 * @return Un array con los valores de la propiedad `searchValue`
 */
function recursiveSearch({
	obj,
	searchKey,
	resultsBuffer = [],
	unique = true,
}: RecursiveSearchProps = {}) {
	if (!obj) return [];

	const results = resultsBuffer;

	for (const key of Object.keys(obj)) {
		const value = obj[key];
		if (key === searchKey && typeof value !== "object") {
			results.push(value);
		} else if (typeof value === "object") {
			value &&
				recursiveSearch({ obj: value, searchKey, resultsBuffer: results });
		}
	}

	return unique ? [...new Set(results)] : results;
}

export { recursiveSearch };
