const NAME = "forms";

const SET_FORMS = `${NAME}/SET_FORMS`;
const SET_FORM_CONTENT = `${NAME}/SET_FORM_CONTENT`;
const SET_SCHEMA = `${NAME}/SET_SCHEMA`;
const SET_TAB = `${NAME}/SET_TAB`;
const SET_TEMPLATE = `${NAME}/SET_TEMPLATE`;
const SET_CURRENT_FORM_ID = `${NAME}/SET_CURRENT_FORM_ID`;
const SET_SELECTED_CONTENT = `${NAME}/SET_SELECTED_CONTENT`;
const SET_SELECTED_PARENT = `${NAME}/SET_SELECTED_PARENT`;
const SET_SELECTED_EDITOR_ID = `${NAME}/SET_SELECTED_ID`;
const SET_BREADCRUMB = `${NAME}/SET_BREADCRUMB`;
const SET_COPY_MODULE = `${NAME}/SET_COPY_MODULE`;
const SET_SUMMARY = `${NAME}/SET_SUMMARY`;
const SET_CURRENT_FORM_CATEGORIES = `${NAME}/SET_CURRENT_FORM_CATEGORIES`;
const SET_IS_NEW_TRANSLATION = `${NAME}/SET_IS_NEW_TRANSLATION`;
const SET_IS_IA_TRANSLATED = `${NAME}/SET_IS_IA_TRANSLATED`;

const ITEMS_PER_PAGE = 50;

const DEFAULT_PARAMS = {
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  pagination: true,
};

export {
  NAME,
  SET_FORMS,
  SET_FORM_CONTENT,
  SET_SCHEMA,
  SET_TAB,
  SET_TEMPLATE,
  SET_CURRENT_FORM_ID,
  SET_SELECTED_CONTENT,
  SET_SELECTED_EDITOR_ID,
  SET_SELECTED_PARENT,
  SET_BREADCRUMB,
  SET_COPY_MODULE,
  SET_SUMMARY,
  ITEMS_PER_PAGE,
  DEFAULT_PARAMS,
  SET_CURRENT_FORM_CATEGORIES,
  SET_IS_NEW_TRANSLATION,
  SET_IS_IA_TRANSLATED,
};
