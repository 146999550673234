import React, { useState, memo } from "react";

import { ISite } from "@ax/types";
import AsyncCheckGroup from "@ax/components/Fields/AsyncCheckGroup";
import CheckGroup from "@ax/components/Fields/CheckGroup";

import * as S from "./style";

const MultiCheckSelect = (props: IMultiCheckSelectProps) => {
  const {
    placeholder,
    source,
    value,
    onChange,
    site,
    className,
    mandatory,
    options,
    selectAllOption,
    floating,
    disabled,
    contentType = "data",
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => setIsOpen(!isOpen);

  const Asterisk = () => (mandatory ? <S.Asterisk>*</S.Asterisk> : null);

  return (
    <S.Wrapper className={className} data-testid="multi-check-select-wrapper">
      <S.Field isOpen={isOpen} onClick={handleClick} disabled={disabled} data-testid="field">
        {placeholder} <Asterisk />
      </S.Field>
      {isOpen && (
        <S.DropDown floating={floating} isOpen={isOpen}>
          {options && (
            <CheckGroup
              options={options}
              value={value}
              onChange={onChange}
              selectAllOption={selectAllOption}
              disabled={disabled}
            />
          )}
          {source && !options && (
            <AsyncCheckGroup
              source={source}
              site={site}
              value={value}
              onChange={onChange}
              fullHeight={true}
              disabled={disabled}
              contentType={contentType}
            />
          )}
        </S.DropDown>
      )}
    </S.Wrapper>
  );
};

export interface IMultiCheckSelectProps {
  value: any[];
  onChange: (value: string | any[]) => void;
  site?: ISite | null;
  placeholder: string;
  source?: string;
  className?: string;
  mandatory?: boolean;
  options?: { name: string; value: string; title: string }[];
  selectAllOption?: string;
  floating?: boolean;
  disabled?: boolean;
  contentType: "data" | "formCategory";
}

export default memo(MultiCheckSelect);
