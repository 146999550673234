import React from "react";
import { connect } from "react-redux";
import { DraggableProvided } from "react-beautiful-dnd";

import { formsActions } from "@ax/containers/Forms";
import { useModal } from "@ax/hooks";
import { FormCategory, ICheck } from "@ax/types";
import { CheckField, Icon } from "@ax/components";
import CategoryPanel from "../CategoryPanel";
import { DeleteModal } from "../atoms";

import * as S from "./style";

const CategoryItem = (props: ICategoryItemProps): JSX.Element => {
  const { category, isSelected, hoverCheck, isDragging, provided, deleteFormCategory, onChange, toggleToast } = props;

  const { isOpen, toggleModal } = useModal();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();

  const { content } = category;

  const handleClick = () => !isDragging && toggleModal();

  const handleCloseModal = () => {
    toggleModal();
  };

  const handleOnChange = (value: ICheck) => onChange(value);

  const handleCheckClick = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  const removeCategory = async () => {
    const deleted = await deleteFormCategory(category.id, category.categoryType);

    if (deleted) {
      isDeleteOpen && toggleDeleteModal();
      toggleToast("1 form category deleted");
    }
  };

  const menuOptions = [
    {
      label: "delete",
      icon: "delete",
      action: toggleDeleteModal,
    },
  ];

  const mainDeleteModalAction = {
    title: "Yes, delete it",
    onClick: removeCategory,
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  return (
    <>
      <S.CategoryRow
        role="rowgroup"
        selected={isSelected}
        onClick={handleClick}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <S.HandleCell>
          <S.IconWrapperDrag role="cell">
            <Icon name="drag" size="16" />
          </S.IconWrapperDrag>
        </S.HandleCell>
        <S.CheckCell role="cell" onClick={handleCheckClick}>
          <CheckField name="check" value={category.id} checked={isSelected || hoverCheck} onChange={handleOnChange} />
        </S.CheckCell>
        <S.NameCell role="cell" clickable={true}>
          <div>{content?.title || ""}</div>
        </S.NameCell>
        <S.CodeCell role="cell" clickable={true}>
          {category.content.code}
        </S.CodeCell>
        <S.ActionsCell role="cell">
          <S.StyledActionMenu icon="more" options={menuOptions} tooltip="Actions" />
        </S.ActionsCell>
      </S.CategoryRow>
      {isOpen && (
        <CategoryPanel
          isOpen={isOpen}
          toggleModal={handleCloseModal}
          item={category}
          toggleToast={toggleToast}
          type={category.categoryType}
        />
      )}
      {isDeleteOpen && (
        <DeleteModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainDeleteModalAction}
          secondaryModalAction={secondaryDeleteModalAction}
        />
      )}
    </>
  );
};

interface IProps {
  category: FormCategory;
  isSelected: boolean;
  onChange: (e: any) => void;
  toggleToast(state: any): void;
  hoverCheck?: boolean;
  isDragging: boolean;
  provided: DraggableProvided;
}

interface IDispatchProps {
  deleteFormCategory(id: number | number[], categoryType: string): Promise<boolean>;
}

type ICategoryItemProps = IProps & IDispatchProps;

const mapDispatchToProps = {
  deleteFormCategory: formsActions.deleteFormCategory,
};

export default connect(null, mapDispatchToProps)(CategoryItem);
