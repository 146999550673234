import React, { useState } from "react";

import { IModal, ISchema } from "@ax/types";
import { Modal, SearchField } from "@ax/components";
import { getFormTemplateThumbnailProps } from "@ax/helpers";

import * as S from "./style";

const TemplateModal = (props: ITemplateModal): JSX.Element => {
  const {
    isOpen,
    toggleModal,
    mainModalAction,
    secondaryModalAction,
    templates,
    selectedTemplate,
    setSelectedTemplate,
  } = props;

  const init = Object.values(templates);
  const [items, setItems] = useState(init);

  const handleSearch = (query: string) => {
    const filteredItems = init.filter((item) => item.displayName?.toLowerCase().includes(query.toLowerCase()));
    setItems(filteredItems);
  };

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="New form: Select a template to start creating a new form"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="L"
    >
      <S.ModalContent>
        <S.FiltersBar>
          <SearchField onChange={handleSearch} placeholder="Search" autoFocus={false} searchOnEnter={false} />
        </S.FiltersBar>
        <S.TemplateGrid>
          {items.map((template) => {
            const handleClick = () => setSelectedTemplate(template);
            const isSelected = template.component === selectedTemplate?.component;
            const thumbnailProps = getFormTemplateThumbnailProps(template.component);
            return (
              <S.TemplateItem key={template.component}>
                <S.Title>{template.displayName}</S.Title>
                <S.ThumbnailWrapper onClick={handleClick} isSelected={isSelected}>
                  <S.Thumbnail {...thumbnailProps} />
                </S.ThumbnailWrapper>
              </S.TemplateItem>
            );
          })}
        </S.TemplateGrid>
      </S.ModalContent>
    </Modal>
  );
};

interface ITemplateModal extends IModal {
  templates: Record<string, ISchema>;
  selectedTemplate: ISchema | null;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<ISchema | null>>;
}

export { TemplateModal };
