import React from "react";

import { IErrorItem, ISchemaField, ISite } from "@ax/types";
import Field from "../Field";

import * as S from "./style";

export const TemplateManager = (props: IProps): JSX.Element => {
  const {
    template,
    selectedTab,
    selectedContent,
    objKey,
    goTo,
    updateValue,
    pages,
    actions,
    site,
    disabled,
    errors,
    theme,
    moduleCopy,
    setHistoryPush,
    lang,
  } = props;

  const isConfig = selectedTab === "config";
  const templateFields = template[selectedTab];
  const templateContent = selectedContent.template;

  const getFieldProps = (field: ISchemaField) => {
    const { key, whiteList = [], readonly, disabled } = field;

    const handleUpdate = (fieldKey: string, value: any) => updateValue(fieldKey, value, templateContent.editorID);
    const error = errors.find((err: any) => err.editorID === templateContent.editorID && err.key === key);

    return {
      whiteList,
      key,
      handleUpdate,
      error,
      readonly,
      disabledField: disabled,
    };
  };

  return (
    <>
      {isConfig && templateFields && <S.Title>Template Options</S.Title>}
      {templateFields &&
        templateFields
          .filter((templateField: ISchemaField) => !templateField.hidden)
          .map((templateField: ISchemaField, index: number) => {
            const { whiteList, key, handleUpdate, error, readonly, disabledField } = getFieldProps(templateField);

            return (
              <Field
                whiteList={whiteList}
                key={`${objKey}.${key}${index}`}
                objKey={key}
                field={templateField}
                selectedContent={templateContent}
                goTo={goTo}
                updateValue={handleUpdate}
                pages={pages}
                actions={actions}
                site={site}
                disabled={disabled || disabledField}
                error={error}
                errors={errors}
                theme={theme}
                moduleCopy={moduleCopy}
                template={template}
                setHistoryPush={setHistoryPush}
                lang={lang}
                readonly={readonly}
              />
            );
          })}
    </>
  );
};

interface IProps {
  template: any;
  updateValue: (key: string, value: any, templateID?: number) => void;
  goTo: any;
  objKey: string;
  pages: any;
  selectedTab: string;
  selectedContent: any;
  actions: any;
  site?: ISite;
  disabled?: boolean;
  errors: IErrorItem[];
  theme: string;
  moduleCopy: { date: string; element: Record<string, unknown> } | null;
  setHistoryPush?: (path: string, isEditor: boolean) => void;
  lang: number;
}

export default TemplateManager;
