import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  background-color: ${(p) => p.theme.color.uiBackground02};
  column-gap: ${(p) => p.theme.spacing.s};
  padding: ${(p) => p.theme.spacing.s};
  margin: ${(p) => `${p.theme.spacing.m} ${p.theme.spacing.m} 0 ${p.theme.spacing.m}`};
  width: 694px;
  border: ${(p) => `1px solid ${p.theme.color.uiLine}`};
  border-radius: ${(p) => p.theme.radii.s};
`;

const Card = styled.div<{ color: string }>`
  display: flex;
  background-color: ${(p) => p.color};
  width: 210px;
  height: 82px;
  border-radius: ${(p) => p.theme.radii.s};
  :hover {
    cursor: pointer;
  }
`;

const Description = styled.div`
  margin: auto 0;
`;

const Number = styled.div`
  margin: ${(p) => `auto ${p.theme.spacing.s}`};
  font-size: 51px;
  font-weight: 400;
  line-height: 64.11px;
  letter-spacing: 0.5px;
  color: ${(p) => p.theme.color.textHighEmphasis};
`;

const IconWrapper = styled.div`
  width: ${(p) => p.theme.spacing.m};
  height: ${(p) => p.theme.spacing.m};
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-top: ${(p) => p.theme.spacing.xxs};
`;

export { Wrapper, Card, Description, Number, IconWrapper, Text };
