import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { withErrorBoundary } from "react-error-boundary";

import {
  IErrorItem,
  INotification,
  IRootState,
  ISavePageParams,
  IUserEditing,
  IPageLanguage,
  ILanguage,
} from "@ax/types";
import {
  MainWrapper,
  Loading,
  ErrorToast,
  Notification,
  Modal,
  ErrorPage,
  CancelScheduleModal,
  ScheduleModal,
} from "@ax/components";
import { pageEditorActions } from "@ax/containers/PageEditor";
import { appActions } from "@ax/containers/App";
import { navigationActions } from "@ax/containers/Navigation";
import { pageStatus } from "@ax/containers/PageEditor/interfaces";
import { RouteLeavingGuard } from "@ax/guards";
import { useIsDirty, useModal, usePermission } from "@ax/hooks";
import { isModuleDisabled, getDeactivatedModules, dateToString } from "@ax/helpers";
import { dataPacksActions } from "@ax/containers/Settings/DataPacks";
import { DeleteModal } from "./atoms";
import Editor from "./Editor";
import Preview from "./Preview";

import * as S from "./style";

const PageEditor = (props: IProps) => {
  const {
    pageID,
    isLoading,
    isSaving,
    pageName,
    lang,
    setLanguage,
    createNewTranslation,
    siteLanguages,
    pageLanguages,
    getPage,
    activatedTemplates,
    selectedComponent,
    activatedModules,
    getSiteDataPackbyModule,
    getSiteDataPackbyTemplate,
    setHistoryPush,
    getDefaults,
    errors,
    setSelectedContent,
    setTab,
    editorContent,
    schema,
    userEditing,
    isNewTranslation,
    currentSiteErrorPages,
    schedulePublication,
    setCurrentPageID,
  } = props;

  const isAllowedToPublishPages = usePermission("content.publishUnpublishPages");
  const isAllowedToCreatePages = usePermission("content.createPages");
  const isAllowedToDeletePage = usePermission("content.deletePages");
  const isAllowedToDeletePublishedPage = usePermission("content.deletePublishedPages");
  const isAllowedToEditContentPage = usePermission("content.editContentPages");

  const defaultTab = isAllowedToEditContentPage ? "edit" : "view";

  const [deleteAllVersions, setDeleteAllVersions] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [notification, setNotification] = useState<INotification | null>(null);
  const [scheduleDate, setScheduleDate] = useState({ date: "", time: "12:00 am" });
  const { isDirty, setIsDirty, resetDirty } = useIsDirty(editorContent, isNewTranslation);
  const { isOpen, toggleModal } = useModal();
  const { isOpen: isUnpublishOpen, toggleModal: toggleUnpublishModal } = useModal();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isScheduleOpen, toggleModal: toggleScheduleModal } = useModal();
  const { isOpen: isCancelScheduleOpen, toggleModal: toggleCancelScheduleModal } = useModal();
  const [errorPagesChecked, setErrorPagesChecked] = useState(false);
  const browserRef = useRef<HTMLDivElement>(null);

  const isGlobal = editorContent && editorContent.origin === "GLOBAL";
  const isEditable = editorContent && editorContent.editable;
  const isPublished = props.pageStatus === pageStatus.PUBLISHED || props.pageStatus === pageStatus.UPLOAD_PENDING;
  const isDraft = props.pageStatus === pageStatus.MODIFIED || !!editorContent.draftFromPage;
  const hasDraft = editorContent && editorContent.haveDraftPage;
  const isLivePageChanged = editorContent && editorContent.liveChanged;
  const isTranslated = pageLanguages.length > 1;
  const structuredData = editorContent ? editorContent.structuredData : "";
  const isEditLive = isPublished && hasDraft;
  const isAllowedToDelete = (isPublished && isAllowedToDeletePublishedPage) || (!isPublished && isAllowedToDeletePage);
  const canBeUnpublished = editorContent && editorContent.canBeUnpublished;
  const deleteHelpText = !canBeUnpublished ? "This is the canonical site of the page. You cannot unpublish it." : null;
  const isScheduled = !!editorContent && !!editorContent.publicationScheduled;

  const errorNotificationText =
    "There are some errors on the page so you can not publish yet. Please review them in the error panel.";
  const validatedNotificationText = "Everything seems ok, you can publish the page.";

  useEffect(() => {
    const { pageID, getPage, setTab, sendPagePing } = props;
    const defaultTab = "content";
    const handleGetPage = async () => await getPage(pageID);

    setTab(defaultTab);
    resetDirty();
    handleGetPage();

    if (!pageID) {
      setIsDirty(false);
    }

    const interval = setInterval(() => {
      pageID && sendPagePing(pageID);
    }, 30000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { pageID, sendPagePing, currentUserID } = props;
    if (userEditing && userEditing.id !== currentUserID) {
      setIsReadOnly(true);
      !isOpen && toggleModal();
    } else {
      setIsReadOnly(false);
      pageID && sendPagePing(pageID);
      isOpen && toggleModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEditing]);

  useEffect(() => {
    if (!errorPagesChecked && !isLoading && editorContent) {
      const pageId = editorContent.id;
      const hasErrors = currentSiteErrorPages.some((errorPageId) => pageId === errorPageId);
      hasErrors && reviewPage();
      setErrorPagesChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorContent]);

  const setRoute = (path: string) => props.setHistoryPush(path, true);

  const removePage = async () => {
    const { deletePage, deleteBulk } = props;
    const path = "/sites/pages";

    const allPageVersions = pageLanguages.map((lang: IPageLanguage) => lang.pageId);
    const isDeleted = deleteAllVersions ? await deleteBulk(allPageVersions) : await deletePage();

    toggleDeleteModal();
    if (isDeleted) {
      setRoute(path);
    }
  };

  const publishPage = async () => {
    const { updatePageStatus, savePage, pageID, validatePage, skipReviewOnPublish } = props;

    const validated = skipReviewOnPublish ? true : await validatePage(true);

    if (validated) {
      const publishPage = {
        status: pageStatus.UPLOAD_PENDING,
      };

      const isSaved = pageID
        ? await updatePageStatus([pageID], pageStatus.UPLOAD_PENDING)
        : await savePage(false, publishPage);

      if (isSaved) {
        resetDirty();
      }
    } else {
      setNotification({ text: errorNotificationText, type: "error" });
    }
  };

  const publishChanges = async () => {
    const { savePage, validatePage, skipReviewOnPublish } = props;

    const validated = skipReviewOnPublish ? true : await validatePage(true);

    if (validated) {
      const publishPage = {
        status: pageStatus.UPLOAD_PENDING,
      };

      const isSaved = await savePage(false, publishPage);
      if (isSaved) {
        resetDirty();
      }
    } else {
      setNotification({ text: errorNotificationText, type: "error" });
    }
  };

  const unpublishPage = async () => {
    const { updatePageStatus, pageID } = props;

    const isSaved = await updatePageStatus([pageID], pageStatus.OFFLINE_PENDING);
    if (isSaved) {
      resetDirty();
    }
  };

  const reviewPage = async () => {
    const { validatePage } = props;
    const validated = await validatePage(false);
    if (validated) {
      setNotification({ text: validatedNotificationText, type: "success" });
    } else {
      setNotification({ text: errorNotificationText, type: "error" });
    }
  };

  const handlePublishDraft = async () => {
    const { savePage, validatePage, skipReviewOnPublish } = props;

    const validated = skipReviewOnPublish ? true : await validatePage(true);

    if (validated) {
      const isSaved = await savePage(false, null, true);
      if (isSaved) resetDirty();
    } else {
      setNotification({ text: errorNotificationText, type: "error" });
    }
  };

  const handleDiscardDraft = () => {
    const { discardDraft } = props;
    resetDirty();
    discardDraft();
  };

  const handleDiscarChanges = () => {
    const { getPage, pageID } = props;
    resetDirty();

    getPage(pageID);
  };

  const handleCreateDraft = async () => {
    const { savePage } = props;

    const isSaved = await savePage(true);
    if (isSaved) resetDirty();
  };

  const handleSchedulePublication = async () => {
    const date = new Date(`${scheduleDate.date} ${scheduleDate.time}`);
    const dateString = dateToString(date, "dd/MM/yyyy HH:mm:ss");
    const saved = await schedulePublication(dateString, isDraft);
    if (saved) {
      resetDirty();
      toggleScheduleModal();
    }
  };

  const handleCancelSchedulePublication = async () => {
    const saved = await schedulePublication(null, isDraft);
    if (saved) {
      setScheduleDate({ date: "", time: "12:00 am" });
      resetDirty();
      toggleCancelScheduleModal();
    }
  };

  const getPublishButton = (status: string) => {
    switch (status) {
      case pageStatus.OFFLINE:
      case pageStatus.OFFLINE_PENDING:
        return {
          label: status === pageStatus.OFFLINE_PENDING ? "Cancel Unpublishing" : "Publish",
          action: publishPage,
        };
      case pageStatus.PUBLISHED:
      case pageStatus.UPLOAD_PENDING:
        return !isGlobal && !hasDraft && isDirty
          ? {
              label: "Create new draft",
              action: handleCreateDraft,
            }
          : {
              label: status === pageStatus.UPLOAD_PENDING ? "Cancel publication" : "Unpublish",
              action: hasDraft ? toggleUnpublishModal : unpublishPage,
              disabled: !canBeUnpublished,
              helpText: deleteHelpText,
            };
      case pageStatus.MODIFIED:
      case pageStatus.SCHEDULED:
        return {
          label: isDraft ? "Publish changes" : "Publish",
          action: isDraft ? handlePublishDraft : publishPage,
        };
      default:
        return null;
    }
  };

  const menuOptions = [];

  if (isAllowedToPublishPages && !isScheduled && !isPublished && props.pageStatus !== pageStatus.OFFLINE_PENDING) {
    menuOptions.push({
      label: "Schedule",
      icon: "calendar",
      action: toggleScheduleModal,
    });
  }

  if (isAllowedToPublishPages && isScheduled) {
    menuOptions.push({
      label: "Cancel Schedule",
      icon: "cancelEvent",
      action: toggleCancelScheduleModal,
    });
  }

  if (
    isAllowedToEditContentPage &&
    (props.pageStatus === pageStatus.PUBLISHED ||
      props.pageStatus === pageStatus.OFFLINE ||
      props.pageStatus === pageStatus.OFFLINE_PENDING ||
      props.pageStatus === pageStatus.SCHEDULED ||
      (isDraft && isDirty))
  ) {
    menuOptions.push({
      label: "Review",
      icon: "question",
      action: reviewPage,
    });
  }

  if (props.pageStatus === pageStatus.PUBLISHED && !hasDraft && isDirty && isAllowedToPublishPages) {
    menuOptions.push({
      label: "Unpublish",
      icon: "offline",
      action: isDraft ? toggleUnpublishModal : unpublishPage,
    });
  }

  if (isAllowedToEditContentPage && !isScheduled && (isDraft || (isPublished && isDirty))) {
    menuOptions.push({
      label: "Discard changes",
      icon: "close",
      action: isDraft ? handleDiscardDraft : handleDiscarChanges,
    });
  }

  if (!isDraft && isAllowedToDelete) {
    menuOptions.push({
      label: "Delete page",
      icon: "delete",
      action: toggleDeleteModal,
    });
  }

  const downArrowMenu = {
    displayed: !isReadOnly,
    button: isAllowedToPublishPages ? getPublishButton(props.pageStatus) : undefined,
    options: !isGlobal ? menuOptions : [],
  };

  const handleSavePage = async () => {
    const { savePage } = props;

    const isSaved = await savePage(false);
    if (isSaved) resetDirty();
  };

  const goToPages = (path: string) => setRoute(path);

  const goToError = async (editorID: number | null, tab: string, template: boolean) => {
    const realEditorID = template ? 0 : editorID;
    if (realEditorID !== null) {
      await setSelectedContent(realEditorID);
      setTab(tab);
    }
  };

  const modalText = (
    <>
      Some content <strong>is not saved</strong> on this page.
    </>
  );

  let isTemplateActivated = true;
  let hasDeactivatedModules = false;
  let deactivatedModules: string[] = [];
  if (editorContent && editorContent.template) {
    const editorTemplate = editorContent.template;
    const mainContentModules = editorTemplate?.mainContent?.modules;

    if (mainContentModules) {
      deactivatedModules = getDeactivatedModules(activatedModules, mainContentModules);
      hasDeactivatedModules = deactivatedModules.length > 0;
    } else {
      hasDeactivatedModules = isModuleDisabled(selectedComponent, schema.schemaType, activatedModules);
    }

    isTemplateActivated = activatedTemplates.find((temp) => temp.id === editorTemplate.templateType) ? true : false;
  }

  let availableLanguages = siteLanguages;
  if (!isTemplateActivated || hasDeactivatedModules || !isAllowedToCreatePages) {
    const pageLanguagesIDs = pageLanguages.map((language) => language.languageId);
    availableLanguages = siteLanguages.filter((language) => {
      return pageLanguagesIDs.includes(language.id);
    });
  }

  const getSaveLabel = () => {
    switch (props.pageStatus) {
      case pageStatus.PUBLISHED:
      case pageStatus.UPLOAD_PENDING:
        return !isDirty && !isNewTranslation
          ? "Saved"
          : isGlobal || (isPublished && hasDraft)
            ? "Save & publish"
            : "Publish changes";
      default:
        return !pageID || isNewTranslation || (isDirty && props.pageStatus !== pageStatus.MODIFIED)
          ? "Save"
          : isDirty && props.pageStatus === pageStatus.MODIFIED
            ? "Save draft"
            : "Saved";
    }
  };

  const rightButtonProps = isAllowedToEditContentPage
    ? {
        label: isSaving ? "Saving" : getSaveLabel(),
        disabled:
          (!isDirty && pageID !== null && !isNewTranslation) ||
          isSaving ||
          isReadOnly ||
          (!isTemplateActivated && !isGlobal),
        action: isPublished ? publishChanges : handleSavePage,
      }
    : undefined;

  const goToLivePage = () => {
    const currentPageUrl = editorContent.fullUrl;
    if (currentPageUrl) {
      window.open(currentPageUrl, "_blank");
    }
  };

  const toggleDraftPage = async () => {
    const { getPage } = props;
    resetDirty();
    const pageID = isDraft ? editorContent.draftFromPage : editorContent.haveDraftPage;
    await getPage(pageID);
  };

  let pageStatusActions =
    props.pageStatus === pageStatus.PUBLISHED || isDraft
      ? [
          {
            label: "Go to live page",
            icon: "view",
            action: goToLivePage,
          },
        ]
      : [];

  pageStatusActions =
    isDraft || hasDraft
      ? [
          {
            label: isDraft ? "View live" : "Edit draft",
            icon: isDraft ? "active" : "modified",
            action: toggleDraftPage,
          },
          ...pageStatusActions,
        ]
      : pageStatusActions;

  const packNotificationText =
    "This content is part of disabled content type package. To edit it, you must first activate it.";

  const handleClickNotification = () => {
    if (editorContent && editorContent.template) {
      const editorTemplate = editorContent.template;
      if (!isTemplateActivated) {
        getSiteDataPackbyTemplate(editorTemplate.templateType);
      } else if (hasDeactivatedModules) {
        const currentModule = deactivatedModules.length === 1 ? deactivatedModules[0] : selectedComponent;
        getSiteDataPackbyModule(currentModule);
      }
      setHistoryPush("/sites/settings/content-types", false);
    }
  };

  const modifiedNotificationText =
    "You made some changes to the Live version that aren't implemented on this Draft. Check it out before you continue working.";

  const backLinkRoute = "/sites/pages";

  const handleGoBack = () => {
    props.setHistoryPush(backLinkRoute, false);
  };

  const mainAction = { title: "Preview Page", onClick: toggleModal };
  const secondaryAction = { title: "Ok, go back", onClick: handleGoBack };

  const mainUnpublishAction = { title: "Ok", onClick: toggleUnpublishModal };

  const mainDeleteModalAction = {
    title: "Delete page",
    onClick: removePage,
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const mainScheduleModalAction = {
    title: "Schedule",
    onClick: handleSchedulePublication,
  };

  const secondaryScheduleModalAction = { title: "Cancel", onClick: toggleScheduleModal };

  const mainCancelScheduleModalAction = {
    title: "Cancel Schedule",
    onClick: handleCancelSchedulePublication,
  };

  const secondaryCancelScheduleModalAction = { title: "Back", onClick: toggleCancelScheduleModal };

  const tabIcons = isAllowedToEditContentPage
    ? [
        { name: "edit", text: "Edit mode" },
        { name: "view", text: "Preview mode" },
      ]
    : [{ name: "view", text: "Preview mode" }];

  const tabsPreview = {
    icons: tabIcons,
    selectedTab,
    action: (tab: string) => setSelectedTab(tab),
  };

  const contentLanguages: ILanguage[] = [];
  pageLanguages.forEach((pageLang) => {
    const contentLang = siteLanguages.find((lang) => lang.id === pageLang.languageId);
    if (contentLang) {
      contentLanguages.push(contentLang);
    }
  });

  const getSelectedPageLanguage = (language: ILanguage) =>
    pageLanguages.find((pageLang) => pageLang.languageId === language.id);

  const handleLanguage = async (language: ILanguage) => {
    const { locale, id } = language;
    setLanguage({ locale, id });

    const selectedPageLanguage = getSelectedPageLanguage(language);

    await getDefaults();

    if (selectedPageLanguage) {
      createNewTranslation(false);
      setCurrentPageID(selectedPageLanguage.pageId);
      await getPage(selectedPageLanguage.pageId);
      resetDirty();
    } else {
      isAllowedToEditContentPage && setSelectedTab("edit");
      createNewTranslation(true);
      await getPage(pageID);
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <RouteLeavingGuard when={isDirty} action={goToPages} text={modalText} />
      <MainWrapper
        title={pageName}
        subtitle={structuredData}
        backLink={backLinkRoute}
        rightButton={rightButtonProps}
        downArrowMenu={downArrowMenu}
        fixedAppBar={true}
        pageStatus={props.pageStatus}
        pageStatusActions={pageStatusActions}
        language={lang}
        languageAction={handleLanguage}
        availableLanguages={availableLanguages}
        currentLanguages={contentLanguages}
        currentPageID={pageID}
        fullWidth={true}
        errors={errors}
        errorActions={{ goToError, goToPackage: handleClickNotification }}
        isFromEditor={true}
        tabs={tabsPreview}
        isDirty={isDirty}
        scheduledPublication={editorContent?.publicationScheduled}
      >
        {selectedTab === "edit" ? (
          <>
            {(!isTemplateActivated || hasDeactivatedModules) && !isGlobal && (
              <S.NotificationWrapper>
                <Notification
                  type="error"
                  text={packNotificationText}
                  btnText="Activate package"
                  onClick={handleClickNotification}
                />
              </S.NotificationWrapper>
            )}
            {isLivePageChanged && (
              <S.NotificationWrapper>
                <Notification type="warning" text={modifiedNotificationText} />
              </S.NotificationWrapper>
            )}
            {notification && (
              <S.NotificationWrapper>
                <Notification
                  type={notification.type}
                  text={notification.text}
                  btnText={notification.btnText}
                  onClick={notification.onClick}
                  resetError={() => setNotification(null)}
                />
              </S.NotificationWrapper>
            )}
            <ErrorToast size="l" fixed />
            <S.Content>
              <Editor
                isTemplateActivated={isTemplateActivated}
                isGlobal={isGlobal}
                isEditable={isEditable}
                pageTitle={pageName}
                isReadOnly={isReadOnly}
                browserRef={browserRef}
                setNotification={setNotification}
                isEditLive={isEditLive}
              />
            </S.Content>
          </>
        ) : (
          <Preview />
        )}
        <Modal
          isOpen={isOpen}
          hide={toggleModal}
          size="S"
          title="This page is currently being edited"
          mainAction={mainAction}
          secondaryAction={secondaryAction}
        >
          {isOpen && (
            <S.ModalContent>
              <p>
                <strong>{userEditing && userEditing.name}</strong> is currently working on this page. You can preview
                the page but <strong>you cannot make changes to it</strong> until {userEditing && userEditing.name}{" "}
                leaves the page.
              </p>
            </S.ModalContent>
          )}
        </Modal>
        <Modal
          isOpen={isUnpublishOpen}
          hide={toggleUnpublishModal}
          size="S"
          title="Unpublish Modified Page"
          mainAction={mainUnpublishAction}
        >
          {isUnpublishOpen && (
            <S.ModalContent>
              <p>
                There are some saved changes that are not published on this page. To Unpublish this page,{" "}
                <strong>you must publish these changes or discard them.</strong>
              </p>
            </S.ModalContent>
          )}
        </Modal>
        <DeleteModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainDeleteModalAction}
          secondaryModalAction={secondaryDeleteModalAction}
          {...{ isTranslated, deleteAllVersions, setDeleteAllVersions }}
        />
        <ScheduleModal
          isOpen={isScheduleOpen}
          toggleModal={toggleScheduleModal}
          mainModalAction={mainScheduleModalAction}
          secondaryModalAction={secondaryScheduleModalAction}
          scheduleDate={scheduleDate}
          setScheduleDate={setScheduleDate}
        />
        <CancelScheduleModal
          isOpen={isCancelScheduleOpen}
          toggleModal={toggleCancelScheduleModal}
          mainModalAction={mainCancelScheduleModalAction}
          secondaryModalAction={secondaryCancelScheduleModalAction}
        />
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state: IRootState): IPageEditorStateProps => ({
  isSaving: state.app.isSaving,
  pageID: state.pageEditor.currentPageID as number,
  pageStatus: state.pageEditor.currentPageStatus as string,
  pageName: state.pageEditor.currentPageName,
  selectedComponent: state.pageEditor.selectedContent.component,
  isLoading: state.app.isLoading,
  lang: state.app.lang,
  siteLanguages: state.sites.currentSiteLanguages,
  pageLanguages: state.pageEditor.currentPageLanguages,
  editorContent: state.pageEditor.editorContent,
  schema: state.pageEditor.schema,
  activatedTemplates: state.dataPacks.templates,
  activatedModules: state.dataPacks.modules,
  errors: state.pageEditor.errors,
  userEditing: state.pageEditor.userEditing,
  currentUserID: state.users.currentUser && state.users.currentUser.id,
  isNewTranslation: state.pageEditor.isNewTranslation,
  currentSiteErrorPages: state.sites.currentSiteErrorPages,
  skipReviewOnPublish: state.app.globalSettings.skipReviewOnPublish,
});

interface IPageEditorStateProps {
  pageID: number;
  pageStatus: string;
  pageName: string;
  selectedComponent: any;
  isSaving: boolean;
  isLoading: boolean;
  lang: { locale: string; id: number | null };
  siteLanguages: ILanguage[];
  pageLanguages: IPageLanguage[];
  editorContent: any;
  activatedTemplates: any[];
  activatedModules: string[];
  errors: IErrorItem[];
  userEditing: IUserEditing | null;
  currentUserID: number | null;
  isNewTranslation: boolean;
  currentSiteErrorPages: number[];
  skipReviewOnPublish?: boolean;
  schema: any;
}

const mapDispatchToProps = {
  getPage: pageEditorActions.getPage,
  savePage: pageEditorActions.savePage,
  deletePage: pageEditorActions.deletePage,
  validatePage: pageEditorActions.validatePage,
  updatePageStatus: pageEditorActions.updatePageStatus,
  setHistoryPush: appActions.setHistoryPush,
  setLanguage: appActions.setLanguage,
  createNewTranslation: pageEditorActions.createNewTranslation,
  setTab: pageEditorActions.setTab,
  getSiteDataPackbyTemplate: dataPacksActions.getSiteDataPackbyTemplate,
  getSiteDataPackbyModule: dataPacksActions.getSiteDataPackbyModule,
  getDefaults: navigationActions.getDefaults,
  setSelectedContent: pageEditorActions.setSelectedContent,
  sendPagePing: pageEditorActions.sendPagePing,
  discardDraft: pageEditorActions.discardDraft,
  deleteBulk: pageEditorActions.deleteBulk,
  schedulePublication: pageEditorActions.schedulePublication,
  setCurrentPageID: pageEditorActions.setCurrentPageID,
};

interface IPageEditorDispatchProps {
  getPage(pageID?: number): Promise<void>;
  savePage(createDraft: boolean, publishPage?: any, publishDraft?: boolean): Promise<boolean>;
  deletePage(params?: ISavePageParams): Promise<boolean>;
  validatePage(publish?: boolean): Promise<boolean>;
  updatePageStatus(id: number[], status: string): Promise<boolean>;
  setHistoryPush(path: string, isEditor: boolean): void;
  setLanguage(lang: { locale: string; id: number | null }): void;
  createNewTranslation(isNewTranslation: boolean): void;
  setTab(tab: string): void;
  getSiteDataPackbyTemplate(templateType: string): void;
  getSiteDataPackbyModule(module: string): void;
  getDefaults(): Promise<void>;
  setSelectedContent(editorID: number): Promise<void>;
  sendPagePing(pageID: number): Promise<boolean>;
  discardDraft(): Promise<void>;
  deleteBulk(ids: number[]): Promise<boolean>;
  schedulePublication(date: string | null, isDraft: boolean): Promise<boolean>;
  setCurrentPageID: (currentPageID: number | null) => void;
}

type IProps = IPageEditorStateProps & IPageEditorDispatchProps & RouteComponentProps;

const PageEditorWithErrorBoundary = withErrorBoundary(PageEditor, { fallback: <ErrorPage type="wrongEditor" /> });

export default connect(mapStateToProps, mapDispatchToProps)(PageEditorWithErrorBoundary);
