import React, { useState } from "react";
import { connect } from "react-redux";

import { formsActions } from "@ax/containers/Forms";
import { FormCategory } from "@ax/types";
import { Button, FloatingPanel } from "@ax/components";
import Form from "./Form";

import * as S from "./style";

const CategoryPanel = (props: IProps): JSX.Element => {
  const { isOpen, item, type, toggleModal, toggleToast, createFormCategory, updateFormCategory } = props;

  const initState: IFormState = {
    name: item?.content.title || "",
    code: item?.content.code || "",
  };

  const [form, setForm] = useState(initState);

  const addItemAction = async () => {
    const newCategory = {
      title: form.name,
      code: form.code,
    };

    const isCreated = await createFormCategory(newCategory, type);

    if (isCreated) {
      toggleModal();
    }
  };

  const editItemAction = async () => {
    let isUpdated = false;
    let toastString = "";

    const updatedCategory = {
      title: form.name,
      code: form.code,
    };

    isUpdated = !!item && (await updateFormCategory(item.id, updatedCategory));
    toastString = "1 form category edited";

    if (isUpdated) {
      toggleToast(toastString);
    }

    toggleModal();
  };

  const addButton = {
    label: "Add",
    action: addItemAction,
    disabled: false,
  };

  const editButton = {
    label: "Update",
    action: editItemAction,
    disabled: false,
  };

  const title = !item ? "New Category" : "Edit Category";

  return (
    <FloatingPanel title={title} toggleModal={toggleModal} isOpen={isOpen}>
      <S.Wrapper>
        <Form edit={!!item} form={form} setForm={setForm} />
        <S.Footer>
          {!item ? (
            <Button className="button" type="button" onClick={addButton.action} disabled={addButton.disabled}>
              {addButton.label}
            </Button>
          ) : (
            <Button className="button" type="button" onClick={editButton.action} disabled={editButton.disabled}>
              {editButton.label}
            </Button>
          )}
        </S.Footer>
      </S.Wrapper>
    </FloatingPanel>
  );
};

interface ICategoryPanelProps {
  item?: FormCategory;
  type: string;
  isOpen: boolean;
  toggleModal(): void;
  toggleToast(state: string): void;
}

export interface IFormState {
  name: string;
  code: string;
}

const mapDispatchToProps = {
  createFormCategory: formsActions.createFormCategory,
  updateFormCategory: formsActions.updateFormCategory,
};

interface IDispatchProps {
  createFormCategory: (content: { title: string; code: string }, categoryType: string) => Promise<boolean>;
  updateFormCategory: (id: number, content: { title: string; code: string }) => Promise<boolean>;
}

type IProps = IDispatchProps & ICategoryPanelProps;

export default connect(null, mapDispatchToProps)(CategoryPanel);
