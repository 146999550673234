import React from "react";

import { Flag, Icon } from "@ax/components";
import { ILanguage } from "@ax/types";

import * as S from "./style";

const LanguageMenu = (props: ILanguageMenuProps): JSX.Element => {
  const { availableLanguages, language, setLanguage, isInAppBar, currentLanguages } = props;

  const getCurrentLanguage = (lang: string) =>
    currentLanguages && currentLanguages.find((currLanguage) => currLanguage.locale === lang);

  const isNewVersion = (lang: string) => currentLanguages && !getCurrentLanguage(lang);
  const isEditableVersion = (lang: string) => currentLanguages && getCurrentLanguage(lang);

  const getLanguageIcon = (lang: string) => {
    const isCurrentLanguage = lang === language;
    const isAvailableToEdit = !isCurrentLanguage && isEditableVersion(lang);
    let iconName;

    if (isCurrentLanguage) {
      iconName = "done";
    } else if (isNewVersion(lang)) {
      iconName = "add";
    } else if (isAvailableToEdit && !isInAppBar) {
      iconName = "edit";
    } else {
      return null;
    }

    return (
      <S.LanguageIcon data-testid="language-icon">
        <Icon name={iconName} />
      </S.LanguageIcon>
    );
  };

  const languageMenuItem = (item: ILanguage) => {
    const handleClick = () => setLanguage(item);
    return (
      <S.LanguageItem
        key={item.locale}
        selected={item.locale === language}
        onClick={handleClick}
        data-testid="language-menu-item"
      >
        <S.LanguageItemWrapper>
          <S.LanguageLabel>
            <Flag name={item.locale} />
            <S.LanguageText data-testid="language-text">
              <S.Locale>{item.label}</S.Locale> - {item.language}
            </S.LanguageText>
          </S.LanguageLabel>
          {isNewVersion(item.locale) && language !== item.locale && (
            <S.LanguageSubtext> Add new version </S.LanguageSubtext>
          )}
        </S.LanguageItemWrapper>
        {getLanguageIcon(item.locale)}
      </S.LanguageItem>
    );
  };

  return (
    <S.ActionMenu data-testid="language-menu">
      {availableLanguages && availableLanguages.map((item) => languageMenuItem(item))}
    </S.ActionMenu>
  );
};

export interface ILanguageMenuProps {
  language: string | null;
  availableLanguages: ILanguage[];
  currentLanguages: ILanguage[];
  isInAppBar?: boolean;
  setLanguage(lang: ILanguage): void;
}

export default LanguageMenu;
