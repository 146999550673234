import { FormContent } from "@ax/types";

const getAllLangFormsIds = (forms: FormContent[], selectedItems: number[], getAllVersions: boolean): number[] => {
  let langsFormsIds: number[] = [];
  if (getAllVersions) {
    const selectedForms = forms.filter((form) => selectedItems.includes(form.id));
    langsFormsIds = selectedForms.reduce((ids: number[], form) => {
      const langsFormIds = form.dataLanguages.map((lang) => lang.id);
      return [...ids, ...langsFormIds];
    }, []);
  }

  return langsFormsIds.length ? langsFormsIds : selectedItems;
};

const getSortedListStatus = (orderPointer: string, isAscending: boolean) => {
  const sortedListStatus = {
    isAscending,
    sortedByTitle: orderPointer === "title",
    sortedByDate: orderPointer === "modified",
  };

  return sortedListStatus;
};

export { getAllLangFormsIds, getSortedListStatus };
