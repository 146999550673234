import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { withErrorBoundary } from "react-error-boundary";

import { FormContent, FormLanguage, FormState, ILanguage, INotification, IRootState, ISite } from "@ax/types";
import { MainWrapper, Loading, ErrorToast, Notification, ErrorPage } from "@ax/components";
import { appActions } from "@ax/containers/App";
import { formsActions } from "@ax/containers/Forms";
import { RouteLeavingGuard } from "@ax/guards";
import { useIsDirty, useModal } from "@ax/hooks";
import { getDefaultTheme } from "@ax/helpers";

import Editor from "./Editor";
import { DeleteModal, UnPublishModal } from "../atoms";

import * as S from "./style";

const FormEditor = (props: IProps) => {
  const {
    formID,
    isLoading,
    isSaving,
    lang,
    globalLangs,
    formContent,
    isNewTranslation,
    currentSiteInfo,
    setHistoryPush,
    setLanguage,
    deleteForm,
    getForm,
    createNewTranslation,
    setCurrentFormID,
  } = props;

  const { id, title, state, dataLanguages } = formContent || { dataLanguages: [] };
  const formStatus = state === "active" ? "active" : "offline";
  const isTranslated = dataLanguages.length > 1;
  const isSiteView = !!currentSiteInfo;

  const formLanguages: ILanguage[] = [];
  dataLanguages?.forEach((dataLang) => {
    const formLang = globalLangs.find((lang) => lang.id === dataLang.language);
    if (formLang) {
      formLanguages.push(formLang);
    }
  });

  const [notification, setNotification] = useState<INotification | null>(null);
  const [deleteAllVersions, setDeleteAllVersions] = useState(false);
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isUnpublishOpen, toggleModal: toggleUnpublishModal } = useModal();
  const { isDirty, setIsDirty, resetDirty } = useIsDirty(formContent);
  const browserRef = useRef<HTMLDivElement>(null);

  const backLinkRoute = isSiteView ? "/sites/forms" : "/forms";
  const theme = getDefaultTheme();

  useEffect(() => {
    const { setTab, resetForm } = props;

    const handleGetForm = async () => await getForm(formID);

    const defaultTab = "content";
    setTab(defaultTab);
    handleGetForm();

    if (!formID) {
      setIsDirty(true);
    }

    return () => {
      resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToForms = (path: string) => setHistoryPush(path, true);

  const handleSavePage = async (updateState?: FormState) => {
    const { saveForm } = props;

    const isSaved = await saveForm(updateState);
    if (isSaved) {
      resetDirty();
    }
  };

  const removeItem = async () => {
    if (!id) return;
    const allFormVersions = dataLanguages.map((lang: FormLanguage) => lang.id);
    const isDeleted = deleteAllVersions ? await deleteForm(allFormVersions) : await deleteForm(id);
    if (isDeleted) {
      setHistoryPush(backLinkRoute, false);
    }
    toggleDeleteModal();
  };

  const handleDiscarChanges = async () => {
    const { getForm } = props;
    resetDirty();
    await getForm(formID);
  };

  const publishForm = () => handleSavePage("active");

  const unpublishForm = () => {
    handleSavePage("inactive");
    isUnpublishOpen && toggleUnpublishModal();
  };

  const getSelectedFormLanguage = (language: ILanguage) =>
    dataLanguages?.find((formLang) => formLang.language === language.id);

  const handleLanguage = async (language: ILanguage) => {
    const { locale, id } = language;
    setLanguage({ locale, id });

    const selectedFormLanguage = getSelectedFormLanguage(language);

    if (selectedFormLanguage) {
      createNewTranslation(false);
      setCurrentFormID(selectedFormLanguage.id);
      await getForm(selectedFormLanguage.id);
      resetDirty();
    } else {
      createNewTranslation(true);
      await getForm(formID);
    }
  };

  const menuOptions = [];

  if (isDirty) {
    menuOptions.push({
      label: "Discard changes",
      icon: "close",
      action: handleDiscarChanges,
    });
  }

  menuOptions.push({
    label: "Delete form",
    icon: "delete",
    action: toggleDeleteModal,
  });

  const publishButton =
    state && state === "active"
      ? {
          label: "Unpublish form",
          action: toggleUnpublishModal,
        }
      : {
          label: "Publish form",
          action: publishForm,
        };

  const downArrowMenu = {
    button: publishButton,
    options: menuOptions,
    displayed: true,
  };

  const modalText = (
    <>
      Some content <strong>is not saved</strong> on this page.
    </>
  );

  const rightButtonProps = {
    label: isSaving ? "Saving" : "Save",
    disabled: (!isDirty && formID !== null && !isNewTranslation) || isSaving,
    action: () => handleSavePage(),
  };

  const mainDeleteModalAction = {
    title: "Delete form",
    onClick: removeItem,
  };
  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const mainUnpublishModalAction = {
    title: "Unpublish form",
    onClick: unpublishForm,
  };

  const secondaryUnpublishModalAction = { title: "Cancel", onClick: toggleUnpublishModal };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <RouteLeavingGuard when={isDirty} action={goToForms} text={modalText} />
      <MainWrapper
        title={title || "New Form"}
        backLink={backLinkRoute}
        rightButton={rightButtonProps}
        downArrowMenu={downArrowMenu}
        fixedAppBar={true}
        pageStatus={formStatus}
        language={lang}
        languageAction={handleLanguage}
        availableLanguages={globalLangs}
        currentLanguages={formLanguages}
        currentPageID={0}
        fullWidth={true}
        inversed={!isSiteView}
        isFromEditor={true}
        isDirty={isDirty}
      >
        {notification && (
          <S.NotificationWrapper>
            <Notification
              type={notification.type}
              text={notification.text}
              btnText={notification.btnText}
              onClick={notification.onClick}
              resetError={() => setNotification(null)}
            />
          </S.NotificationWrapper>
        )}
        <ErrorToast size="l" fixed />
        <S.Content>
          <Editor isGlobal={true} theme={theme} browserRef={browserRef} setNotification={setNotification} />
        </S.Content>
      </MainWrapper>
      <DeleteModal
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        mainModalAction={mainDeleteModalAction}
        secondaryModalAction={secondaryDeleteModalAction}
        {...{ title, isTranslated, deleteAllVersions, setDeleteAllVersions }}
      />
      <UnPublishModal
        isOpen={isUnpublishOpen}
        toggleModal={toggleUnpublishModal}
        mainModalAction={mainUnpublishModalAction}
        secondaryModalAction={secondaryUnpublishModalAction}
        title={title}
      />
    </>
  );
};

const mapStateToProps = (state: IRootState): IFormEditorStateProps => ({
  isSaving: state.app.isSaving,
  isLoading: state.app.isLoading,
  lang: state.app.lang,
  globalLangs: state.app.globalLangs,
  formID: state.forms.currentFormID,
  formContent: state.forms.formContent,
  currentSiteInfo: state.sites.currentSiteInfo,
  isNewTranslation: state.forms.isNewTranslation,
});

interface IFormEditorStateProps {
  isSaving: boolean;
  isLoading: boolean;
  lang: { locale: string; id: number | null };
  globalLangs: ILanguage[];
  formID: number | null;
  formContent: FormContent | null;
  currentSiteInfo: ISite | null;
  isNewTranslation: boolean;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  setLanguage: appActions.setLanguage,
  getForm: formsActions.getForm,
  setTab: formsActions.setSelectedTab,
  saveForm: formsActions.saveForm,
  resetForm: formsActions.resetForm,
  deleteForm: formsActions.deleteForm,
  createNewTranslation: formsActions.createNewTranslation,
  setCurrentFormID: formsActions.setCurrentFormID,
};

interface IFormEditorDispatchProps {
  setHistoryPush: (path: string, isEditor: boolean) => void;
  setLanguage(lang: { locale: string; id: number | null }): void;
  getForm(formID: number | null): Promise<void>;
  setTab(tab: string): void;
  saveForm(updateState?: FormState): Promise<boolean>;
  resetForm(): void;
  deleteForm: (formID: number[] | number) => Promise<boolean>;
  createNewTranslation(isNewTranslation: boolean): void;
  setCurrentFormID(formID: number | null): void;
}

type IProps = IFormEditorStateProps & IFormEditorDispatchProps & RouteComponentProps;

const FormEditorWithErrorBoundary = withErrorBoundary(FormEditor, { fallback: <ErrorPage type="wrongEditor" /> });

export default connect(mapStateToProps, mapDispatchToProps)(FormEditorWithErrorBoundary);
