import React, { memo, useCallback, useEffect, useState } from "react";

import { IBreadcrumbItem, ISchema } from "@ax/types";
import { Breadcrumb, Toast } from "@ax/components";
import { useToast } from "@ax/hooks";

import * as S from "./style";

const Header = (props: IHeaderProps) => {
  const {
    breadcrumb,
    schema,
    selectedParent,
    actions,
    headerRef,
    setSelectedContent,
    setHeaderHeight,
    hasStickyHeader,
  } = props;

  const { duplicateModuleAction, deleteModuleAction, copyModuleAction } = actions;
  const title = breadcrumb[breadcrumb.length - 1].displayName;
  const editorID = breadcrumb[breadcrumb.length - 1].editorID;
  const parentID = breadcrumb.length > 1 ? breadcrumb[breadcrumb.length - 2].editorID : breadcrumb[0].editorID;
  const isFormComponent = schema.schemaType === "formComponent";
  const isInArray = Array.isArray(selectedParent);

  const { isVisible, toggleToast, setIsVisible } = useToast();
  const [y, setY] = useState(window.scrollY);
  const [scrollDown, setScrollDown] = useState(false);
  const [breadcrumbOld, setBreadcrumbOld] = useState(breadcrumb);

  const handleNavigation = useCallback(
    (e: Event) => {
      const window = e.currentTarget as Window;
      const height = headerRef.current ? headerRef.current.offsetHeight + headerRef.current.offsetTop : 0;
      if (isInArray && y > window.scrollY) {
        setHeaderHeight(height);
        setScrollDown(false);
      } else if (y > height && y < window.scrollY) {
        setHeaderHeight(0);
        setScrollDown(true);
      }
      setY(window.scrollY);
    },
    [y, headerRef, setHeaderHeight, isInArray]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.offsetHeight + headerRef.current.offsetTop;
      setHeaderHeight(height);
      setBreadcrumbOld(breadcrumb);
      if (JSON.stringify(breadcrumbOld) !== JSON.stringify(breadcrumb)) {
        window.scrollTo(0, 0);
        setBreadcrumbOld(breadcrumb);
      }
    }
  }, [headerRef, breadcrumb, setHeaderHeight]);

  const removeItem = () => {
    setSelectedContent(parentID);
    deleteModuleAction([editorID]);
  };

  const duplicateItem = () => {
    duplicateModuleAction([editorID]);
    setSelectedContent(parentID);
  };

  const copyItem = () => {
    const isCopied = copyModuleAction([editorID]);
    isCopied && toggleToast();
  };

  const duplicateOpt = {
    label: "duplicate",
    icon: "duplicate",
    action: duplicateItem,
  };

  const deleteOpt = {
    label: "delete",
    icon: "delete",
    action: removeItem,
  };

  const copyOpt = {
    label: "copy",
    icon: "copy",
    action: copyItem,
  };

  let menuOptions = [duplicateOpt, deleteOpt];
  menuOptions = isFormComponent ? [...menuOptions, copyOpt] : menuOptions;

  return (
    <S.Wrapper scrollDown={scrollDown || !hasStickyHeader}>
      <S.HeaderWrapper data-testid="header-config-wrapper" ref={headerRef}>
        <S.Title>{title}</S.Title>
        <Breadcrumb breadcrumb={breadcrumb} setSelectedContent={setSelectedContent} />
        {isInArray && <S.StyledActionMenu icon="more" options={menuOptions} tooltip="Actions" />}
      </S.HeaderWrapper>
      {isVisible && <Toast message="1 module copied to clipboard" setIsVisible={setIsVisible} />}
    </S.Wrapper>
  );
};

export interface IHeaderProps {
  schema: ISchema | Record<string, never>;
  actions: {
    deleteModuleAction: (editorID: number[], key?: string) => void;
    duplicateModuleAction: (editorID: number[], key?: string) => number;
    copyModuleAction: (editorID: number[]) => boolean;
  };
  breadcrumb: IBreadcrumbItem[];
  selectedParent: Record<string, unknown> | null;
  setSelectedContent(editorID: number): void;
  headerRef: React.RefObject<HTMLDivElement>;
  setHeaderHeight(height: number): void;
  hasStickyHeader: boolean;
}

export default memo(Header);
