import React from "react";

import { ISchema, ISchemaTab, ISchemaField } from "@ax/types";
import { Tabs } from "@ax/components";

import ConnectedField from "./ConnectedField";

import * as S from "./style";

export const Form = (props: IFormProps): JSX.Element => {
  const { schema, selectedTab, actions, isGlobal, theme, headerHeight, setSelectedTab, setHistoryPush } = props;

  const tabContent = schema.configTabs.find((tab: ISchemaTab) => tab.title.toLowerCase() === selectedTab);
  const setTab = (tab: string) => setSelectedTab(tab);

  const generateFields = (field: ISchemaField) => {
    const { setSelectedContent } = props;
    const { key, whiteList } = field;

    const goTo = (editorID: number) => setSelectedContent(editorID);

    return (
      <ConnectedField
        selectedTab={selectedTab}
        whiteList={whiteList}
        key={key}
        objKey={key}
        field={field}
        goTo={goTo}
        actions={actions}
        componentType={schema.schemaType}
        isGlobal={isGlobal}
        theme={theme}
        setHistoryPush={setHistoryPush}
      />
    );
  };

  const getTabs = () => {
    const isPageSchema = schema.schemaType === "page";
    const mappedTabs = schema.configTabs.reduce((acc: string[], curr: ISchemaTab) => {
      const currTitle = curr.title.toLowerCase();
      if (!isPageSchema || (isPageSchema && (currTitle === "content" || currTitle === "config"))) {
        return [...acc, currTitle];
      }
      return acc;
    }, []);

    return mappedTabs;
  };

  const tabs = getTabs();

  return (
    <S.Wrapper data-testid="form-section">
      <S.TabsWrapper headerHeight={headerHeight}>
        <Tabs tabs={tabs} active={selectedTab} setSelectedTab={setTab} />
      </S.TabsWrapper>
      {tabContent && tabContent.fields.map((field: ISchemaField) => generateFields(field))}
    </S.Wrapper>
  );
};

export interface IFormProps {
  selectedTab: string;
  schema: ISchema | Record<string, never>;
  actions: any;
  setSelectedTab(tab: string): void;
  setSelectedContent(editorID: number): void;
  isGlobal?: boolean;
  theme: string;
  setHistoryPush?: (path: string, isEditor: boolean) => void;
  headerHeight: number;
}

export default Form;
