import React from "react";
import TableHeader from "./TableHeader";

import * as S from "./style";

const BulkHeader = (props: IProps): JSX.Element => {
  const { showBulk, checkState, selectItems, totalItems, isScrolling, bulkActions, setHoverCheck } = props;

  return showBulk ? (
    <S.StyledBulkSelectionOptions
      checkState={checkState}
      actions={bulkActions}
      selectItems={selectItems}
      totalItems={totalItems}
    />
  ) : (
    <TableHeader
      totalItems={totalItems}
      selectAllItems={selectItems}
      isScrolling={isScrolling}
      setHoverCheck={setHoverCheck}
      checkState={checkState}
    />
  );
};

interface IProps {
  showBulk: boolean;
  checkState: Record<string, boolean>;
  selectItems: () => void;
  totalItems: number;
  isScrolling: boolean;
  bulkActions: { icon: string; text: string; action: () => void }[];
  setHoverCheck: (state: boolean) => void;
}

export default BulkHeader;
