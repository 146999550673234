import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withErrorBoundary } from "react-error-boundary";

import { IRootState, ISite, INotification, ILanguage, INavigationLanguage } from "@ax/types";
import { useIsDirty, useModal, usePermission } from "@ax/hooks";
import { RouteLeavingGuard } from "@ax/guards";

import { appActions } from "@ax/containers/App";
import { navigationActions } from "@ax/containers/Navigation";
import { ErrorPage, ErrorToast, Loading, MainWrapper, Modal, Notification } from "@ax/components";
import Editor from "./Editor";

import * as S from "./style";

const DefaultsEditor = (props: IProps) => {
  const {
    isSaving,
    isLoading,
    lang,
    siteLanguages,
    setLanguage,
    getValues,
    createNavigation,
    editorContent,
    updateNavigation,
    currentDefaultsContent,
    navLanguages,
    isNewTranslation,
    createTranslation,
    selectedDefault,
    setHeader,
    setFooter,
    setHistoryPush,
    currentSiteInfo,
  } = props;

  const [notification, setNotification] = useState<INotification | null>(null);
  const { isOpen, toggleModal } = useModal();
  const { isDirty, setIsDirty, resetDirty } = useIsDirty(editorContent, isNewTranslation);
  const currentDefaultNav = currentDefaultsContent.find((item: any) => item.setAsDefault);
  const isNew = !editorContent?.id;

  const currentLanguages: ILanguage[] = [];
  navLanguages?.forEach((navLang) => {
    const currentLang = siteLanguages.find((lang) => lang.id === navLang.languageId);
    if (currentLang) {
      currentLanguages.push(currentLang);
    }
  });

  const isSetAsDefault = editorContent && editorContent.setAsDefault;
  const browserRef = useRef<HTMLDivElement>(null);

  const isAllowedToCreateHeaders = usePermission("navigation.createSiteHeaders") && selectedDefault === "Headers";
  const isAllowedToCreateFooters = usePermission("navigation.createSiteFooters") && selectedDefault === "Footers";

  useEffect(() => {
    getValues();
    if (isNew) {
      setIsDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetDirty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    const navigationModuleComponent = currentSiteInfo && currentSiteInfo.navigationModules?.[editorContent?.type];
    const currentNavigation = currentDefaultsContent?.find((item: any) => item.id === editorContent?.id);
    if (navigationModuleComponent && editorContent && currentNavigation) {
      const isNavigationModuleChanged = navigationModuleComponent !== currentNavigation.component;
      isNavigationModuleChanged && setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSiteInfo, editorContent?.id]);

  const save = async () => {
    const isSaved =
      isNew || isNewTranslation
        ? await createNavigation(browserRef?.current)
        : await updateNavigation(editorContent.id, editorContent, true, browserRef?.current);
    if (isSaved) resetDirty();
  };

  const saveAsDefault = () => {
    save();
    toggleModal();
  };

  const saveButtonAction = () => {
    const isCurrentDefault = currentDefaultNav && currentDefaultNav.id === editorContent.id;
    const isNewDefault = isNew || !isCurrentDefault;
    isSetAsDefault && isNewDefault && !isNewTranslation ? toggleModal() : save();
  };

  const rightButtonProps = {
    label: !isDirty && !isNewTranslation && !isNew ? "Saved" : isSaving ? "Saving" : "Save",
    disabled: (!isDirty && !isNewTranslation && !isNew) || isSaving,
    action: () => saveButtonAction(),
  };

  const getNavigation = (id: number): Promise<void> => {
    return new Promise(() => {
      const isHeader = selectedDefault === "Headers";
      isHeader ? setHeader(id) : setFooter(id);
      getValues();
    });
  };

  const getSelectedNavLanguage = (language: ILanguage) =>
    navLanguages.find((navLang) => navLang.languageId === language.id);

  const handleLanguage = async (language: ILanguage) => {
    const { locale, id } = language;
    setLanguage({ locale, id });

    const selectedNavLanguage = getSelectedNavLanguage(language);

    if (selectedNavLanguage) {
      createTranslation(false);
      await getNavigation(selectedNavLanguage.navigationId);
      resetDirty();
    } else {
      setIsDirty(true);
      createTranslation(true);
      editorContent && (await getNavigation(editorContent.id));
    }
  };

  const leavingGuardText = (
    <>
      Some content <strong>is not saved</strong> on this page.
    </>
  );
  const setDefaultModalText = editorContent && (
    <p>{`There is already a ${editorContent.type} defined as default. If you change it to this one, it will be shown on all the pages on this site. Are you sure your want to make this change?`}</p>
  );

  const mainModalAction = { title: "Yes, set as default", onClick: saveAsDefault };
  const secondaryModalAction = { title: "Cancel", onClick: toggleModal };
  const goBack = (path: string) => setHistoryPush(path, true);

  const availableLanguages = isAllowedToCreateHeaders || isAllowedToCreateFooters ? siteLanguages : currentLanguages;

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <RouteLeavingGuard when={isDirty} action={goBack} text={leavingGuardText} />
      <MainWrapper
        title={editorContent.title}
        backLink={true}
        rightButton={rightButtonProps}
        fixedAppBar={true}
        language={lang}
        languageAction={handleLanguage}
        availableLanguages={availableLanguages}
        currentLanguages={currentLanguages}
        fullWidth={true}
      >
        {notification && (
          <S.NotificationWrapper>
            <Notification
              type={notification.type}
              text={notification.text}
              btnText={notification.btnText}
              onClick={notification.onClick}
              resetError={() => setNotification(null)}
            />
          </S.NotificationWrapper>
        )}
        <ErrorToast size="l" />
        <Modal
          isOpen={isOpen}
          hide={toggleModal}
          title={`Default ${editorContent.component}`}
          secondaryAction={secondaryModalAction}
          mainAction={mainModalAction}
          size="S"
        >
          {isOpen ? <S.ModalContent>{setDefaultModalText}</S.ModalContent> : null}
        </Modal>
        <S.Content>
          <Editor browserRef={browserRef} setNotification={setNotification} />
        </S.Content>
      </MainWrapper>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  isSaving: state.app.isSaving,
  isLoading: state.app.isLoading,
  lang: state.app.lang,
  siteLanguages: state.sites.currentSiteLanguages,
  selectedDefault: state.navigation.selectedDefault,
  editorContent: state.navigation.editorContent,
  header: state.navigation.header,
  footer: state.navigation.footer,
  navLanguages: state.navigation.currentNavigationLanguages,
  currentDefaultsContent: state.navigation.currentDefaultsContent,
  isNewTranslation: state.navigation.isNewTranslation,
  currentSiteInfo: state.sites.currentSiteInfo,
});

interface IStateProps {
  isSaving: boolean;
  isLoading: boolean;
  lang: { locale: string; id: number | null };
  siteLanguages: ILanguage[];
  currentDefaultsContent: any;
  selectedDefault: any;
  editorContent: any;
  header: number | null;
  footer: number | null;
  navLanguages: INavigationLanguage[];
  isNewTranslation: boolean;
  currentSiteInfo: ISite | null;
}

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  setLanguage: appActions.setLanguage,
  getValues: navigationActions.getValues,
  setSchema: navigationActions.setSchema,
  createNavigation: navigationActions.createNavigation,
  updateNavigation: navigationActions.updateNavigation,
  createTranslation: navigationActions.createNewTranslation,
  setHeader: navigationActions.setHeader,
  setFooter: navigationActions.setFooter,
};

interface IDispatchProps {
  setHistoryPush(path: string, isEditor?: boolean): void;
  setLanguage(lang: { locale: string; id: number | null }): void;
  getValues(): Promise<void>;
  createNavigation(navHtml?: HTMLDivElement | null): Promise<boolean>;
  updateNavigation(navID: number, data: any, fromEditor?: boolean, navHtml?: HTMLDivElement | null): Promise<boolean>;
  createTranslation(isNewTranslation: boolean): void;
  setHeader(id: number | null): void;
  setFooter(id: number | null): void;
}

type IProps = IStateProps & IDispatchProps;

const DefaultsEditorWithErrorBoundary = withErrorBoundary(DefaultsEditor, {
  fallback: <ErrorPage type="wrongEditor" />,
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultsEditorWithErrorBoundary);
