import { deepClone, getDefaultSchema } from "@ax/helpers";

const addElement = (element: any) => {
  const defaultSchema = getDefaultSchema(element.component);
  for (const key in defaultSchema) {
    element[key] = defaultSchema[key];
  }
};

const updateCollection = (elementType: string, prevCollection: any[]) => {
  const newModule = getDefaultSchema(elementType);
  return [...prevCollection, newModule];
};

const getUpdatedComponents = (editorContent: any, element: any, key: string) => {
  const { editorID, type } = element;
  const isCollectionItem = typeof type === "string";
  const updatedDefault = isCollectionItem ? deepClone(editorContent) : editorContent;

  const mapValues = (item: any) => {
    if (item.editorID !== undefined && item.editorID === editorID) {
      if (isCollectionItem) {
        item[key] = updateCollection(type, item[key]);
      } else {
        addElement(type);
      }
    }

    const itemKeys = Object.keys(item);
    itemKeys.forEach((key: string) => {
      const containedValue = item[key];
      if (containedValue && typeof containedValue === "object") {
        mapValues(containedValue);
      }
    });
  };

  mapValues(updatedDefault);
  return updatedDefault;
};

export { getUpdatedComponents };
