import styled from "styled-components";

const Wrapper = styled.section``;

const FieldWrapper = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  margin-top: ${(p) => p.theme.spacing.s};
  margin-bottom: ${(p) => p.theme.spacing.s};
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

const Link = styled.span`
  cursor: pointer;
  font-weight: bold;
  color: ${(p) => p.theme.color?.interactive01};
`;

const TabsWrapper = styled.div<{ headerHeight: number }>`
  background-color: ${(p) => p.theme.color.uiBackground01};
  position: sticky;
  top: ${(p) => (p.headerHeight ? `calc(63px + ${p.headerHeight}px)` : "63px")};
  z-index: 2;
`;

export { Wrapper, FieldWrapper, Link, TabsWrapper };
