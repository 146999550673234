import React, { useLayoutEffect } from "react";

import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { IGetRoles, ILanguage, IRootState, ISite } from "@ax/types";
import { appActions } from "@ax/containers/App";
import { sitesActions } from "@ax/containers/Sites";
import { dataPacksActions } from "@ax/containers/Settings/DataPacks";
import { structuredDataActions } from "@ax/containers/StructuredData";
import { usersActions } from "@ax/containers/Users";

import SitesList from "./SitesList";

const Sites = (props: ISitesProps): JSX.Element => {
  const {
    token,
    setCurrentSiteInfo,
    getStructuredData,
    setLanguage,
    getAllDataPacks,
    getUser,
    globalLangs,
    getRoles,
    updateCurrentSearch,
    resetCurrentData,
    setIsLoading,
  } = props;

  useLayoutEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      setCurrentSiteInfo(null);
      updateCurrentSearch("");
      resetCurrentData();
      await getRoles({ siteId: "global" }, token, false);
      await getUser("me", token, false);
      await getStructuredData(token, null, false);
      await getAllDataPacks();

      const defaultLanguage = globalLangs.find((language) => language.isDefault);
      if (defaultLanguage) {
        const { locale, id } = defaultLanguage;
        const lang = {
          locale,
          id,
        };

        setLanguage(lang);
      }
      setIsLoading(false);
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <SitesList />;
};

const mapStateToProps = (state: IRootState) => ({
  token: state.app.token,
  globalLangs: state.app.globalLangs,
});

interface IStateProps {
  token: string;
  globalLangs: ILanguage[];
}

interface IDispatchProps {
  setCurrentSiteInfo(currentSiteInfo: ISite | null): void;
  getStructuredData(token: string, siteId?: number | null, hasLoading?: boolean): Promise<void>;
  setLanguage(lang: { locale: string; id: number | null }): void;
  getAllDataPacks: () => Promise<void>;
  getUser: (id: string, token?: string, hasLoading?: boolean) => Promise<void>;
  getRoles: (params: IGetRoles, token?: string, hasLoading?: boolean) => Promise<void>;
  updateCurrentSearch(query: string): Promise<void>;
  resetCurrentData(): Promise<void>;
  setIsLoading(isLoading: boolean): void;
}

export type ISitesProps = IStateProps & IDispatchProps & RouteComponentProps;

const mapDispatchToProps = {
  setCurrentSiteInfo: sitesActions.setCurrentSiteInfo,
  getStructuredData: structuredDataActions.getStructuredData,
  setLanguage: appActions.setLanguage,
  getAllDataPacks: dataPacksActions.getAllDataPacks,
  getUser: usersActions.getUser,
  getRoles: usersActions.getRoles,
  updateCurrentSearch: structuredDataActions.updateCurrentSearch,
  resetCurrentData: structuredDataActions.resetCurrentData,
  setIsLoading: appActions.setIsLoading,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sites));
