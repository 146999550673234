import React, { useEffect, useRef, useState } from "react";

import { isEmptyObj } from "@ax/helpers";
import { Loading } from "@ax/components";
import { IBreadcrumbItem, ISchema } from "@ax/types";

import Form from "./Form";
import Header from "./Header";

import * as S from "./style";

const FormConfigPanel = (props: IStateProps): JSX.Element => {
  const {
    isLoading,
    schema,
    actions,
    setSelectedContent,
    setSelectedTab,
    selectedTab,
    isGlobal,
    setHistoryPush,
    theme,
    lastElementAddedId,
    breadcrumb,
    selectedParent,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    if (lastElementAddedId && wrapperRef.current) {
      const element = document.querySelector(`.editorId-${lastElementAddedId}`);
      element && element.scrollIntoView();
    }
  }, [lastElementAddedId]);

  if (isLoading || isEmptyObj(schema)) {
    return <Loading />;
  }

  return (
    <S.Wrapper data-testid="config-panel-wrapper" ref={wrapperRef}>
      <Header
        schema={schema}
        actions={actions}
        breadcrumb={breadcrumb}
        selectedParent={selectedParent}
        setSelectedContent={setSelectedContent}
        headerRef={headerRef}
        setHeaderHeight={setHeaderHeight}
        hasStickyHeader={true}
      />
      <Form
        actions={actions}
        schema={schema}
        setSelectedContent={setSelectedContent}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
        isGlobal={isGlobal}
        theme={theme}
        setHistoryPush={setHistoryPush}
        headerHeight={headerHeight}
      />
    </S.Wrapper>
  );
};

export interface IStateProps {
  isLoading: boolean;
  schema: ISchema | Record<string, never>;
  actions: any;
  setSelectedContent(editorID: number): void;
  selectedTab: string;
  setSelectedTab(tab: string): void;
  isGlobal?: boolean;
  pageTitle?: string;
  setHistoryPush?: (path: string, isEditor: boolean) => void;
  theme: string;
  lastElementAddedId?: null | number;
  breadcrumb: IBreadcrumbItem[];
  selectedParent: Record<string, unknown> | null;
}

export default FormConfigPanel;
