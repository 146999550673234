import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { Icon, MenuItem } from "@ax/components";

import * as S from "./style";

const MenuGroup = (props: IProps): JSX.Element => {
  const { navItem, current, onClick } = props;

  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleClick = (item: { label: string; value: string }) => onClick(item);

  const icon = isOpen ? "UpArrow" : "DownArrow";

  return (
    <S.Item>
      <S.NavLink onClick={toggleOpen}>
        <S.Title>{navItem.label}</S.Title>
        <S.Arrow>
          <Icon name={icon} />
        </S.Arrow>
      </S.NavLink>

      <S.Dropdown isOpen={isOpen}>
        {navItem.items &&
          navItem.items.map((item) => {
            const { label, value } = item;

            const isSelected = label === current;
            const selectedClass = isSelected ? "selected" : "";

            return (
              <MenuItem key={value} onClick={() => handleClick(item)} className={selectedClass}>
                <NavLink to="#">
                  <S.Link>
                    <S.Text active={isSelected}>{label}</S.Text>
                  </S.Link>
                </NavLink>
              </MenuItem>
            );
          })}
      </S.Dropdown>
    </S.Item>
  );
};

interface IProps {
  navItem: { label: string; path: string; items?: { label: string; value: string }[] };
  current: string | undefined;
  onClick: (item: { label: string; value: string }) => void;
}

export default MenuGroup;
