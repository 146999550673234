import styled from "styled-components";

const ModalContent = styled.div`
  padding: ${(p) => `0 ${p.theme.spacing.m} ${p.theme.spacing.m} ${p.theme.spacing.m}`};
`;

const FiltersBar = styled.div`
  padding: ${(p) => `${p.theme.spacing.s} 0 ${p.theme.spacing.m}`};
`;

const TemplateGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${(p) => p.theme.spacing.s};
  height: auto;
  align-items: flex-start;
  padding-bottom: ${(p) => p.theme.spacing.l};
`;

const TemplateItem = styled.div``;

const Title = styled.div`
  ${(p) => p.theme.textStyle.uiL};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.xs};
`;

const ThumbnailWrapper = styled.div<{ isSelected: boolean }>`
  position: relative;
  background-color: ${(p) => p.theme.color.uiBackground02};
  border: ${(p) => (p.isSelected ? `3px solid ${p.theme.color.interactive01}` : "none")};
  border-radius: ${(p) => p.theme.radii.s};
  padding: ${(p) => p.theme.spacing.xs};
  :before {
    content: "";
    border-radius: ${(p) => p.theme.radii.s};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  :hover {
    cursor: pointer;
    :before {
      background-color: ${(p) => p.theme.color.overlayHoverPrimary};
    }
  }
`;

const Thumbnail = styled.img``;

export { ModalContent, FiltersBar, TemplateGrid, TemplateItem, Title, ThumbnailWrapper, Thumbnail };
