import type { QueriedData } from "../types/api-response-fields";
import type { ListContentType } from "../types/core";
import type { ListApiQueryResponseProps } from "../types/global";
import type { PartiallyRequired } from "../types/utilities";

import {
	useList,
	type UseListReturn,
	type UseListSetQueryProps,
} from "./useList";

export interface useListWithDefaultStaticPageProps<T>
	extends Omit<PartiallyRequired<UseListSetQueryProps<T>, "items">, "data"> {
	queriedData?: QueriedData<T>;
}

function filterKeys(obj?: Record<string, unknown>, fields?: Array<string>) {
	if (!obj) {
		return;
	}

	if (!fields) {
		return obj;
	}

	return Object.fromEntries(
		Object.keys(obj)
			.map((key) => fields?.includes(key) && [key, obj[key]])
			.filter(Boolean) as Array<[string, unknown]>,
	);
}

/**
 * `useListWithDefaultStaticPage()`
 *
 * Hook to fetch a list of ContentTypes from the public API that returns the first page statically.
 * This way the build process will write the data into the HTML.
 *
 * @warning
 * Maybe you **don't want** to `use { mode: "list" }` in the schema options
 *
 * @example
 *	const [{ query, isError, isFirstFetch, isLoading, msg }, setQuery] =
 *	useListWithDefaultStaticPage<MEMBERContentTypeProps>({
 *  // This is de default query params for the first page
 *		queriedData,
 *		items: itemsPerPage,
 *		page: 1,
 *		fields: ["title", "id"],
 * });
 *
 */
function useListWithDefaultStaticPage<T>(
	props: useListWithDefaultStaticPageProps<T>,
): UseListReturn<T> {
	const { items, queriedData, fields, page } = props;
	const [{ isError, isLoading, msg, query, isFirstFetch }, setQuery] =
		useList<T>();

	// Only at build-time. SSR.
	const staticQuery = {
		page: page || 1,
		totalItems: queriedData?.length,
		totalPages:
			queriedData?.length && items && Math.ceil(queriedData?.length / items),
		items: queriedData?.slice(0, items || 10).map((item) => {
			return {
				id: item.id,
				...filterKeys(item.content, fields as Array<string>),
			};
		}),
	} as ListApiQueryResponseProps<ListContentType<T>> | undefined;

	// Only in client.
	// Esta const debe ser `undefined` si aun no tenemos respuesta de la API,
	// esto va a pasar siempre, al menos en la fase de build SSR.
	const apiFetchQuery = query
		? {
				...query,
				totalPages:
					items && query?.totalItems && Math.ceil(query?.totalItems / items),
			}
		: undefined;

	return [
		{
			isError,
			isLoading,
			msg,
			isFirstFetch,
			// En build time `query` valdrá `staticQuery`
			query: apiFetchQuery || staticQuery,
		},
		setQuery,
	];
}

export { useListWithDefaultStaticPage };
