import React, { Dispatch, SetStateAction } from "react";

import { IModal } from "@ax/types";
import { Modal, FieldsBehavior, AsyncSelect } from "@ax/components";

import * as S from "./style";

const DeleteModal = (props: IDeleteModal): JSX.Element => {
  const {
    isOpen,
    toggleModal,
    mainModalAction,
    secondaryModalAction,
    isTranslated,
    deleteAllVersions,
    setDeleteAllVersions,
    title,
  } = props;

  const options = [
    {
      title: "Delete only this version",
      name: "deletePage",
      value: false,
    },
    {
      title: "Delete all languages versions",
      name: "deleteAll",
      value: true,
    },
  ];

  const formTitle = title ? <strong>{title}</strong> : "this form";

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete Form"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
      height={isTranslated ? 285 : 240}
    >
      {isTranslated ? (
        <>
          <S.ModalContent>
            <p>
              Are you sure you want to delete {formTitle}? If you delete it, this form <strong>cannot be added</strong>
              to the pages. Also, this form has some translations associated.
            </p>
            <FieldsBehavior
              name="removeAllVersions"
              fieldType="RadioGroup"
              value={deleteAllVersions}
              options={options}
              onChange={setDeleteAllVersions}
            />
          </S.ModalContent>
        </>
      ) : (
        <S.ModalContent>
          Are you sure you want to delete {formTitle}?
          <br />
          If you delete it, this form <strong>cannot be added</strong> to the pages.
          <br />
          <strong>This action cannot be undone</strong>.
        </S.ModalContent>
      )}
    </Modal>
  );
};

const UnPublishModal = (props: IUnpublishModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, title } = props;

  const formTitle = title ? <strong>{title}</strong> : "this form";

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Unpublish Form"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
      height={240}
    >
      <S.ModalContent>
        Are you sure you want to unpublish {formTitle}?
        <br />
        If you do it, this form <strong>cannot be added</strong> to the pages.
        <br />
        <strong>This action cannot be undone</strong>.
      </S.ModalContent>
    </Modal>
  );
};

const DuplicateModal = (props: IDuplicateModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, title, handleChange } = props;

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Duplicate form"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
      height={284}
    >
      <S.ModalContent>
        <p>Please enter a new name for the duplicated form.</p>
        <FieldsBehavior
          fieldType="TextField"
          name="title"
          title="Form name"
          mandatory={true}
          value={title}
          onChange={handleChange}
          placeholder="Type a name for this form"
        />
      </S.ModalContent>
    </Modal>
  );
};

const CopyModal = (props: ICopyModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction, setSite, site } = props;

  const handleSelectChange = (value: string | number | null) => {
    if (typeof value === "number") setSite(value);
  };

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      size="S"
      title="Copy form in another site"
      mainAction={mainModalAction}
      secondaryAction={secondaryModalAction}
      overflow="visible"
      height={278}
    >
      <S.ModalContent>
        <p>
          <strong>Select a site to copy this form. </strong>
          <br />
          You can only select sites with the same language as this form.
        </p>
        <S.SelectWrapper>
          <AsyncSelect name="select" entity="sites" onChange={handleSelectChange} value={site} mandatory={true} />
        </S.SelectWrapper>
      </S.ModalContent>
    </Modal>
  );
};

interface IDeleteModal extends IModal {
  isTranslated: boolean;
  deleteAllVersions: boolean;
  setDeleteAllVersions: Dispatch<SetStateAction<boolean>>;
  title?: string;
}

interface IUnpublishModal extends IModal {
  title?: string;
}

interface IDuplicateModal extends IModal {
  title: string;
  handleChange(title: string): void;
}

interface ICopyModal extends IModal {
  setSite: Dispatch<SetStateAction<number | null>>;
  site: number | null;
}

export { DeleteModal, UnPublishModal, DuplicateModal, CopyModal };
