import * as React from "react";
const SvgForm = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#5057FF"
      fillRule="evenodd"
      d="M19 19V5H5v14h14ZM5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2Zm12 4H7v2h10V7ZM7 11h10v2H7v-2Zm4 4H7v2h4v-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgForm;
