import React from "react";
import { connect } from "react-redux";

import { useModal, usePermission } from "@ax/hooks";
import { ILanguage, ISiteLanguage, IRootState, ISite } from "@ax/types";
import { languagesActions } from "@ax/containers/Settings";
import { Flag } from "@ax/components";
import LanguagePanel from "../../LanguagePanel";

import * as S from "./style";

const Item = (props: IItemProps): JSX.Element => {
  const { item, resetForm, setForm, deleteSiteLanguage, currentSiteInfo } = props;

  if (!currentSiteInfo) {
    throw new Error(`ERROR: User reached Site Languages with null site info`);
  }

  const { isOpen, toggleModal } = useModal();
  const isAllowedToEdit = usePermission("general.editLanguage");
  const isAllowedToDelete = usePermission("general.deleteLanguages");

  const siteLanguage: ISiteLanguage = {
    language: item.id,
    path: item.path || "",
    domain: item.domain || null,
    isDefault: item.isDefault,
  };

  const removeItemAction = () => deleteSiteLanguage(currentSiteInfo.id, item.id);

  const menuOptions = isAllowedToDelete
    ? [
        {
          label: "Delete",
          icon: "delete",
          action: removeItemAction,
        },
      ]
    : [];

  const resetValues = () => resetForm();

  const setItem = (item: ISiteLanguage) => setForm(item);

  const openModal = () => {
    resetValues();
    setItem(siteLanguage);
    toggleModal();
  };

  return (
    <>
      <S.Component onClick={openModal} disabled={!isAllowedToEdit}>
        <S.FlagWrapper>
          <Flag name={item.locale} />
        </S.FlagWrapper>
        {item.language}
        <S.Default>{item.isDefault ? "Default" : ""}</S.Default>
        <S.StyledActionMenu icon="more" options={menuOptions} />
      </S.Component>
      <LanguagePanel isOpen={isOpen} toggleModal={toggleModal} item={item} />
    </>
  );
};

interface IProps {
  item: ILanguage;
  currentSiteInfo: ISite | null;
}

const mapStateToProps = (state: IRootState) => ({
  currentSiteInfo: state.sites.currentSiteInfo,
});

const mapDispatchToProps = {
  resetForm: languagesActions.resetForm,
  setForm: languagesActions.setForm,
  deleteSiteLanguage: languagesActions.deleteSiteLanguage,
};

interface IDispatchProps {
  setForm(item: ISiteLanguage): void;
  resetForm(): void;
  deleteSiteLanguage(siteId: number, languageId: number): void;
}

type IItemProps = IProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Item);
