import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const Heading = styled.div`
  ${(p) => p.theme.textStyle.headingXS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
`;

const Description = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-bottom: ${(p) => p.theme.spacing.s};

  a {
    color: ${(p) => p.theme.color.interactive01};
    font-weight: 600;
  }
`;

const WarningHeading = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
`;

const WarningText = styled.div`
  ${(p) => p.theme.textStyle.uiM};
  color: ${(p) => p.theme.color.textHighEmphasis};
  width: calc(${(p) => p.theme.spacing.l} * 12);
  margin-bottom: ${(p) => p.theme.spacing.m};
  a {
    color: ${(p) => p.theme.color.interactive01};
    font-weight: 600;
  }
`;

const ContentWrapper = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
  padding: ${(p) => p.theme.spacing.m};
`;

const AnalyticsWrapper = styled.div`
  width: 720px;
`;

const ScriptCodeWrapper = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const ComponentsWrapper = styled.div<{ itemsSize: number }>`
  display: grid;
  grid-template-columns: repeat(2, calc(100% * ${(p) => p.itemsSize}));
  grid-gap: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.xs};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
  margin-bottom: ${(p) => p.theme.spacing.m};
  padding-bottom: ${(p) => p.theme.spacing.m};
`;

const Component = styled.div`
  width: 100%;
  height: 100%;
  background: ${(p) => p.theme.color.uiBackground02};
  border: 1px solid ${(p) => p.theme.color.uiLine};
  border-radius: ${(p) => p.theme.radii.s};
  padding: ${(p) => p.theme.spacing.s};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ComponentName = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.color.textLowEmphasis};
  text-transform: uppercase;
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  word-break: break-all;
`;

const ComponentValues = styled.div`
  ${(p) => p.theme.textStyle.fieldContent};
  color: ${(p) => p.theme.color.textHighEmphasis};
  padding-top: ${(p) => p.theme.spacing.xxs};
  padding-bottom: ${(p) => p.theme.spacing.xxs};
`;

const ContentType = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
  margin-top: ${(p) => p.theme.spacing.xxs};

  span {
    color: ${(p) => p.theme.color.interactive01};
  }
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

export {
  Wrapper,
  Heading,
  Description,
  WarningHeading,
  WarningText,
  ContentWrapper,
  ScriptCodeWrapper,
  ComponentsWrapper,
  Component,
  ComponentName,
  ComponentValues,
  AnalyticsWrapper,
  ContentType,
  ModalContent,
};
