import React, { memo } from "react";

import { ISite } from "@ax/types";

import * as S from "./style";

const MultiCheckSelectGroup = (props: IMultiCheckSelectGroupProps) => {
  const { value, onChange, site, elements, note, disabled, error, handleValidation, contentType = "data" } = props;

  const completedValue = value ? value : {};

  return (
    <>
      {note && <S.StyledNoteField value={{ text: note }} />}
      {elements &&
        elements.map((element: any) => {
          const { placeholder, source, key, mandatory, isGlobal } = element;

          const handleChange = (val: any[]) => {
            let newValue = { ...completedValue };
            if (val.length > 0) {
              newValue = { ...newValue, [key]: val };
            } else {
              delete newValue[key];
            }

            onChange(newValue);
            error && handleValidation && handleValidation(newValue);
          };

          const val = completedValue[key] ? completedValue[key] : [];
          const fromSite = isGlobal ? null : site;

          return (
            <S.StyledMultiCheckSelect
              key={key}
              mandatory={mandatory}
              value={val}
              onChange={handleChange}
              site={fromSite}
              placeholder={placeholder}
              source={source}
              disabled={disabled}
              contentType={contentType}
            />
          );
        })}
    </>
  );
};

interface IElement {
  key: string;
  placeholder: string;
  source: string;
  mandatory?: boolean;
}

export interface IMultiCheckSelectGroupProps {
  value: Record<string, unknown[]> | Record<string, never> | undefined | null;
  elements: IElement[];
  onChange: (value: Record<string, unknown[]> | Record<string, never>) => void;
  site?: ISite;
  note?: string;
  error?: boolean;
  handleValidation?: (value: Record<string, unknown>, validators?: Record<string, unknown>) => void;
  disabled?: boolean;
  contentType: "data" | "formCategory";
}

export default memo(MultiCheckSelectGroup);
