import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "styled-components";

import { ICheck, IAvailableSites, ISavePageParams, ILanguage, IPageLanguage, IPage, IColumn } from "@ax/types";
import { getHumanLastModifiedDate, getScheduleFormatDate, getStructuredDataTitle, trimText } from "@ax/helpers";
import { appActions } from "@ax/containers/App";
import { pageStatus, ISetCurrentPageIDAction } from "@ax/containers/PageEditor/interfaces";
import {
  CheckField,
  FloatingMenu,
  Icon,
  Flag,
  LanguageMenu,
  Tooltip,
  ElementsTooltip,
  CategoryCell,
} from "@ax/components";
import { pageEditorActions } from "@ax/containers/PageEditor";
import { useAdaptiveText, useModal, usePermission } from "@ax/hooks";
import { DeleteModal, DuplicateModal, UnpublishModal } from "./atoms";
import { getCurrentLanguages } from "./utils";

import * as S from "./style";

const GlobalPageItem = (props: IGlobalPageItemProps): JSX.Element => {
  const {
    handleClick,
    lang,
    languages,
    setLanguage,
    isSelected,
    onChange,
    isEditable,
    isTranslatable,
    globalPage,
    isAllPages,
    updatePageStatus,
    duplicatePage,
    getPage,
    validatePage,
    setHistoryPush,
    deletePage,
    getGlobalPages,
    createNewTranslation,
    setCurrentPageID,
    toggleToast,
    setDeletedItem,
    deleteBulk,
    categoryColumns,
    columns,
    categoryColors,
    addCategoryColors,
    skipReview,
    hoverCheck,
  } = props;

  const { locale } = lang;
  const {
    pageLanguages,
    metaDescription,
    metaTitle,
    isIndexed,
    availableSites,
    structuredData,
    structuredDataContent,
    fullPath,
    publicationScheduled,
    liveStatus,
    haveDraftPage,
  } = globalPage;

  const activeColumns = columns.filter((col) => col.show).map((col) => col.id);
  const isScheduledPub =
    !!publicationScheduled && (liveStatus.status === pageStatus.SCHEDULED || liveStatus.status === pageStatus.MODIFIED);

  const initValue = { title: "", slug: "" };
  const [duplicateModalState, setDuplicateModalState] = useState(initValue);
  const { isOpen: isDuplicateOpen, toggleModal: toggleDuplicateModal } = useModal();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isUnpublishOpen, toggleModal: toggleUnpublishModal } = useModal();
  const [deleteAllVersions, setDeleteAllVersions] = useState(false);

  const nameCellRef = useRef<HTMLDivElement>(null);
  const theme: any = useTheme();
  const nameCellPadding = Number(theme.spacing.s.slice(0, -2));
  const title = useAdaptiveText(nameCellRef, globalPage.title, nameCellPadding);
  const path = useAdaptiveText(nameCellRef, fullPath.page, nameCellPadding);
  const API_URL = process.env.GRIDDO_API_URL || process.env.REACT_APP_API_ENDPOINT;

  const isAllowedToDuplicatePages = usePermission("global.globalData.duplicateGlobalData");
  const isAllowedToPublishPages = usePermission("global.globalData.publishUnpublishAllGlobalData");
  const isAllowedToCreatePages = usePermission("global.globalData.createAllGlobalData");
  const isAllowedToDeletePage = usePermission("global.globalData.deleteAllGlobalData");
  const isAllowedToEditContentPage = usePermission("global.globalData.editAllGlobalData");

  const publishedTooltip: Record<string, string> = {
    active: "Live",
    "upload-pending": "Publication pending",
    offline: "Offline",
    "offline-pending": "Offline pending",
    modified: "Live & Modified",
    scheduled: `Scheduled publication: ${isScheduledPub ? getScheduleFormatDate(publicationScheduled) : ""}`,
  };

  const _handleClick = () => {
    handleClick(true, globalPage);
  };

  const handleEditLivePage = () => {
    handleClick(true, globalPage, true);
  };

  const handleOnChange = (value: ICheck) => onChange(value);

  const checkSeoDone = (value: string | boolean) => {
    if (typeof value === "string") {
      value = value.trim();
    }
    if (value) {
      return (
        <S.StyledDone>
          <Icon name="done" />
        </S.StyledDone>
      );
    }
    return (
      <S.StyledClose>
        <Icon name="close" />
      </S.StyledClose>
    );
  };

  const SeoItems = {
    Index: () => <S.StyledSeo>{checkSeoDone(isIndexed)}Indexed</S.StyledSeo>,
    Title: () => <S.StyledSeo>{checkSeoDone(metaTitle)}Title</S.StyledSeo>,
    Description: () => <S.StyledSeo>{checkSeoDone(metaDescription)}Description</S.StyledSeo>,
  };

  const SeoTitleMenu = () => {
    if (metaTitle) {
      return (
        <FloatingMenu Button={SeoItems.Title}>
          <S.FloatingSeo>{metaTitle}</S.FloatingSeo>
        </FloatingMenu>
      );
    }
    return SeoItems.Title();
  };

  const SeoDescriptionMenu = () => {
    if (metaDescription) {
      return (
        <FloatingMenu Button={SeoItems.Description}>
          <S.FloatingSeo>{metaDescription}</S.FloatingSeo>
        </FloatingMenu>
      );
    }
    return SeoItems.Description();
  };

  const removeItem = async () => {
    const deletePageParams: ISavePageParams = { site: "global", page: globalPage };
    const allPageVersions = globalPage.pageLanguages.map((lang: IPageLanguage) => lang.pageId);
    const isDeleted = deleteAllVersions ? await deleteBulk(allPageVersions) : await deletePage(deletePageParams);
    const deletedItems = deleteAllVersions ? allPageVersions : globalPage.id;
    if (isDeleted) {
      setDeletedItem(deletedItems);
      toggleToast();
    }
    toggleDeleteModal();
    getGlobalPages();
  };

  const publishPage = async () => {
    await getPage(globalPage.id);
    const isValidated = skipReview ? true : await validatePage(true);

    if (isValidated) {
      await updatePageStatus([globalPage.id], pageStatus.UPLOAD_PENDING, true);
      getGlobalPages();
    }
  };

  const unpublishPage = async () => {
    await updatePageStatus([globalPage.id], pageStatus.OFFLINE_PENDING, true);
    getGlobalPages();
  };

  const cancelPublishPage = async () => {
    await updatePageStatus([globalPage.id], pageStatus.OFFLINE, true);
    getGlobalPages();
  };

  const handleDuplicatePage = async () => {
    await duplicatePage(globalPage.id, duplicateModalState);
    toggleDuplicateModal();
    setHistoryPush("data/pages/editor", true);
  };

  let menuOptions = [];

  if (isAllowedToDuplicatePages) {
    menuOptions.push({
      label: "duplicate",
      icon: "duplicate",
      action: toggleDuplicateModal,
    });
  }

  if (isAllowedToDeletePage) {
    menuOptions.push({
      label: "delete",
      icon: "delete",
      action: toggleDeleteModal,
    });
  }

  const getPublishItem = (status: string) => {
    switch (status) {
      case pageStatus.OFFLINE:
      case pageStatus.OFFLINE_PENDING:
        return {
          label: "publish",
          icon: "upload-pending",
          action: publishPage,
        };
      case pageStatus.PUBLISHED:
        return {
          label: "unpublish",
          icon: "offline",
          action: globalPage.haveDraftPage ? toggleUnpublishModal : unpublishPage,
        };
      case pageStatus.UPLOAD_PENDING:
        return {
          label: "unpublish",
          icon: "offline",
          action: globalPage.haveDraftPage ? toggleUnpublishModal : cancelPublishPage,
        };
      default:
        return null;
    }
  };

  const viewPage = () => window.open(globalPage.fullUrl || `${API_URL}/page/go/${globalPage.id}`, "_blank");

  const viewOption = {
    label: "View online",
    icon: "View",
    action: viewPage,
  };

  const editOptions = [
    {
      label: "View live",
      icon: "active",
      action: handleEditLivePage,
      color: true,
    },
  ];

  if (isAllowedToEditContentPage) {
    editOptions.unshift({
      label: "Edit draft",
      icon: "modified",
      action: _handleClick,
      color: true,
    });
  }

  const publishAction = getPublishItem(globalPage.liveStatus.status);
  menuOptions = publishAction && isAllowedToPublishPages ? [publishAction, ...menuOptions] : menuOptions;

  menuOptions = globalPage.haveDraftPage ? [...editOptions, ...menuOptions] : menuOptions;

  menuOptions = globalPage.liveStatus.status === pageStatus.PUBLISHED ? [viewOption, ...menuOptions] : menuOptions;

  const mainDuplicateModalAction = {
    title: "Duplicate",
    onClick: handleDuplicatePage,
    disabled: !duplicateModalState.title.trim() || !duplicateModalState.slug.trim(),
  };
  const secondaryDuplicateModalAction = { title: "Cancel", onClick: toggleDuplicateModal };

  const isTranslated = globalPage.pageLanguages.length > 1;

  const mainDeleteModalAction = {
    title: "Delete page",
    onClick: removeItem,
  };
  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const currentLanguages = getCurrentLanguages(languages, pageLanguages);

  const changeLanguage = (language: { locale: string; id: number | null }) => {
    return setLanguage(language);
  };

  const handleNewTranslation = (isNewTranslation: boolean) => {
    setCurrentPageID(globalPage.id);
    createNewTranslation(isNewTranslation);
  };

  const languageActions = {
    setLanguage: changeLanguage,
    createNewTranslation: handleNewTranslation,
    getGlobalPages,
  };

  const getSelectedPageLanguage = (language: ILanguage) =>
    pageLanguages.find((lang: IPageLanguage) => lang.languageId === language.id);

  const handleLanguage = (language: ILanguage) => () => {
    if (!languageActions || !languageActions.setLanguage) return;

    const { locale, id } = language;
    const lang = { locale, id };

    languageActions.setLanguage(lang);

    const selectedPageLanguage = getSelectedPageLanguage(language);

    selectedPageLanguage
      ? setCurrentPageID(selectedPageLanguage.pageId)
      : languageActions.createNewTranslation && languageActions.createNewTranslation(true);

    setHistoryPush("data/pages/editor", true);
  };

  const languageMenu = () => (
    <LanguageMenu
      language={locale}
      availableLanguages={isAllowedToCreatePages ? languages : currentLanguages}
      setLanguage={handleLanguage}
      currentLanguages={currentLanguages}
    />
  );

  const FlagsButton = () => (
    <S.FlagsWrapper>
      {currentLanguages.slice(0, 2).map((language, i: number) => (
        <Flag key={`${language.language}${i}`} name={language.locale} size="15" />
      ))}
      <span>({currentLanguages.length})</span>
    </S.FlagsWrapper>
  );

  const translations = isTranslatable ? (
    <FloatingMenu Button={FlagsButton}>{languageMenu()}</FloatingMenu>
  ) : (
    "Not translatable"
  );

  const availableSiteNames = availableSites && availableSites.map((site: IAvailableSites) => site.name);

  const getLiveStatus = () => (isScheduledPub ? "scheduled" : haveDraftPage ? "modified" : liveStatus.status);

  const mainUnpublishAction = { title: "Ok", onClick: toggleUnpublishModal };

  const CategoryColumns = categoryColumns.map((col: any) => {
    const type: any = structuredDataContent && structuredDataContent[col.key];
    const categories: string[] = !type
      ? []
      : Array.isArray(type)
        ? type.map((cat: any) => cat.label || cat.title)
        : [type.label || type.title];

    return (
      activeColumns.includes(col.key) && (
        <CategoryCell
          key={col.key}
          categories={categories}
          categoryColors={categoryColors}
          addCategoryColors={addCategoryColors}
        />
      )
    );
  });

  const structuredDataTitle = structuredData ? getStructuredDataTitle(structuredData) : "";
  const typeTitle =
    structuredDataTitle && structuredDataTitle.length > 20 ? (
      <Tooltip content={structuredDataTitle} left={0} top={1} expanded>
        {trimText(structuredDataTitle, 19)}
      </Tooltip>
    ) : (
      <>{structuredDataTitle}</>
    );

  return (
    <>
      <S.StructuredDataRow role="rowgroup" selected={isSelected} disabled={!isEditable}>
        <S.CheckCell role="cell">
          <CheckField name="check" value={globalPage.id} checked={isSelected || hoverCheck} onChange={handleOnChange} />
        </S.CheckCell>
        <S.NameCell role="cell" onClick={_handleClick} ref={nameCellRef}>
          <Tooltip
            content={[
              <React.Fragment key="page-title">
                <strong>{globalPage.title}</strong>
                <br />
              </React.Fragment>,
              <React.Fragment key="page-slug">{fullPath.page}</React.Fragment>,
            ]}
            left={0}
            top={1}
            expanded
          >
            <S.Title width={title.width}>{title.text}</S.Title>
            <S.Slug width={path.width}>{path.text}</S.Slug>
          </Tooltip>
        </S.NameCell>
        {isAllPages && activeColumns.includes("type") && <S.TypeCell role="cell">{typeTitle}</S.TypeCell>}
        {activeColumns.includes("site") && (
          <S.SiteCell role="cell">
            <ElementsTooltip elements={availableSiteNames} elementsPerRow={3} maxChar={30} />
          </S.SiteCell>
        )}
        {activeColumns.includes("live") && (
          <S.LiveCell role="cell" onClick={_handleClick}>
            <Tooltip content={publishedTooltip[getLiveStatus()]}>
              <Icon name={getLiveStatus()} />
            </Tooltip>
          </S.LiveCell>
        )}
        {CategoryColumns}
        {activeColumns.includes("status") && (
          <S.StatusCell role="cell" onClick={_handleClick}>
            <S.ModDate>{`Mod. ${getHumanLastModifiedDate(globalPage.modified)}`}</S.ModDate>
          </S.StatusCell>
        )}
        {activeColumns.includes("translation") && <S.TransCell role="cell">{translations}</S.TransCell>}
        {activeColumns.includes("seo") && (
          <S.SeoCell role="cell">
            <SeoItems.Index />
            {SeoTitleMenu()}
            {SeoDescriptionMenu()}
          </S.SeoCell>
        )}
        <S.ActionsCell role="cell">
          <S.StyledActionMenu icon="more" options={menuOptions} tooltip="Actions" />
        </S.ActionsCell>
      </S.StructuredDataRow>
      <DuplicateModal
        isOpen={isDuplicateOpen}
        toggleModal={toggleDuplicateModal}
        mainModalAction={mainDuplicateModalAction}
        secondaryModalAction={secondaryDuplicateModalAction}
        {...{ duplicateModalState, setDuplicateModalState }}
      />
      <DeleteModal
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        mainModalAction={mainDeleteModalAction}
        secondaryModalAction={secondaryDeleteModalAction}
        {...{ title: globalPage.title, isTranslated, deleteAllVersions, setDeleteAllVersions }}
      />
      <UnpublishModal
        isOpen={isUnpublishOpen}
        toggleModal={toggleUnpublishModal}
        mainModalAction={mainUnpublishAction}
      />
    </>
  );
};

interface IGlobalPageItemProps {
  languages: ILanguage[];
  lang: { locale: string; id: number | null };
  handleClick: (isFromPage?: boolean, globalPage?: IPage, forceLive?: boolean) => void;
  setLanguage(lang: { locale: string; id: number | null }): void;
  isSelected: boolean;
  onChange: (e: ICheck) => void;
  isEditable?: boolean | null;
  isTranslatable?: boolean;
  isAllPages?: boolean;
  globalPage: IPage;
  updatePageStatus(ids: number[], status: string, updatedFromList: boolean): Promise<boolean>;
  duplicatePage(pageID: number, data: { title: string; slug: string }, siteID?: number): Promise<boolean>;
  getPage(pageID?: number, global?: boolean): Promise<void>;
  validatePage(publish?: boolean, browserRef?: any, currentPage?: IPage): Promise<boolean>;
  setHistoryPush(path: string, isEditor: boolean): void;
  deletePage(params?: ISavePageParams, currentLanguage?: string): Promise<boolean>;
  getGlobalPages(): void;
  createNewTranslation(isNewTranslation: boolean): void;
  setCurrentPageID(currentPageID: number | null): ISetCurrentPageIDAction;
  toggleToast(): void;
  setDeletedItem(item: number | number[] | null): void;
  deleteBulk(ids: number[]): Promise<boolean>;
  categoryColumns: any[];
  columns: IColumn[];
  categoryColors: any;
  addCategoryColors(cats: string[]): void;
  skipReview?: boolean;
  hoverCheck?: boolean;
}

const mapDispatchToProps = {
  setLanguage: appActions.setLanguage,
  updatePageStatus: pageEditorActions.updatePageStatus,
  duplicatePage: pageEditorActions.duplicatePage,
  getPage: pageEditorActions.getPage,
  validatePage: pageEditorActions.validatePage,
  setHistoryPush: appActions.setHistoryPush,
  deletePage: pageEditorActions.deletePage,
  createNewTranslation: pageEditorActions.createNewTranslation,
  setCurrentPageID: pageEditorActions.setCurrentPageID,
  deleteBulk: pageEditorActions.deleteBulk,
};

export default connect(null, mapDispatchToProps)(GlobalPageItem);
