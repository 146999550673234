import React from "react";

import MixableComponentArray, { IMixableComponentArrayProps } from "../ComponentArray/MixableComponentArray";

const FormFieldArray = (props: IMixableComponentArrayProps): JSX.Element => {
  const formFieldArrayProps: IMixableComponentArrayProps = {
    ...props,
    field: { ...props.field, contentType: "fields" },
  };
  return <MixableComponentArray {...formFieldArrayProps} />;
};

export default FormFieldArray;
