import styled from "styled-components";

const FormListWrapper = styled.div`
  display: flex;
  min-height: 100%;
`;

const TableListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: calc(100vh - ${(p) => p.theme.spacing.xl});
  overflow: auto;
`;

const EmptyWrapper = styled.div`
  height: ${(p) => `calc(100vh - (${p.theme.spacing.xl} * 3))`};
  display: flex;
  align-items: center;
`;

const ModalContent = styled.div`
  padding: ${(p) => p.theme.spacing.m};

  p {
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

const SearchTags = styled.div`
  & > div:nth-child(1) {
    margin-bottom: ${(p) => p.theme.spacing.xs};
  }
  & > div:nth-child(2) {
    margin-bottom: ${(p) => p.theme.spacing.xs};
  }
`;

export { FormListWrapper, TableListWrapper, EmptyWrapper, ModalContent, SearchTags };
