import { IRootState } from "@ax/types";
import React from "react";
import { connect } from "react-redux";
import { Loading, Pagination } from "@ax/components";

import * as S from "./style";

const TableList = (props: ITableListProps): JSX.Element => {
  const { tableHeader, children, pagination, isLoading, onScroll, hasFixedHeader, tableRef, className } = props;

  const showPagination = pagination && pagination.totalItems > pagination.itemsPerPage;

  return (
    <S.TableList onScroll={onScroll} ref={tableRef} data-testid="table-list">
      <S.Table>
        <>
          <S.TableHeader hasFixedHeader={hasFixedHeader} data-testid="table-list-header">
            {tableHeader}
          </S.TableHeader>
          {isLoading ? (
            <Loading />
          ) : (
            <S.TableBody data-testid="table-body" className={className}>
              {children}
            </S.TableBody>
          )}
        </>
      </S.Table>
      <S.PaginationWrapper>
        {showPagination && !isLoading && (
          <Pagination
            totalItems={pagination.totalItems}
            setPage={pagination.setPage}
            itemsPerPage={pagination.itemsPerPage}
            currPage={pagination.currPage}
          />
        )}
      </S.PaginationWrapper>
    </S.TableList>
  );
};

const mapStateToProps = (state: IRootState) => ({
  isLoading: state.app.isLoading,
});

export interface ITableListProps {
  tableHeader: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
  pagination?: {
    totalItems: number;
    setPage: any;
    itemsPerPage: number;
    currPage: number;
  };
  isLoading?: boolean;
  onScroll?: any;
  hasFixedHeader?: boolean;
  tableRef?: any;
  className?: string;
}

export default connect(mapStateToProps, null)(TableList);
