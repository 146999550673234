import React from "react";

import { IModal } from "@ax/types";
import { Modal } from "@ax/components";

import * as S from "./style";

const DeleteModal = (props: IModal): JSX.Element => {
  const { isOpen, toggleModal, mainModalAction, secondaryModalAction } = props;

  return (
    <Modal
      isOpen={isOpen}
      hide={toggleModal}
      title="Delete category"
      secondaryAction={secondaryModalAction}
      mainAction={mainModalAction}
      size="S"
      height={240}
    >
      <S.ModalContent>
        <p>
          Are you sure you want to delete <strong>this category</strong>? It won&apos;t be assignable to new froms, and
          existing forms with this category will lose it.
          <br />
          <strong>This action cannot be undone</strong>.
        </p>
      </S.ModalContent>
    </Modal>
  );
};

export { DeleteModal };
