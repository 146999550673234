import React from "react";
import { connect } from "react-redux";

import { getFormTemplate } from "@ax/helpers";
import { IRootState } from "@ax/types";
import { formsActions } from "@ax/containers/Forms";

import TemplateManager from "./TemplateManager";
import Field from "./Field";

const ConnectedField = (props: any) => {
  const {
    whiteList,
    objKey,
    selectedTab,
    field,
    selectedContent,
    goTo,
    updateFormContent,
    selectedEditorID,
    pages,
    actions,
    site,
    lang,
    errors,
    theme,
    moduleCopy,
    template,
    setHistoryPush,
    languages,
    isReadOnly,
  } = props;

  const isTemplate = field.type === "template" || field.type === "formTemplate";
  const isFieldReadOnly = isReadOnly || field.readonly;
  const isDisabled = isFieldReadOnly || field.disabled;
  const error = errors.find((err: any) => err.editorID === selectedEditorID && err.key === objKey);

  const updateValue = (key: string, value: any, templateID?: number) => {
    const editorID = templateID ? templateID : selectedEditorID;
    updateFormContent(editorID, key, value);
  };

  if (isTemplate) {
    const template = getFormTemplate(selectedContent[field.key].templateType);

    return (
      <TemplateManager
        template={template}
        updateValue={updateValue}
        goTo={goTo}
        objKey={objKey}
        pages={pages}
        selectedTab={selectedTab}
        selectedContent={selectedContent}
        actions={actions}
        site={site}
        disabled={isDisabled}
        errors={errors}
        theme={theme}
        moduleCopy={moduleCopy}
        setHistoryPush={setHistoryPush}
        lang={lang.id}
      />
    );
  }

  return (
    <Field
      whiteList={whiteList}
      objKey={objKey}
      field={field}
      selectedContent={selectedContent}
      goTo={goTo}
      updateValue={updateValue}
      pages={pages}
      actions={actions}
      site={site}
      lang={lang.id}
      disabled={isDisabled}
      readonly={isFieldReadOnly}
      error={error}
      errors={errors}
      theme={theme}
      moduleCopy={moduleCopy}
      template={template}
      setHistoryPush={setHistoryPush}
      languages={languages}
    />
  );
};

const mapStateToProps = (state: IRootState) => ({
  selectedEditorID: state.forms.selectedEditorID,
  selectedContent: { ...state.forms.selectedContent },
  pages: state.sites.currentSitePages,
  site: state.sites.currentSiteInfo,
  lang: state.app.lang,
  errors: state.pageEditor.errors,
  moduleCopy: state.forms.moduleCopy,
  template: state.forms.template,
  languages: state.sites.currentSiteLanguages,
});

const mapDispatchToProps = {
  updateFormContent: formsActions.updateFormContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedField);
