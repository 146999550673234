import React, { useState, useEffect } from "react";

import { isEmptyContainer, getFieldByPath } from "@ax/helpers";
import { IconAction, Tooltip } from "@ax/components";
import { getValidity } from "@ax/forms";

import * as S from "./style";

const FieldsBehavior = (props: any): JSX.Element => {
  const {
    value,
    fieldType,
    title,
    name,
    helptext,
    hideable,
    hideField,
    advanced,
    className,
    whiteList,
    mandatory,
    disabled,
    filled,
    validators,
    error,
    deleteError,
    editorID,
    objKey,
    inversed = false,
  } = props;
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [errorField, setErrorField] = useState(false);
  const [message, setMessage] = useState(helptext);

  useEffect(() => {
    setErrorField(false);
    setMessage(helptext);
    if (error) {
      handleValidation(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorID, error]);

  const helpTextWrapper = message ? (
    <S.HelpText error={errorField} inversed={inversed}>
      {message}
    </S.HelpText>
  ) : (
    ""
  );
  const isComponentArray = fieldType === "ComponentArray" || fieldType === "FormFieldArray";
  const isComponentContainer = fieldType === "ComponentContainer";
  const hasMultipleOptions: boolean = whiteList && whiteList.length > 1;

  const hasTitle = title && !isComponentArray;
  const Field = getFieldByPath(fieldType);

  const toggleAdvanced = () => setShowAdvanced(!showAdvanced);

  const containerInfo: any = isComponentContainer && value && isEmptyContainer(value, hasMultipleOptions);

  const isEmpty = containerInfo ? containerInfo.isEmpty : false;
  const showTitle = hasTitle && (!isEmpty || hasMultipleOptions);

  const Asterisk = () => (mandatory ? <S.Asterisk>*</S.Asterisk> : null);

  const wrapperClass = filled ? `${className} filled` : className;

  const handleValidation = (newValue: string | any[], fieldValidators?: Record<string, unknown>) => {
    const validatorWithErrors = error ? { ...validators, ...error.validator } : validators;
    const allValidators = fieldValidators ? { ...validatorWithErrors, ...fieldValidators } : validatorWithErrors;
    const { isValid, errorText } = getValidity(allValidators, newValue);
    if (isValid) {
      setMessage(helptext);
      error && deleteError && deleteError(error);
    } else {
      setMessage(errorText);
    }
    setErrorField(!isValid);
  };

  const resetValidation = () => {
    setMessage(helptext);
    error && deleteError && deleteError(error);
    setErrorField(false);
  };

  return (
    <S.Wrapper className={wrapperClass} showTitle={showTitle} id={`gdd_${objKey}`}>
      <S.Content error={errorField} inversed={inversed} data-testid="fields-behavior-wrapper">
        <Field
          {...props}
          showAdvanced={showAdvanced}
          handleValidation={handleValidation}
          resetValidation={resetValidation}
          error={errorField}
        />
      </S.Content>
      <S.Header className="fieldHeader">
        {showTitle && (
          <S.Label htmlFor={name} error={errorField} inversed={inversed}>
            {title} <Asterisk />
          </S.Label>
        )}
        <S.Icons>
          {hideable && !isEmpty && (
            <Tooltip content="Hide">
              <IconAction icon="delete" size="s" onClick={hideField} disabled={disabled} />
            </Tooltip>
          )}
          {advanced && (
            <Tooltip content="Options">
              <IconAction icon="settings" size="s" onClick={toggleAdvanced} disabled={disabled} />
            </Tooltip>
          )}
        </S.Icons>
      </S.Header>
      {helpTextWrapper}
    </S.Wrapper>
  );
};

export default FieldsBehavior;
