import type { Source } from "./types";
import type { Core, Fields } from "../..";
import type {
	GriddoDamDefaults,
	ImageCropType,
	ImageDecoding,
	ImageFormats,
	ImageLoading,
	ImagePosition,
	ImageTransform,
} from "../../types/core";

import {
	getDamIdFromDamUrl,
	getDomainFromDamUrl,
	getGriddoDamURIWithParams,
	removeUnit,
} from "../../utils/images";

interface GetGriddoArtDirectionImage {
	sources: Array<Source>;
	width?: string;
	height?: string;
	widths?: Array<string>;
	griddoDamDefaults?: GriddoDamDefaults;
	crop?: Core.ImageCropType;
	formats?: Array<"avif" | "webp">;
	quality?: number;
	position?: Core.ImagePosition;
	transforms?: Core.ImageTransform;
	loading?: Core.ImageLoading;
	decoding?: Core.ImageDecoding;
	imageField?: Fields.Image;
	sizes?: string;
}

const MIME_TYPES = {
	jpg: "image/jpeg",
	jpeg: "image/jpeg",
	gif: "image/gif",
	svg: "image/svg+xml",
	png: "image/png",
	avif: "image/avif",
	webp: "image/webp",
};

function getGriddoArtDirectionImageSources(props: GetGriddoArtDirectionImage) {
	const {
		griddoDamDefaults,
		sources,
		widths,
		sizes: rootSizes,
		transforms: rootTransforms,
		quality: rootQuality,
		width: rootWidth,
		height: rootHeight,
		position: rootPosition,
		crop: rootCrop,
		imageField: rootImageField,
	} = props;

	const sourcesRawData = sources.map((source) => {
		// Explicación de root:
		// Se refiere a la prop en el raíz del componente y no en cada source del array sources.
		// <GriddoImageExp width="300px" /> <---- ese width es un rootWidth
		const domain = getDomainFromDamUrl(
			source.image?.url || rootImageField?.url || "",
		);
		const damId = getDamIdFromDamUrl(
			source.image?.url || rootImageField?.url || "",
		);

		// This object contains every aspect of the image configuration
		const imageConfig: ImageConfigExperimental = {
			...griddoDamDefaults,
			...props,
			domain: domain,
			format: "jpeg",
			transforms: source.transforms || rootTransforms || undefined,
			quality: source.quality || rootQuality || griddoDamDefaults?.quality,
			position: source.position || rootPosition,
			crop: source.crop || rootCrop,
		};

		// Default ratio, this will be override 100%.
		let RATIO = 1;
		let imgTagWidth = "";
		let imgTagHeight = "";

		// Si hay custom-width and custom-height calculamos el ratio y este
		// será el ratio final y también usarmos el width/height para el <img>
		if (source.width && source.height) {
			// Ratio para el srcSet
			RATIO = removeCSSUnits(source.width) / removeCSSUnits(source.height);
			// medidas para el <img>
			imgTagWidth = source.width;
			imgTagHeight = source.height;
		}

		// Si no hay width o height y hay imageField o rootImageField...
		if ((!source.width || !source.height) && (source.image || rootImageField)) {
			// Ratio para el srcSet
			// si está el field del source lo priorizamos vs el field del root
			const field = source.image || rootImageField || ({} as Fields.Image);
			const fieldWidth = field.width || 1;
			const fieldHeight = field.height || 1;
			RATIO = fieldWidth / fieldHeight;
			// medidas para el <img>
			// HAY WIDTH - NO HEIGHT
			if (source.width && !source.height) {
				imgTagWidth = source.width;
				const fieldWidth = field.width || 1;
				const fieldHeight = field.height || 1;
				imgTagHeight = `${Math.round(
					fieldHeight / (fieldWidth / removeCSSUnits(source.width)),
				)}px`;
			}
			// NO WIDTH - HAY HEIGHT
			if (!source.width && source.height) {
				const fieldWidth = field.width || 1;
				const fieldHeight = field.height || 1;
				imgTagWidth = `${Math.round(
					fieldWidth / (fieldHeight / removeCSSUnits(source.height)),
				)}px`;
				imgTagHeight = source.height;
			}
		}

		// NO WIDTH - NO HEIGHT
		if (!source.width && !source.height && !rootImageField) {
			const field = source.image || rootImageField || ({} as Fields.Image);
			if (rootWidth && rootHeight) {
				// Comprobamos si hay un width y un height (ambos) en el root y lo usamos si existe
				RATIO = removeCSSUnits(rootWidth) / removeCSSUnits(rootHeight);
				imgTagWidth = rootWidth;
				imgTagHeight = rootHeight;
			} else {
				// Si no tomamos el del field (root o source) que es obligatorio.
				const fieldWidth = field.width || 1;
				const fieldHeight = field.height || 1;
				RATIO = fieldWidth / fieldHeight;
				imgTagWidth = `${field.width}px`;
				imgTagHeight = `${field.height}px`;
			}
		}

		// NO WIDTH - NO HEIGHT - YES IMAGEFIELD
		if (!source.width && !source.height && source.image) {
			const sourceImageWidth = source.image.width || 1;
			const sourceImageHeight = source.image.height || 1;
			RATIO = sourceImageWidth / sourceImageHeight;
			imgTagWidth = `${source.image.width}px`;
			imgTagHeight = `${source.image.height}px`;
		}

		// jpgSource es un Array<string> que calcula el SRCSET
		const jpgSource = widths?.map((widthValue) => {
			return (
				getGriddoDamURIWithParams({
					damId: damId,
					imageConfig: imageConfig,
					width: widthValue,
					height: `${Math.round(removeCSSUnits(widthValue) / RATIO)}px`,
				}) + ` ${removeUnit(widthValue)}w`
			);
		});

		// sourcesRawData
		return {
			type: MIME_TYPES.jpeg,
			srcSet: jpgSource,
			sizes: source.sizes || rootSizes,
			media: source.media,
			width: imgTagWidth,
			height: imgTagHeight,
			src: getGriddoDamURIWithParams({
				damId: damId,
				imageConfig: imageConfig,
				width: imgTagWidth || source.width || rootWidth,
				height: imgTagHeight || source.height || rootHeight,
			}),
		};
	});

	return {
		jpeg: sourcesRawData,
		webp: JSON.parse(
			JSON.stringify(sourcesRawData).replaceAll("/jpeg", "/webp"),
		) as typeof sourcesRawData,
		avif: JSON.parse(
			JSON.stringify(sourcesRawData).replaceAll("/jpeg", "/avif"),
		) as typeof sourcesRawData,
	};
}

export interface GenerateImageChunkPropsExperimental {
	srcSet?: Array<string>;
	srcSetURL?: Array<string>;
	format: ImageFormats;
}

export interface UseGriddoImagePropsExperimental
	extends Omit<ImageConfigExperimental, "formats"> {
	url?: string;
}

export interface ImageConfigExperimental {
	blurCSSTransition?: string;
	blurSize?: string;
	crop?: ImageCropType;
	decoding?: ImageDecoding;
	domain?: string;
	format?: ImageFormats;
	formats?: Array<ImageFormats>;
	height?: string;
	loading?: ImageLoading;
	position?: ImagePosition;
	quality?: number;
	transforms?: ImageTransform;
	width?: string;
	sizes?: string;
	widths?: Array<string>;
	ratio?: number;
}

export type MIMETypesExperimental =
	| "image/avif"
	| "image/gif"
	| "image/jpeg"
	| "image/png"
	| "image/svg+xml"
	| "image/webp";

export interface ImageChunkExperimental {
	type: MIMETypesExperimental;
	srcSet?: Array<string>;
	srcSetURL?: Array<string>;
}

export interface UseGriddoImageReturnExperimental {
	type?: string;
	srcSet?: Array<string>;
	srcSetURL?: Array<string>;
	src?: string;
	sizes?: string;
	webpFallback?: ImageChunkExperimental;
	jpg?: ImageChunkExperimental;
	jpeg?: ImageChunkExperimental;
	webp?: ImageChunkExperimental;
	avif?: ImageChunkExperimental;
	png?: ImageChunkExperimental;
	gif?: ImageChunkExperimental;
	svg?: ImageChunkExperimental;
}

function removeCSSUnits(value: string) {
	const match = value.match(/^-?\d*\.?\d+/);
	if (match) {
		return Number.parseFloat(match[0]);
	}
	return 0;
}

export {
	getGriddoArtDirectionImageSources as getGriddoArtDirectionImage,
	removeCSSUnits,
};
