import type { Url } from "../../types/api-response-fields";

import * as React from "react";

import { useLink } from "../../hooks/useLink";
import { usePage } from "../../hooks/usePage";

export interface GetLinkProps {
	href?: string;
	linkToURL?: string;
}

// return an anchor if the link is in the same page.
function parseAnchor(pageUrl: string, linkUrl: string) {
	const hasAnchorId = linkUrl?.startsWith(`${pageUrl}#`);
	return hasAnchorId ? `#${linkUrl.split("#")[1]}` : linkUrl;
}

// Return { href, to } from a griddo url object field
function getLinks(url?: GetLinkProps) {
	// Standar external url
	const href = url?.href;
	// linkToURL could be // for internal absolute or / for router links
	const linkToURL = url?.linkToURL;
	// linkToURL: "//cx-web.com/site" or "https(s):"
	const linkToURLHasDoubleSlashOrHttp = linkToURL?.match(/^\/{2}|^https?:/g);
	// linkToURL: "/future-router-link
	const linkToURL_hasSingleSlash = linkToURL?.match(/^\/\w/g);

	const result = {
		href: href ? href : linkToURLHasDoubleSlashOrHttp ? linkToURL : null,
		to: linkToURL_hasSingleSlash ? linkToURL : null,
	};

	return result;
}

/**
 * @deprecated This component will be removed from Griddo in the future.
 */
function Link(props: LinkProps) {
	const { url, linkProp = "to", children, ...rest } = props;

	// Get page info from provider
	const { fullUrl } = usePage();
	// Get the router component from the provider
	const RouterLink = useLink() || "a";
	// Get href for the normal links and to for the router links
	const { href, to } = getLinks(url);

	const newTab = url?.newTab;
	const noFollow = url?.noFollow;

	const linkProps = {
		...rest,
		...(newTab && { target: "_blank" }),
		rel: `${noFollow ? "nofollow" : ""} ${newTab ? "noreferrer" : ""}`.trim(),
		...(fullUrl && href && { href: parseAnchor(fullUrl, href) }),
		...(fullUrl && to && { [linkProp]: parseAnchor(fullUrl, to) }),
	};

	return to ? (
		<>
			<p>Router</p>
			<RouterLink {...linkProps}>{children}</RouterLink>
		</>
	) : (
		React.createElement("a", linkProps, children)
	);
}

interface LinkProps {
	url: Url;
	linkProp?: string;
	children: JSX.Element;
	[key: string]: unknown;
}

export { Link };
