import differenceInSeconds from "date-fns/differenceInSeconds";
import { getSchema, getDisplayName, getSchemaType, isComponentEmpty } from "@ax/helpers";
import { IComponent, IModule, ISchemaField } from "@ax/types";

const getComponentTitle = (component: string, title: any) => {
  if (!title) return;
  const {
    displayName,
    default: { title: defaultTitle },
  } = getSchema(component);
  if (!defaultTitle) return displayName;
  const { content } = defaultTitle;
  if (content) return content === title.content ? displayName : title.content;
  return defaultTitle === title ? displayName : title;
};

const getComponentProps = (element: IModule, activatedModules: string[], isModuleArr: boolean) => {
  const { component } = element;
  const schemaType = getSchemaType(component);
  const displayName = getDisplayName(component);
  const title = getComponentTitle(component, element.title);
  const isModule = schemaType === "module" || schemaType === "formComponent";
  const moduleTitle = isModule && displayName !== title && title;
  const componentTitle = !isModule && displayName !== title && title;
  const isModuleDeactivated = isModuleArr && activatedModules && !activatedModules.includes(component);

  return { moduleTitle, isModuleDeactivated, componentTitle, displayName, isModule };
};

const containerToComponentArray = (value?: Record<string, IComponent>): IComponent[] =>
  value ? Object.values(value).filter((item: IComponent) => !isComponentEmpty(item)) : [];

const getTypefromKey = (key: string) => {
  switch (key) {
    case "elements":
    case "componentModules":
      return "components";
    case "modules":
      return key;
    default:
      return null;
  }
};

const getTimeSinceModuleCopy = (moduleCopy: { date: Date; elements: IModule[] }) =>
  differenceInSeconds(new Date(), new Date(moduleCopy.date));

const getModulesToPaste = (
  moduleCopy: { date: Date; elements: IModule[] } | null,
  whiteList: string[],
  availableDataPacks: Record<string, any>[],
  template: any,
  field: ISchemaField
) => {
  const modulesToPaste: IModule[] = [];
  const unavailableModules: IModule[] = [];
  const eightHoursInSeconds = 8 * 60 * 60;

  if (moduleCopy && getTimeSinceModuleCopy(moduleCopy) < eightHoursInSeconds) {
    moduleCopy.elements.forEach((element) => {
      const { component } = element;

      const availableDataPackModule = availableDataPacks?.reduce((prev: any, curr: any) => {
        const packModule = curr.modules.find((module: any) => module.id === component);
        return prev || packModule;
      }, null);

      if (
        availableDataPackModule &&
        !whiteList.includes(component) &&
        !!availableDataPackModule?.sectionList[template.component]?.includes(field.key)
      ) {
        unavailableModules.push(element);
      }

      if (whiteList.includes(component)) {
        modulesToPaste.push(element);
      }
    });
  }

  return { modulesToPaste, unavailableModules };
};

export { getComponentTitle, getComponentProps, containerToComponentArray, getTypefromKey, getModulesToPaste };
