import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { MenuItem, SubNav } from "@ax/components";
import { appActions } from "@ax/containers/App";
import { getSchemaFormCategories } from "@ax/helpers";

import MenuGroup from "./MenuGroup";

import * as S from "./style";
import { IRootState, ISite } from "@ax/types";

const FormsMenu = (props: IProps): JSX.Element => {
  const { selected, currentSiteInfo, setHistoryPush } = props;

  const basePath = currentSiteInfo ? "/sites" : "";
  const formCategories = getSchemaFormCategories();

  const navItems: INavItem[] = [
    {
      label: "All Forms",
      path: `${basePath}/forms`,
    },
  ];

  if (formCategories.length) {
    navItems.push({
      label: "Forms Categories",
      path: `${basePath}/forms/categories/`,
      items: formCategories,
    });
  }

  const init = selected ? selected : navItems[0].label;
  const [current, setCurrent] = useState<string>(init);

  return (
    <SubNav>
      <S.Wrapper>
        {navItems.map((item) => {
          const isSelected = item.label === current;
          const selectedClass = isSelected ? "selected" : "";

          const handleClick = () => {
            setCurrent(item.label);
            setHistoryPush(item.path);
          };

          const handleClickSubmenu = (subItem: { label: string; value: string }) => {
            setCurrent(subItem.label);
            setHistoryPush(`${item.path}${subItem.value}`);
          };

          return (
            <React.Fragment key={item.path}>
              {!item.items ? (
                <MenuItem onClick={handleClick} className={selectedClass}>
                  <NavLink to="#">
                    <S.Link active={isSelected}>{item.label}</S.Link>
                  </NavLink>
                </MenuItem>
              ) : (
                <MenuGroup navItem={item} current={current} onClick={handleClickSubmenu} />
              )}
            </React.Fragment>
          );
        })}
      </S.Wrapper>
    </SubNav>
  );
};

interface INavItem {
  label: string;
  path: string;
  items?: { label: string; value: string; featured?: boolean }[];
}

interface IProps {
  selected?: string;
  currentSiteInfo: ISite | null;
  setHistoryPush(path: string, isEditor?: boolean): Promise<void>;
}

const mapStateToProps = (state: IRootState) => ({
  currentSiteInfo: state.sites.currentSiteInfo,
});

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormsMenu);
