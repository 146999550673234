import React from "react";
import { connect } from "react-redux";

import { menuActions } from "@ax/containers/Navigation";
import { appActions } from "@ax/containers/App";
import { ILanguage, IRootState } from "@ax/types";
import { setIsSavedData } from "@ax/forms";
import { MainWrapper } from "@ax/components";
import { usePermission } from "@ax/hooks";

import List from "./List";

const MenuView = (props: IMenus) => {
  const { updateMenu, setLanguage, isSaving, lang, siteLanguages } = props;

  const isAllowedToMangageMenus = usePermission("navigation.manageSiteMenu");

  const saveMenu = () => {
    setIsSavedData(true);
    return updateMenu();
  };

  const rightButtonProps = isAllowedToMangageMenus
    ? {
        label: isSaving ? "Saving" : "Save",
        disabled: isSaving,
        action: saveMenu,
      }
    : undefined;

  const handleLanguage = (language: ILanguage) => {
    const { locale, id } = language;
    setLanguage({ locale, id });
  };

  return (
    <MainWrapper
      title="Menus"
      rightButton={rightButtonProps}
      language={lang}
      availableLanguages={siteLanguages}
      languageAction={handleLanguage}
    >
      <List />
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  isSaving: state.app.isSaving,
  lang: state.app.lang,
  siteLanguages: state.sites.currentSiteLanguages,
});

const mapDispatchToProps = {
  updateMenu: menuActions.updateMenu,
  setLanguage: appActions.setLanguage,
};

interface IStateProps {
  isSaving: boolean;
  lang: { locale: string; id: number | null };
  siteLanguages: ILanguage[];
}

interface IDispatchProps {
  updateMenu(): void;
  setLanguage(lang: { locale: string; id: number | null }): void;
}

type IMenus = IStateProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(MenuView);
