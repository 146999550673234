import { Dispatch } from "redux";
import {
  SET_CATEGORIES,
  SET_STRUCTURED_DATA,
  SET_CURRENT_STRUCTURED_DATA,
  SET_CURRENT_STRUCTURED_DATA_ID,
  SET_CURRENT_STRUCTURED_DATA_CONTENTS,
  SET_SCHEMA,
  UPDATE_FORM,
  CREATE_FORM,
  SET_IS_ACTIVE,
  SET_ENTITY,
  DEFAULT_PARAMS,
  SET_FILTER,
  SET_SCHEMA_VERSION,
  SET_ERRORS,
  SET_VALIDATED,
  SET_CONTENT_FILTERS,
  SET_IS_IA_TRANSLATED,
  SET_CURRENT_DATA_CATEGORY,
  SET_CURRENT_SEARCH,
} from "./constants";

import {
  ISetCategories,
  ISetStructuredData,
  ISetCurrentData,
  ISetCurrentDataID,
  ISetCurrentDataContent,
  ISetIsActive,
  ISetFilter,
  ISetSchemaVersion,
  ISetErrors,
  ISetValidated,
  ISetContentFilters,
  ISetIsIATranslated,
  ISetCurrentCategories,
  ISetCurrentSearch,
} from "./interfaces";
import {
  prepareStructuredDataContent,
  getTaxonomies,
  filterStructuredDataByID,
  getTypes,
  evaluateDataComputedFields,
} from "./utils";
import {
  IStructuredData,
  IStructuredDataContent,
  IGetStructuredDataParams,
  IErrorItem,
  IStructuredDataQueryValues,
  IStructuredDataCategory,
  ICategoryGroupParams,
  IRootState,
  IOrderCategoryParams,
  IExportDataParams,
} from "@ax/types";
import { structuredData } from "@ax/api";
import { setTotalItems } from "@ax/containers/Sites/actions";
import { appActions } from "@ax/containers/App";
import { dateToString, deepClone, handleRequest, isEmptyArray } from "@ax/helpers";
import { findMandatoryStructuredDataErrors } from "@ax/forms";

const { setIsLoading, setIsSaving } = appActions;

function setIsActive(isActive: boolean): ISetIsActive {
  return { type: SET_IS_ACTIVE, payload: { isActive } };
}

function setCategories(categories: { global: IStructuredData[]; site: IStructuredData[] }): ISetCategories {
  return { type: SET_CATEGORIES, payload: { categories } };
}

// tslint:disable-next-line: no-shadowed-variable
function setStructuredData(structuredData: { global: IStructuredData[]; site: IStructuredData[] }): ISetStructuredData {
  return { type: SET_STRUCTURED_DATA, payload: { structuredData } };
}

function setCurrentData(currentStructuredData: IStructuredData | null): ISetCurrentData {
  return { type: SET_CURRENT_STRUCTURED_DATA, payload: { currentStructuredData } };
}

function setCurrentDataID(currentStructuredDataId: number | null): ISetCurrentDataID {
  return { type: SET_CURRENT_STRUCTURED_DATA_ID, payload: { currentStructuredDataId } };
}

function setCurrentDataContent(currentDataContent: IStructuredDataContent[]): ISetCurrentDataContent {
  return { type: SET_CURRENT_STRUCTURED_DATA_CONTENTS, payload: { currentDataContent } };
}

function setCurrentDataCategory(currentDataCategory: IStructuredDataCategory[]): ISetCurrentCategories {
  return { type: SET_CURRENT_DATA_CATEGORY, payload: { currentDataCategory } };
}

function setSchema(schema: any) {
  return { type: SET_SCHEMA, payload: { schema } };
}

function setForm(form: any) {
  return { type: CREATE_FORM, payload: { form } };
}

function updateForm(form: any) {
  return { type: UPDATE_FORM, payload: { form } };
}

function setEntity(entity: string | null) {
  return { type: SET_ENTITY, payload: { entity } };
}

function setFilter(currentFilter: string): ISetFilter {
  return { type: SET_FILTER, payload: { currentFilter } };
}

function setSchemaVersion(schemaVersion: string): ISetSchemaVersion {
  return { type: SET_SCHEMA_VERSION, payload: { schemaVersion } };
}

function setErrors(errors: IErrorItem[]): ISetErrors {
  return { type: SET_ERRORS, payload: { errors } };
}

function setValidated(validated: boolean): ISetValidated {
  return { type: SET_VALIDATED, payload: { validated } };
}

function setContentFilters(contentFilters: Record<string, IStructuredDataQueryValues> | null): ISetContentFilters {
  return { type: SET_CONTENT_FILTERS, payload: { contentFilters } };
}

function setIsIATranslated(isIATranslated: boolean): ISetIsIATranslated {
  return { type: SET_IS_IA_TRANSLATED, payload: { isIATranslated } };
}

function setCurrentSearch(currentSearch: string): ISetCurrentSearch {
  return { type: SET_CURRENT_SEARCH, payload: { currentSearch } };
}

function updateFormValue(valueObj: any): (dispatch: Dispatch, getState: any) => void {
  return (dispatch, getState) => {
    const {
      structuredData: { form },
    } = getState();
    const updatedForm = { ...form, content: { ...form?.content, ...valueObj } };

    dispatch(updateForm(updatedForm));
  };
}

function resetForm(setDefault?: boolean): (dispatch: Dispatch, getState: any) => void {
  return (dispatch, getState) => {
    const {
      structuredData: { currentStructuredData },
    } = getState();

    const formContent = setDefault && currentStructuredData ? { content: currentStructuredData.defaultValues } : null;

    dispatch(setEntity(null));
    dispatch(updateForm(formContent));
    dispatch(setErrors([]));
    dispatch(setValidated(false));
    dispatch(setCurrentDataID(null));
    dispatch(setIsIATranslated(false));
  };
}

const filterStructuredData = (data: IStructuredData[], isTaxonomy: boolean, siteID?: number | null) => {
  const scope = !siteID ? "global" : "site";

  const filteredData: { global: IStructuredData[]; site: IStructuredData[] } = { global: [], site: [] };

  isTaxonomy ? (filteredData[scope] = getTaxonomies(data)) : (filteredData[scope] = getTypes(data));

  return filteredData;
};

function getStructuredData(
  token: string | null,
  siteID?: number | null,
  hasLoading = true
): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    try {
      const {
        structuredData: { structuredData: currentStructuredData },
      } = getState();

      const responseActions = {
        handleSuccess: (response: any) => {
          const { schemasTimestamp, items } = response;

          const categories = filterStructuredData(items, true, siteID);
          const structuredDataValues = filterStructuredData(items, false, siteID);
          dispatch(setCategories(categories));
          dispatch(setSchemaVersion(schemasTimestamp));

          const newStructuredData: any = [];
          for (const [key, value] of Object.entries(structuredDataValues)) {
            if (!isEmptyArray(value)) newStructuredData[key] = value;
          }
          dispatch(setStructuredData({ ...currentStructuredData, ...newStructuredData }));
        },
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => structuredData.getData(token, siteID);

      const loading = hasLoading === false ? [] : [appActions.setIsLoading];

      await handleRequest(callback, responseActions, loading)(dispatch);
    } catch (e) {
      console.log(e);
    }
  };
}

function setSelectedStructuredData(id: string, scope: string): (dispatch: Dispatch, getState: any) => void {
  return (dispatch, getState) => {
    const {
      structuredData: { structuredData: data },
    } = getState();

    const currentData = filterStructuredDataByID(data[scope], id);

    if (currentData) {
      const { schema } = currentData;
      dispatch(setSchema(schema));
      dispatch(setCurrentData(currentData));
    } else {
      dispatch(setCurrentData(null));
    }
  };
}

function setSelectedCategory(id: string, scope: string): (dispatch: Dispatch, getState: any) => void {
  return (dispatch, getState) => {
    const {
      structuredData: { categories: data },
    } = getState();
    const currentData = filterStructuredDataByID(data[scope], id);

    dispatch(setFilter(id));
    dispatch(setCurrentData(currentData));
  };
}

function getStructuredDataContents(
  params: IGetStructuredDataParams,
  siteID?: number | null,
  hasLoading = true
): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    const { groupingCategories } = params;

    if (!params.dataID) {
      if (groupingCategories) {
        dispatch(setCurrentDataCategory([]));
      } else {
        dispatch(setCurrentDataContent([]));
      }
      dispatch(setIsLoading(false));
      return;
    }

    try {
      const {
        structuredData: { schemaVersion },
      } = getState();

      const updateStructuredData = async () => {
        await getStructuredData(null, siteID)(dispatch, getState);
        const scope = siteID ? "site" : "global";
        params.dataID && setSelectedStructuredData(params.dataID, scope)(dispatch, getState);
      };

      const responseActions = {
        handleSuccess: (response: any) => {
          const { items, totalItems, schemasTimestamp } = response;
          if (groupingCategories) {
            dispatch(setCurrentDataCategory(items));
          } else {
            dispatch(setCurrentDataContent(items));
          }
          dispatch(setTotalItems(totalItems));

          if (!schemaVersion || schemaVersion < schemasTimestamp) {
            updateStructuredData();
          }
        },
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => structuredData.getDataContents(params, siteID);

      const loading = hasLoading ? [appActions.setIsLoading] : [];

      await handleRequest(callback, responseActions, loading)(dispatch);
    } catch (e) {
      console.log(e);
    }
  };
}

function getDataContent(id: number, lang?: { locale: string; id: number }): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      const langID = lang ? lang.id : null;

      const responseActions = {
        handleSuccess: (response: any) => dispatch(setForm(response)),
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => structuredData.getDataContent(id, langID);

      await handleRequest(callback, responseActions, [appActions.setIsLoading])(dispatch);
    } catch (e) {
      console.log(e);
    }
  };
}

function createStructuredDataContent(
  structuredDataContent: IStructuredDataContent | IStructuredDataCategory,
  langId?: number | null
): (dispatch: Dispatch, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        app: { lang },
        sites: { currentSiteInfo },
        structuredData: { schema, currentStructuredData },
      }: IRootState = getState();

      const isCategory = currentStructuredData?.taxonomy === true;
      let dataContent = prepareStructuredDataContent(structuredDataContent);
      if (Array.isArray(schema.fields) && !isCategory) {
        dataContent = await evaluateDataComputedFields(structuredDataContent, schema.fields);
      }

      dataContent = {
        ...dataContent,
        language: langId ? langId : lang.id,
        relatedSite: currentSiteInfo && currentSiteInfo.id,
      };

      const responseActions = {
        handleSuccess: (response: any) => dispatch(setForm(response)),
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => structuredData.createDataContent(dataContent);

      return await handleRequest(callback, responseActions, [appActions.setIsSaving])(dispatch);
    } catch (e) {
      dispatch(setIsSaving(false));
      console.log(e); // TODO: capturar error bien
      return false;
    }
  };
}

function updateStructuredDataContent(
  structuredDataContent: IStructuredDataContent | IStructuredDataCategory
): (dispatch: Dispatch, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        structuredData: { schema, currentStructuredData },
      }: IRootState = getState();

      const isCategory = currentStructuredData?.taxonomy === true;
      let dataContent = prepareStructuredDataContent(structuredDataContent);
      if (Array.isArray(schema.fields) && !isCategory) {
        dataContent = await evaluateDataComputedFields(structuredDataContent, schema.fields);
      }

      const responseActions = {
        handleSuccess: (response: any) => dispatch(setForm(response)),
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => structuredData.updateDataContent(structuredDataContent.id, dataContent);

      return await handleRequest(callback, responseActions, [appActions.setIsSaving])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function deleteStructuredDataContent(
  id: number | number[],
  refresh = true
): (dispatch: Dispatch, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));

      const {
        structuredData: { currentFilter, currentStructuredData },
        sites: { currentSiteInfo, currentFilter: currentSiteFilter },
      }: IRootState = getState();

      const params = {
        ...DEFAULT_PARAMS,
        dataID: currentSiteInfo ? currentSiteFilter : currentFilter,
        groupingCategories: currentStructuredData?.taxonomy || false,
      };

      const siteID = currentSiteInfo && currentSiteInfo.id;

      const responseActions = {
        handleSuccess: () => refresh && getStructuredDataContents(params, siteID)(dispatch, getState),
        handleError: (response: any) => {
          const {
            data: { message },
          } = response;
          const isMultiple = Array.isArray(message) && message.length > 1;
          const msg = isMultiple ? `The delete action failed due to ${message.length} errors.` : undefined;
          appActions.handleError(response, isMultiple, msg)(dispatch);
        },
      };

      const callback = async () =>
        Array.isArray(id) ? structuredData.deleteDataContentBulk(id) : structuredData.deleteDataContent(id);

      return await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function restoreStructuredDataContent(dataID: number | number[]): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));

      const {
        structuredData: { currentFilter },
        sites: { currentSiteInfo, currentFilter: currentSiteFilter },
      } = getState();

      const params = { ...DEFAULT_PARAMS, dataID: currentSiteInfo ? currentSiteFilter : currentFilter };

      const siteID = currentSiteInfo && currentSiteInfo.id;

      const responseActions = {
        handleSuccess: () => getStructuredDataContents(params, siteID)(dispatch, getState),
        handleError: (response: any) => {
          const {
            data: { message },
          } = response;
          const isMultiple = Array.isArray(message) && message.length > 1;
          const msg = isMultiple ? `The restore action failed due to ${message.length} errors.` : undefined;
          appActions.handleError(response, isMultiple, msg)(dispatch);
        },
      };

      const callback = async () =>
        Array.isArray(dataID)
          ? structuredData.restoreDataContentBulk(dataID)
          : structuredData.restoreDataContent(dataID);

      await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e);
    }
  };
}

function getDistributorContent(data: any): (getState: any) => Promise<any> {
  return async (getState) => {
    try {
      const {
        sites: {
          currentSiteInfo: { id },
        },
      } = getState();
      return structuredData.getDistributorContent(id, data);
    } catch (e) {
      console.log("error in get distributor");
    }
  };
}

function setStatusStructuredDataContent(
  id: number | number[],
  status: string
): (dispatch: Dispatch, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        structuredData: { currentFilter },
        sites: { currentSiteInfo, currentFilter: currentSiteFilter },
      } = getState();

      const params = { ...DEFAULT_PARAMS, dataID: currentSiteInfo ? currentSiteFilter : currentFilter };

      const siteID = currentSiteInfo && currentSiteInfo.id;

      const responseActions = {
        handleSuccess: () => getStructuredDataContents(params, siteID)(dispatch, getState),
        handleError: (response: any) => {
          const {
            data: { message },
          } = response;
          const isMultiple = Array.isArray(message) && message.length > 1;
          const msg = isMultiple ? `The action failed due to ${message.length} errors.` : undefined;
          appActions.handleError(response, isMultiple, msg)(dispatch);
        },
      };

      const callback = async () =>
        Array.isArray(id) ? structuredData.setDataStatusBulk(id, status) : structuredData.setDataStatus(id, status);

      return await handleRequest(callback, responseActions, [appActions.setIsLoading])(dispatch);
    } catch (e) {
      console.log(e); // TODO: capturar error bien
      return false;
    }
  };
}

function validateForm(publish?: boolean): (dispatch: Dispatch, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        structuredData: {
          form: { content },
          schema,
        },
      } = getState();

      const formContent = deepClone(content);
      const errors = findMandatoryStructuredDataErrors(formContent, schema);
      dispatch(setErrors(errors));
      if (errors.length === 0) {
        !publish && dispatch(setValidated(true));
        return true;
      } else {
        dispatch(setValidated(false));
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function deleteError(error: IErrorItem): (dispatch: Dispatch, getState: any) => void {
  return async (dispatch, getState) => {
    try {
      const {
        structuredData: { errors },
      } = getState();

      const updatedErrors = errors.filter((err: IErrorItem) => error.key !== err.key);

      dispatch(setErrors(updatedErrors));
    } catch (e) {
      console.log(e);
    }
  };
}

function resetStructuredData(): (dispatch: Dispatch) => void {
  return async (dispatch) => {
    try {
      dispatch(setCurrentDataContent([]));
      dispatch(setCurrentData(null));
      dispatch(setCurrentDataID(null));
    } catch (e) {
      console.log(e);
    }
  };
}

function getDataContentTranslation(langID: number): (dispatch: Dispatch, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        structuredData: { form },
      } = getState();

      const responseActions = {
        handleSuccess: (data: IStructuredDataContent) => {
          const newForm = { ...data, canBeTranslated: false };
          dispatch(setForm(newForm));
          dispatch(setIsIATranslated(true));
        },
        handleError: () => console.log("Error en GetDataContentTranslation"),
      };

      const callback = async () => structuredData.getDataContentTranslation(form, langID);

      return await handleRequest(callback, responseActions, [appActions.setIsLoading])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function setFormValues(values: any): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      dispatch(updateForm(values));
    } catch (e) {
      console.log("Error", e);
    }
  };
}

function setIsTranslated(isTranslated: boolean): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      dispatch(setIsIATranslated(isTranslated));
    } catch (e) {
      console.log("Error", e);
    }
  };
}

function resetCurrentData(): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      dispatch(setCurrentData(null));
      dispatch(setCurrentDataID(null));
      dispatch(setCurrentDataContent([]));
      dispatch(setSchema({}));
      //dispatch(setCurrentSearch(""));
    } catch (e) {
      console.log("Error", e);
    }
  };
}

function createCategoryGroup(data: ICategoryGroupParams): (dispatch: Dispatch, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        sites: { currentSiteInfo },
        app: { lang },
      }: IRootState = getState();

      const dataGroup: ICategoryGroupParams = {
        ...data,
        language: data.language ? data.language : lang.id,
        relatedSite: currentSiteInfo ? currentSiteInfo.id : "global",
      };

      const responseActions = {
        handleSuccess: () => null,
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => structuredData.createGroup(dataGroup);

      return await handleRequest(callback, responseActions, [appActions.setIsLoading])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function updateCategoryGroup(groupID: number, data: ICategoryGroupParams): (dispatch: Dispatch) => Promise<boolean> {
  return async (dispatch) => {
    try {
      const responseActions = {
        handleSuccess: () => null,
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => structuredData.updateGroup(groupID, data);

      return await handleRequest(callback, responseActions, [appActions.setIsLoading])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function deleteCategoryGroup(
  id: number | number[],
  deleteChildren: boolean,
  refresh = true
): (dispatch: Dispatch, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        structuredData: { currentFilter, currentStructuredData },
        sites: { currentSiteInfo },
      }: IRootState = getState();

      if (!currentStructuredData) return false;

      dispatch(setIsLoading(true));

      const params = { ...DEFAULT_PARAMS, dataID: currentFilter, groupingCategories: true };

      const siteID = currentSiteInfo ? currentSiteInfo.id : "global";

      const responseActions = {
        handleSuccess: () => refresh && getStructuredDataContents(params, currentSiteInfo?.id)(dispatch, getState),
        handleError: (response: any) => {
          const {
            data: { message },
          } = response;
          const isMultiple = Array.isArray(message) && message.length > 1;
          const msg = isMultiple ? `The delete action failed due to ${message.length} errors.` : undefined;
          appActions.handleError(response, isMultiple, msg)(dispatch);
        },
      };

      const callback = async () =>
        Array.isArray(id)
          ? structuredData.deleteGroupBulk(currentStructuredData.id, siteID, id, deleteChildren)
          : structuredData.deleteGroup(currentStructuredData.id, siteID, id, deleteChildren);

      return await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function orderCategory(data: IOrderCategoryParams): (dispatch: Dispatch, getState: any) => Promise<boolean> {
  return async (dispatch, getState) => {
    try {
      const {
        structuredData: { currentFilter },
        sites: { currentSiteInfo },
      }: IRootState = getState();

      const params = { ...DEFAULT_PARAMS, dataID: currentFilter, groupingCategories: true };

      const responseActions = {
        handleSuccess: () => getStructuredDataContents(params, currentSiteInfo?.id, false)(dispatch, getState),
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => structuredData.orderCategory(data);

      return await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

function updateCurrentSearch(query: string): (dispatch: Dispatch) => Promise<void> {
  return async (dispatch) => {
    try {
      dispatch(setCurrentSearch(query));
    } catch (e) {
      console.log(e);
    }
  };
}

function exportDataContent(
  structuredDataID: string,
  data: IExportDataParams
): (dispatch: Dispatch, getState: any) => Promise<void> {
  return async (dispatch, getState) => {
    try {
      const {
        sites: { currentSiteInfo },
      }: IRootState = getState();

      const siteID = currentSiteInfo ? currentSiteInfo.id : "global";
      const date = new Date();
      const dateString = dateToString(date, "yyyy-MM-dd");
      const fileName =
        data.format.length === 1
          ? `${structuredDataID}-${dateString}.${data.format[0]}`
          : `${structuredDataID}-${dateString}.zip`;

      const responseActions = {
        handleSuccess: (response: any) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        },
        handleError: (response: any) => appActions.handleError(response)(dispatch),
      };

      const callback = async () => structuredData.exportDataContent(structuredDataID, siteID, data);

      await handleRequest(callback, responseActions, [])(dispatch);
    } catch (e) {
      console.log(e);
    }
  };
}

export {
  setIsActive,
  setCategories,
  setStructuredData,
  setCurrentData,
  setCurrentDataID,
  setSchema,
  setForm,
  updateForm,
  updateFormValue,
  resetForm,
  getStructuredData,
  setSelectedStructuredData,
  setSelectedCategory,
  getStructuredDataContents,
  createStructuredDataContent,
  updateStructuredDataContent,
  deleteStructuredDataContent,
  getDistributorContent,
  setEntity,
  getDataContent,
  restoreStructuredDataContent,
  setStatusStructuredDataContent,
  filterStructuredData,
  setFilter,
  validateForm,
  deleteError,
  setContentFilters,
  resetStructuredData,
  getDataContentTranslation,
  setFormValues,
  setIsTranslated,
  resetCurrentData,
  createCategoryGroup,
  updateCategoryGroup,
  deleteCategoryGroup,
  orderCategory,
  updateCurrentSearch,
  exportDataContent,
};
