import React from "react";
import styled from "styled-components";
import { Button } from "@ax/components";

const Wrapper = styled.div``;

const Label = styled.div`
  ${(p) => p.theme.textStyle.headingXXS};
  color: ${(p) => p.theme.colors.textMediumEmphasis};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  border-bottom: 1px solid ${(p) => p.theme.color.uiLine};
`;

const Content = styled.div`
  background-color: ${(p) => p.theme.color.uiBackground03};
  padding: ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.radii.s};
  margin-top: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.xxs};
  svg {
    vertical-align: middle;
    display: inline-block;
  }
`;

const Text = styled.div`
  ${(p) => p.theme.textStyle.uiXS};
  color: ${(p) => p.theme.color.textMediumEmphasis};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(p) => p.theme.spacing.s};
`;

const StyledButton = styled((props) => <Button {...props} />)`
  width: 100%;
  max-width: 264px;
`;

const StatusWrapper = styled.div`
  ${(p) => p.theme.textStyle.uiS};
  color: ${(p) => p.theme.color.textHighEmphasis};
  margin-top: ${(p) => p.theme.spacing.xs};
`;

const Check = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(p) => p.theme.spacing.xxs};
`;

const IconWrapper = styled.div<{ color: "success" | "warning" | "close" }>`
  width: ${(p) => p.theme.spacing.s};
  height: ${(p) => p.theme.spacing.s};
  margin-right: ${(p) => p.theme.spacing.xs};
  svg path {
    fill: ${(p) =>
      p.color === "close" ? p.theme.color.error : p.color === "warning" ? p.theme.color.warning : p.theme.color.online};
  }
`;

export { Wrapper, Label, Content, Text, ButtonWrapper, StyledButton, StatusWrapper, Check, IconWrapper };
