import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable, DropResult, BeforeCapture } from "react-beautiful-dnd";

import { formsActions } from "@ax/containers/Forms";
import { IRootState, FormCategory, FormCategoriesOrderParams } from "@ax/types";
import { useBulkSelection, useModal, useToast } from "@ax/hooks";
import { MainWrapper, TableList, ErrorToast, Toast, EmptyState, SearchTagsBar } from "@ax/components";
import { getSchemaFormCategories } from "@ax/helpers";

import { DeleteModal } from "./atoms";
import CategoryItem from "./CategoryItem";
import CategoryPanel from "./CategoryPanel";
import BulkHeader from "./BulkHeader";
import FormsMenu from "../FormsMenu";

import * as S from "./style";

const FormCategoriesList = (props: IProps): JSX.Element => {
  const { categories, currenSiteID, getFormCategories, deleteFormCategory, orderFormCategory } = props;

  const schemaCategories = getSchemaFormCategories();
  const { cat: urlCategory } = useParams<{ cat: string }>();

  const category =
    !urlCategory || !schemaCategories.map((cat) => cat.value).includes(urlCategory)
      ? schemaCategories[0].value
      : urlCategory;
  const categoryLabel = schemaCategories.find((cat) => cat.value === category)?.label;

  const [isScrolling, setIsScrolling] = useState(false);
  const [draggingId, setDraggingId] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { isVisible, toggleToast, setIsVisible, state: toastState } = useToast();
  const { isOpen, toggleModal } = useModal();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const tableRef = useRef<HTMLDivElement>(null);

  const catIds = categories.map((category) => category.id);
  const totalItems = categories.length;

  const {
    resetBulkSelection,
    selectedItems,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
    setHoverCheck,
  } = useBulkSelection(catIds);

  useEffect(() => {
    const getContents = async () => await getFormCategories(category);
    getContents();
    return () => {
      //resetCurrentData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const rightButtonProps = {
    label: "New",
    action: toggleModal,
  };

  const bulkDelete = async () => {
    const deleted = await deleteFormCategory(selectedItems.all, category);
    if (deleted) {
      toggleDeleteModal();
    }
  };

  const selectItems = () => (checkState.isAllSelected ? resetBulkSelection() : selectAllItems());

  const onScroll = (e: any) => setIsScrolling(e.target.scrollTop > 0);

  const toastProps = {
    setIsVisible,
    message: toastState,
  };

  const bulkActions = [
    {
      icon: "delete",
      text: "delete",
      action: toggleDeleteModal,
    },
  ];

  const TableHeader = (
    <BulkHeader
      showBulk={areItemsSelected(catIds)}
      totalItems={totalItems}
      selectItems={selectItems}
      checkState={checkState}
      isScrolling={isScrolling}
      bulkActions={bulkActions}
      setHoverCheck={setHoverCheck}
    />
  );

  const ComponentList = React.memo(function ComponentList({ components }: any) {
    return components.map((category: FormCategory, i: number) => {
      const isItemSelected = isSelected(category.id);
      const isDragging = !!draggingId && draggingId !== category.id;

      return (
        <Draggable draggableId={`${category.id}`} index={i} key={category.id}>
          {(provided) => (
            <CategoryItem
              category={category}
              key={category.id}
              isSelected={isItemSelected}
              onChange={addToBulkSelection}
              toggleToast={toggleToast}
              hoverCheck={checkState.hoverCheck}
              isDragging={isDragging}
              provided={provided}
            />
          )}
        </Draggable>
      );
    });
  });

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) {
      setDraggingId(null);
      return;
    }

    const newPosition = result.destination.index
      ? categories[result.destination.index - (result.destination.index > result.source.index ? 0 : 1)].position + 1
      : 1;

    const params: FormCategoriesOrderParams = {
      id: parseInt(result.draggableId),
      categoryType: category,
      position: newPosition,
      relatedSite: currenSiteID,
    };

    await orderFormCategory(params);

    setDraggingId(null);
  };

  const onBeforeCapture = (start: BeforeCapture) => setDraggingId(parseInt(start.draggableId));

  const isEmpty = totalItems === 0;
  const emptyStateProps = {
    message: "To start using categories in your forms, create as many categories as you need.",
    button: "New Category",
    action: toggleModal,
  };

  const mainDeleteModalAction = {
    title: "Yes, delete it",
    onClick: bulkDelete,
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  return (
    <MainWrapper title="Forms" rightButton={rightButtonProps} searchAction={setSearchQuery} searchValue={searchQuery}>
      <S.CategoryListWrapper>
        <FormsMenu selected={categoryLabel} />
        <S.TableWrapper>
          <ErrorToast />
          <S.Intro>
            <S.IntroTitle>Create and manage categories</S.IntroTitle>
            <div>
              Define your form categories by creating the elements you need and assigning them to your forms, making it
              easier to quickly find the right form to add to your page.
            </div>
          </S.Intro>
          <TableList tableHeader={TableHeader} onScroll={onScroll} hasFixedHeader={true} tableRef={tableRef}>
            <S.SearchTags>
              <SearchTagsBar query={searchQuery} setQuery={setSearchQuery} />
            </S.SearchTags>
            {isEmpty ? (
              <S.EmptyWrapper>
                <EmptyState {...emptyStateProps} />
              </S.EmptyWrapper>
            ) : (
              <>
                <S.Notification>
                  Reorder your category list by <strong>drag & drop</strong>.
                </S.Notification>
                <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
                  <Droppable droppableId="formCategoriesList">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <ComponentList components={categories} />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            )}
          </TableList>
        </S.TableWrapper>
      </S.CategoryListWrapper>
      {isOpen && <CategoryPanel isOpen={isOpen} toggleModal={toggleModal} toggleToast={toggleToast} type={category} />}
      {isDeleteOpen && (
        <DeleteModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainDeleteModalAction}
          secondaryModalAction={secondaryDeleteModalAction}
        />
      )}
      {isVisible && <Toast {...toastProps} />}
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  categories: state.forms.currentFormCategories,
  currenSiteID: state.sites.currentSiteInfo && state.sites.currentSiteInfo.id,
});

interface IDispatchProps {
  getFormCategories(type: string): Promise<void>;
  deleteFormCategory(id: number | number[], categoryType: string): Promise<boolean>;
  orderFormCategory(data: FormCategoriesOrderParams): Promise<boolean>;
}

interface ICategoriesProps {
  categories: FormCategory[];
  currenSiteID: number | null;
}

type IProps = ICategoriesProps & IDispatchProps;

const mapDispatchToProps = {
  getFormCategories: formsActions.getFormCategories,
  deleteFormCategory: formsActions.deleteFormCategory,
  orderFormCategory: formsActions.orderFormCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormCategoriesList);
