import React from "react";
import { IDataPackCategory } from "@ax/types";
import { Icon, FloatingMenu, ListTitle, ListItem } from "@ax/components";

import * as S from "./style";

const Filter = (props: IProps) => {
  const { items, setFilter } = props;

  const Select = () => (
    <S.Wrapper>
      Filter by
      <S.IconsWrapper>
        <S.InteractiveArrow>
          <Icon name="DownArrow" size="16" />
        </S.InteractiveArrow>
      </S.IconsWrapper>
    </S.Wrapper>
  );

  const handleClickAll = () => setFilter(null);

  return (
    <FloatingMenu Button={Select} position="left">
      <ListTitle>Filter by category</ListTitle>
      <ListItem key="all" onClick={handleClickAll}>
        All
      </ListItem>
      {!!items &&
        items.map((item) => {
          const handleClick = () => setFilter(item.id);
          return (
            <ListItem key={item.name} onClick={handleClick}>
              {item.name}
            </ListItem>
          );
        })}
    </FloatingMenu>
  );
};

interface IProps {
  items: IDataPackCategory[];
  setFilter: (categoryID: string | null) => void;
}

export default Filter;
