import React, { useEffect, useState } from "react";

import { IDimension } from "@ax/types";
import { FloatingPanel, Button, FieldsBehavior, NoteField } from "@ax/components";
import { splitAndJoin } from "@ax/helpers";

import * as S from "./style";

const DimensionPanel = (props: IProps): JSX.Element => {
  const { isOpen, toggleModal, item, setDimensionItem } = props;

  const isOnlyDimensions = item && ["", "null"].includes(item.values);
  const typeInitialState = isOnlyDimensions ? "onlyDimensions" : "dimensionsAndValues";
  const [type, setType] = useState(typeInitialState);
  const [name, setName] = useState("");
  const [values, setValues] = useState("");
  const [errors, setErrors] = useState({ name: false, values: false });

  const resetState = () => {
    setType(typeInitialState);
    setName(item ? item.name : "");
    setErrors({ name: false, values: false });

    const values = splitAndJoin(item?.values, ";", ";");
    const _values = values === "null" ? "" : values;
    setValues(_values || "");
  };

  useEffect(() => {
    isOpen && resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    type === "onlyDimensions" && setValues("");
  }, [type]);

  const editItemAction = () => {
    setDimensionItem({
      ...item,
      name: name.replace(/\s/g, ""),
      values,
    });
    toggleModal();
  };

  const validateFields = () => {
    const errorFields = {
      name: !name.length,
      values: type === "dimensionsAndValues" && !values.length,
    };
    setErrors(errorFields);
    const isFieldsValid = Object.values(errorFields).every((error) => !error);
    isFieldsValid && editItemAction();
  };

  const title = item ? "Update Dimension" : "Add Dimension";

  const editButton = {
    label: title,
    action: validateFields,
  };

  const typeOptions = [
    {
      title: "Dimensions & Values",
      name: "dimensionsAndValues",
      value: "dimensionsAndValues",
    },
    {
      title: "Only Dimensions",
      name: "onlyDimensions",
      value: "onlyDimensions",
    },
  ];

  const noteTitle = type === "dimensionsAndValues" ? "Dimensions & Values" : "Only Dimensions";
  const noteText =
    type === "dimensionsAndValues"
      ? "Create a dimension and its values. You define the values now and select them on any page later. Your analytics data will be homogeneous avoiding duplicates that might cause inaccurate data results."
      : "Create only a dimension. You will define its values on a page later, and will only affect that page. Only use it when you need custom data.";

  return (
    <FloatingPanel title={title} toggleModal={toggleModal} isOpen={isOpen}>
      <S.Wrapper>
        <S.ContentWrapper>
          <FieldsBehavior
            name="removeItemChildren"
            fieldType="RadioGroup"
            title="Dimension type"
            value={type}
            options={typeOptions}
            onChange={setType}
            mandatory
          />
          <S.NoteWrapper>
            <NoteField value={{ text: noteText, title: noteTitle }} />
          </S.NoteWrapper>
          <FieldsBehavior
            title="Dimension"
            name="name"
            fieldType="TextField"
            value={name}
            onChange={setName}
            mandatory
            validators={{ mandatory: true }}
            error={errors.name}
            placeholder="Type a Dimension Name"
          />
          {type === "dimensionsAndValues" ? (
            <FieldsBehavior
              title="Values"
              name="values"
              fieldType="TextArea"
              value={values}
              onChange={setValues}
              mandatory
              validators={{ mandatory: true }}
              error={errors.values}
              placeholder="Type any value that you need. &#10;Separate them with ;&#10;&#19;&#10;Example: English; Spanish; German; French"
              rows={4}
            />
          ) : (
            <></>
          )}
        </S.ContentWrapper>
        <S.Footer>
          <Button className="button" type="button" onClick={editButton.action}>
            {editButton.label}
          </Button>
        </S.Footer>
      </S.Wrapper>
    </FloatingPanel>
  );
};

interface IProps {
  item?: IDimension;
  isOpen: boolean;
  toggleModal: () => void;
  setDimensionItem(content: IDimension): void;
}

export default DimensionPanel;
