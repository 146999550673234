import { IDimension, IDimensionsGroup } from "@ax/types";
import { AxiosResponse } from "axios";
import { template } from "./config";
import { IServiceConfig, sendRequest } from "./utils";

const SERVICES: { [key: string]: IServiceConfig } = {
  GET_ANALYTICS: {
    ...template,
    endpoint: ["/site/", "/metrics"],
    method: "GET",
  },
  UPDATE_SCRIPT_CODE: {
    ...template,
    endpoint: ["/site/", "/metrics/code"],
    method: "PUT",
  },
  CREATE_DIMENSIONS: {
    ...template,
    endpoint: ["/metrics/dimensions/site/", "/bulk"],
    method: "POST",
  },
  CREATE_DIMENSIONS_GROUPS: {
    ...template,
    endpoint: ["/metrics/groups/site/", "/bulk"],
    method: "POST",
  },
  DELETE_SCRIPT_CODE: {
    ...template,
    endpoint: ["/site/", "/metrics/code"],
    method: "DELETE",
  },
};

const getAnalytics = async (siteId: number | "global"): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.GET_ANALYTICS;

  SERVICES.GET_ANALYTICS.dynamicUrl = `${host}${prefix}${siteId}${suffix}`;

  return sendRequest(SERVICES.GET_ANALYTICS);
};

const updateScriptCode = async (siteId: number | "global", data: { scriptCode: string }): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.UPDATE_SCRIPT_CODE;

  SERVICES.UPDATE_SCRIPT_CODE.dynamicUrl = `${host}${prefix}${siteId}${suffix}`;

  return sendRequest(SERVICES.UPDATE_SCRIPT_CODE, { ...data });
};

const createDimensions = async (
  siteId: number | "global",
  data: { dimensions: IDimension[] }
): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.CREATE_DIMENSIONS;

  SERVICES.CREATE_DIMENSIONS.dynamicUrl = `${host}${prefix}${siteId}${suffix}`;

  return sendRequest(SERVICES.CREATE_DIMENSIONS, { ...data });
};

const createDimensionsGroups = async (
  siteId: number | "global",
  data: { groups: IDimensionsGroup[] }
): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.CREATE_DIMENSIONS_GROUPS;

  SERVICES.CREATE_DIMENSIONS_GROUPS.dynamicUrl = `${host}${prefix}${siteId}${suffix}`;

  return sendRequest(SERVICES.CREATE_DIMENSIONS_GROUPS, { ...data });
};

const deleteScriptCode = async (siteId: number | "global"): Promise<AxiosResponse> => {
  const {
    host,
    endpoint: [prefix, suffix],
  } = SERVICES.DELETE_SCRIPT_CODE;

  SERVICES.DELETE_SCRIPT_CODE.dynamicUrl = `${host}${prefix}${siteId}${suffix}`;

  return sendRequest(SERVICES.DELETE_SCRIPT_CODE);
};

export default {
  getAnalytics,
  updateScriptCode,
  createDimensions,
  createDimensionsGroups,
  deleteScriptCode,
};
