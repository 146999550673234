import { useEffect, useState, useLayoutEffect } from "react";
import { IEmptyStateProps } from "@ax/types";
import { useWindowSize } from "./window";

const useCategoryColors = (
  categories?: string[]
): { categoryColors: Record<string, string>; addCategoryColors: (cats: string[]) => void } => {
  const fixedColors = [
    "#FFE695",
    "#C1F0FF",
    "#C3FFC1",
    "#DDBCFE",
    "#FDCBF2",
    "#FFB8B8",
    "#9CF3D4",
    "#E3CDB8",
    "#CCDA76",
    "#DBDDE9",
  ];

  const colors = [...fixedColors];
  const [categoryColors, setCategoryColors] = useState({});

  useEffect(() => {
    categories && addCategoryColors(categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const getColor = () => {
    return "hsl(" + 360 * Math.random() + "," + (25 + 70 * Math.random()) + "%," + (85 + 10 * Math.random()) + "%)";
  };

  const addCategoryColors = (cats: string[]) => {
    cats &&
      cats.forEach((cat: string) => {
        const founded = Object.keys(categoryColors).find((catID: string) => cat === catID);
        if (!founded) {
          const color = colors.length ? colors.shift() : getColor();
          setCategoryColors({ ...categoryColors, [cat]: color });
        }
      });
  };

  return {
    categoryColors,
    addCategoryColors,
  };
};

const useEmptyState = (
  elements: unknown[],
  fetchState: { isLoading: boolean; isFiltered: boolean },
  emptyStates: IEmptyStates
): { isEmpty: boolean; emptyStateProps: IEmptyStateProps } => {
  const { isLoading, isFiltered } = fetchState;
  const [isEmpty, setIsEmpty] = useState(false);
  const [emptyStateProps, setEmptyStateProps] = useState<IEmptyStateProps>({});
  const [isLoaded, setIsLoaded] = useState<boolean>();

  useEffect(() => {
    if (isLoading || typeof isLoaded !== "undefined") {
      setIsLoaded(!isLoading);
    }
  }, [isLoading, isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      setIsEmpty(!elements.length);
      if (!isFiltered) {
        emptyStates.noElementsProps && setEmptyStateProps(emptyStates.noElementsProps);
      } else if (emptyStates.noSearchResultsProps) {
        setEmptyStateProps(emptyStates.noSearchResultsProps);
      } else {
        const emptyProps = {
          icon: "search",
          title: "Oh! No Results Found",
          message: "We couldn’t find what you are looking for. Please, try another search.",
        };
        setEmptyStateProps(emptyProps);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return { isEmpty, emptyStateProps };
};

interface IEmptyStates {
  noElementsProps: IEmptyStateProps;
  noSearchResultsProps?: IEmptyStateProps;
}

const useAdaptiveText = (
  containerRef: React.RefObject<HTMLDivElement>,
  fullText: string,
  padding = 0
): { text: string; width: number } => {
  const [output, setTitle] = useState({ text: "", width: 0 });

  const [width] = useWindowSize();

  useEffect(() => {
    setTitle({ text: "", width: 0 });
  }, [width]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const cellWidth = containerRef.current.offsetWidth;
      const cellPadding = padding * 2;
      setTitle({ text: fullText, width: cellWidth - cellPadding });
    }
  }, [containerRef, fullText, output.width, padding]);

  return output;
};

export { useCategoryColors, useEmptyState, useAdaptiveText };
