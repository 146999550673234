import { IIntegration } from "@ax/types";
import { SET_CURRENT_INTEGRATION, SET_INTEGRATIONS, SET_TOTAL_ITEMS, SET_COPY_INTEGRATION } from "./constants";
import { IntegrationsActionsCreators } from "./interfaces";

export interface IIntegrationsState {
  integrations: IIntegration[];
  totalItems: number;
  currentIntegration: IIntegration | null;
  integrationCopy: { date: Date; integration: IIntegration } | null;
}

export const initialState = {
  integrations: [],
  totalItems: 0,
  currentIntegration: null,
  integrationCopy: null,
};

export function reducer(state = initialState, action: IntegrationsActionsCreators) {
  switch (action.type) {
    case SET_INTEGRATIONS:
    case SET_TOTAL_ITEMS:
    case SET_CURRENT_INTEGRATION:
    case SET_COPY_INTEGRATION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { initialState as integrationsInitialState, reducer as integrationsReducer };
