export default {
  schemaType: "page",
  displayName: "Form",
  component: "FormPage",
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "",
          type: "TranslateButton",
          key: "translate",
          contentType: "form",
        },
        {
          title: "Title",
          type: "TextField",
          key: "title",
          autoComplete: "false",
        },
        {
          title: "Template",
          key: "template",
          type: "template",
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Template",
          key: "template",
          type: "template",
        },
        {
          title: "Tags",
          type: "TagsField",
          key: "tags",
        },
      ],
    },
  ],
  default: {
    component: "FormPage",
    title: "New Form",
    tags: [],
  },
};
